import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Copyright from './Copyright';
import RealtimeStatus from '../RealtimeStatus';

import { appCfg } from '../../../config/app';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'columns',
    backgroundColor: theme.palette.background.brandSecondary,
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '100%',
  },
  copyrightContainer: {
    flex: '1 1 auto',
  },
  versionContainer: {
    flex: '0 1 auto',
    marginLeft: '1em',
    marginRight: '1em',
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Copyright classNameContainer={classes.copyrightContainer} />
      <RealtimeStatus />
      <Box className={classes.versionContainer}>
        <Typography variant="body2" color="textSecondary">
          {`v${appCfg.appVersion}`}
        </Typography>
      </Box>
    </footer>
  );
}

export default Footer;
