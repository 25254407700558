import clientGroupRepository from '../../repostitories/client-group-repository';
import {
  IO_CLIENT_GROUP_CREATE_OR_UPDATE,
  IO_CLIENT_GROUP_DESTROY,
} from './constants';
import { deepMergeArrObjsWithIdentifier } from '../../shared/utils';

import { API_FAIL as API_CLIENT_FAIL } from './api-client-failure';

const RETRIEVE = 'store/client-group/RETRIEVE';
const RETRIEVE_SUCCESS = 'store/client-group/RETRIEVE_SUCCESS';

const RETRIEVE_CLIENTS = 'store/client-group/RETRIEVE_CLIENTS';
const RETRIEVE_CLIENTS_SUCCESS = 'store/client-group/RETRIEVE_CLIENTS_SUCCESS';

const CREATE = 'store/client-group/CREATE';
const CREATE_SUCCESS = 'store/client-group/CREATE_SUCCESS';

const UPDATE = 'store/client-group/UPDATE';
const UPDATE_SUCCESS = 'store/client-group/UPDATE_SUCCESS';

const REMOVE = 'store/client-group/REMOVE';
const REMOVE_SUCCESS = 'store/client-group/REMOVE_SUCCESS';

const UPDATE_SELECTED_GROUP = 'store/client-group/UPDATE_SELECTED_GROUP';

const RECEIVE_CREATE_OR_UPDATE = 'store/client-group/RECEIVE_CREATE_OR_UPDATE';
const RECEIVE_CREATE_OR_UPDATE_SUCCESS =
  'store/client-group/RECEIVE_CREATE_OR_UPDATE_SUCCESS';
const RECEIVE_CREATE_OR_UPDATE_FAIL =
  'store/client-group/RECEIVE_CREATE_OR_UPDATE_FAIL';

const RECEIVED_CREATE_OR_UPDATE =
  'store/client-group/RECEIVED_CREATE_OR_UPDATE';

const RECEIVE_DESTROY = 'store/client-group/RECEIVE_DESTROY';
const RECEIVE_DESTROY_SUCCESS = 'store/client-group/RECEIVE_DESTROY_SUCCESS';
const RECEIVE_DESTROY_FAIL = 'store/client-group/RECEIVE_DESTROY_FAIL';

const RECEIVED_DESTROY = 'store/client-group/RECEIVED_DESTROY';

const repo = clientGroupRepository();

// TODO: DRY
const filterOutNames = (stateSlice, filterVals) => {
  return stateSlice.filter(
    (item) => !filterVals.map((filterVal) => filterVal.name).includes(item.name)
  );
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        isRetrieving: true,
      };
    case RETRIEVE_SUCCESS:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: null,
        retrieveResult: {
          ...state.retrieveResult,
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoClientGroupDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('name')(
                  state.retrieveResult.data,
                  Object.values(action.result.data)
                )
              : Object.values(action.result.data)
          ),
        },
      };
    case RETRIEVE_CLIENTS:
      return {
        ...state,
        isRetrievingClients: true,
      };
    case RETRIEVE_CLIENTS_SUCCESS:
      return {
        ...state,
        isRetrievingClients: false,
        retrievingClientsError: null,
        retrieveResult: {
          ...state.retrieveResult,
          data: repo.dtoClientGroupDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('name')(
                  state.retrieveResult.data,
                  Object.values(action.result.data)
                )
              : Object.values(action.result.data)
          ),
        },
      };
    case CREATE:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createError: null,
        createResult: {
          hasRecords: !!state.retrieveResult.count + action.result.count,
          count: state.retrieveResult.count + action.result.count,
          data: repo.dtoClientGroupDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('name')(
                  state.retrieveResult.data,
                  Object.values(action.result.data)
                )
              : Object.values(action.result.data)
          ),
        },
      };
    case UPDATE:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateError: null,
        updateResult: {
          hasRecords: !!state.retrieveResult.count + action.result.count,
          count: state.retrieveResult.count + action.result.count,
          data: repo.dtoClientGroupDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('name')(
                  state.retrieveResult.data,
                  Object.values(action.result.data)
                )
              : Object.values(action.result.data)
          ),
        },
      };
    case REMOVE:
      return {
        ...state,
        isRemoving: true,
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        isRemoving: false,
        removeError: null,
        removeResult: {
          hasRecords:
            state.retrieveResult && state.retrieveResult.hasRecords
              ? !!filterOutNames(state.retrieveResult.data, action.result.data)
                  .length
              : false,
          count:
            state.retrieveResult && state.retrieveResult.count
              ? filterOutNames(state.retrieveResult.data, action.result.data)
                  .length
              : 0,
          data:
            state.retrieveResult && state.retrieveResult.data
              ? filterOutNames(state.retrieveResult.data, action.result.data)
              : [],
        },
      };
    case UPDATE_SELECTED_GROUP:
      return {
        ...state,
        selected: action.result,
      };
    case RECEIVED_CREATE_OR_UPDATE:
      return {
        ...state,
        retrieveResult: {
          hasRecords:
            state.retrieveResult && state.retrieveResult.hasRecords
              ? state.retrieveResult.hasRecords
              : !!Object.keys(action.result).length,
          count:
            state.retrieveResult && state.retrieveResult.count
              ? state.retrieveResult.count
              : Object.keys(action.result).length,
          data: repo.dtoClientGroupDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('name')(
                  state.retrieveResult.data,
                  Object.values(action.result) // TODO: change deepMergeArrObjsWithIdentifier to convert object into array
                )
              : Object.values(action.result) // TODO: change deepMergeArrObjsWithIdentifier to convert object into array
          ),
        },
      };
    case RECEIVED_DESTROY:
      return {
        ...state,
        retrieveResult: {
          hasRecords:
            state.retrieveResult && state.retrieveResult.hasRecords
              ? !!(state.retrieveResult.count - 1)
              : false,
          count:
            state.retrieveResult && state.retrieveResult.count
              ? state.retrieveResult.count - 1
              : 0,
          data:
            state.retrieveResult && state.retrieveResult.data
              ? state.retrieveResult.data.filter(
                  (item) => item.name !== action.result
                )
              : [],
        },
      };
    default:
      return state;
  }
}

export function retrieve(authToken) {
  return {
    types: [RETRIEVE, RETRIEVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) => client.get(`/client-groups`, null, authToken),
  };
}

export function retrieveClients({ clientGroup }, authToken) {
  return {
    types: [RETRIEVE_CLIENTS, RETRIEVE_CLIENTS_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.get(`/client-groups/${clientGroup}/clients`, null, authToken),
  };
}

export function create({ clientGroupName }, authToken) {
  return {
    types: [CREATE, CREATE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.post(
        '/client-groups',
        {
          data: {
            name: clientGroupName,
          },
        },
        authToken
      ),
  };
}

export function update({ clientGroupName, clients }, authToken) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.put(
        `/client-groups/${clientGroupName}/clients`,
        {
          data: {
            name: clientGroupName,
            clients: clients.map((name) => {
              return {
                name,
              };
            }),
          },
        },
        authToken
      ),
  };
}

export function remove({ clientGroupName }, authToken) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.delete(`/client-groups/${clientGroupName}`, null, authToken),
  };
}

export function selectionChange(key) {
  return { type: UPDATE_SELECTED_GROUP, result: key };
}

export function receiveCreateOrUpdate() {
  return (dispatch) => {
    const newMessage = (message) => {
      return dispatch({
        type: RECEIVED_CREATE_OR_UPDATE,
        result: message,
      });
    };

    return dispatch({
      type: 'socket',
      types: [
        RECEIVE_CREATE_OR_UPDATE,
        RECEIVE_CREATE_OR_UPDATE_SUCCESS,
        RECEIVE_CREATE_OR_UPDATE_FAIL,
      ],
      promise: (socket) =>
        socket.on(IO_CLIENT_GROUP_CREATE_OR_UPDATE, newMessage),
    });
  };
}

export function receiveDestroy() {
  return (dispatch) => {
    const newMessage = (message) => {
      return dispatch({
        type: RECEIVED_DESTROY,
        result: message,
      });
    };

    return dispatch({
      type: 'socket',
      types: [RECEIVE_DESTROY, RECEIVE_DESTROY_SUCCESS, RECEIVE_DESTROY_FAIL],
      promise: (socket) => socket.on(IO_CLIENT_GROUP_DESTROY, newMessage),
    });
  };
}
