import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loading({ color }) {
  return <CircularProgress color={color} />;
}

Loading.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
};

Loading.defaultProps = {
  color: 'secondary',
};

export default Loading;
