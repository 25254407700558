import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: theme.spacing(1),
  },
  message: {
    fontSize: '0.75rem',
  },
}));

function ErrorMsg({ connectError, disconnectError }) {
  const classes = useStyles();
  const connectErrorContent =
    connectError != null &&
    typeof connectError === 'string' &&
    connectError.length > 0
      ? connectError.toUpperCase()
      : null;
  const disconnectErrorContent =
    disconnectError != null &&
    typeof disconnectError === 'string' &&
    disconnectError.length > 0
      ? disconnectError.toUpperCase()
      : null;
  return (
    <Box className={classes.container}>
      {connectErrorContent !== null ? (
        <Typography variant="body1" color="error" className={classes.message}>
          {connectErrorContent}
        </Typography>
      ) : null}
      {disconnectErrorContent !== null ? (
        <Typography variant="body1" color="error" className={classes.message}>
          {disconnectErrorContent}
        </Typography>
      ) : null}
    </Box>
  );
}

ErrorMsg.propTypes = {
  connectError: PropTypes.string,
  disconnectError: PropTypes.string,
};

ErrorMsg.defaultProps = {
  connectError: null,
  disconnectError: null,
};

export default ErrorMsg;
