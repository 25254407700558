function presenter({ authState, oktaAuth, history }) {
  const signOutHandler = async () => {
    // Note: Can't distinguish CORS error from other network errors
    const isCorsError = (err) =>
      err.name === 'AuthApiError' &&
      !err.errorCode &&
      err.xhr.message === 'Failed to fetch';
    const basename =
      window.location.origin + history.createHref({ pathname: '/' });
    try {
      await oktaAuth.signOut({
        clearTokensBeforeRedirect: true,
        postLogoutRedirectUri: basename,
      });
      return true;
    } catch (err) {
      if (isCorsError(err)) {
        // eslint-disable-next-line no-console
        console.error('AuthN signout issue: CORS suspected', err);
      } else {
        // eslint-disable-next-line no-console
        console.error('AuthN signout issue', err);
      }
      return err;
    }
  };
  const isLoggedIn = !authState.isPending && authState.isAuthenticated;
  const isLoggingIn = authState.isPending;
  const isLoggedOut = !isLoggedIn;
  const isDisplaySignIn = isLoggedOut;
  const isDisplaySignOut = isLoggedIn;

  return Object.freeze({
    signOutHandler,
    isLoggedIn,
    isLoggingIn,
    isLoggedOut,
    isDisplaySignIn,
    isDisplaySignOut,
  });
}

export default presenter;
