import shadowStratParamRepository from '../../repostitories/shadow-strat-param';
import { IO_SHADOW_GROUP_STATUS_UPDATE } from './constants';

import {
  API_FAIL as API_CLIENT_FAIL,
  CMD_FAIL as API_CLIENT_CMD_FAIL,
} from './api-client-failure';

const RETRIEVE = 'store/shadow-group/RETRIEVE';
const RETRIEVE_SUCCESS = 'store/shadow-group/RETRIEVE_SUCCESS';

// Receive doesn't actualy receive messages.
// but prepares the socket to receive messages.
const RECEIVE = 'store/shadow-group-status/RECEIVE';
const RECEIVE_SUCCESS = 'store/shadow-group-status/RECEIVE_SUCCESS';
const RECEIVE_FAIL = 'store/shadow-group-status/RECEIVE_FAIL';

const STATUS_UPDATE = 'store/shadow-group-status/STATUS_UPDATE';

const CREATE = 'store/shadow-group/CREATE';
const CREATE_SUCCESS = 'store/shadow-group/CREATE_SUCCESS';

const UPDATE = 'store/shadow-group/UPDATE';
const UPDATE_SUCCESS = 'store/shadow-group/UPDATE_SUCCESS';

const GROUP_CMD = 'store/shadow-group/GROUP_CMD';
const GROUP_CMD_SUCCESS = 'store/shadow-group/GROUP_CMD_SUCCESS';

const repo = shadowStratParamRepository();

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        isRetrieving: true,
      };
    case RETRIEVE_SUCCESS:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: null,
        retrieveResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoShadowGroupDataSourceInput(
            action.result.data.map((item) => {
              return {
                ...item,
                status:
                  state.retrieveResult && state.retrieveResult.data
                    ? state.retrieveResult.data.reduce((acc, cur) => {
                        if (item.name === cur.name) {
                          return cur.status;
                        }
                        return acc;
                      }, 'offline')
                    : 'offline',
              };
            })
          ),
        },
      };
    case STATUS_UPDATE:
      return {
        ...state,
        retrieveResult: {
          hasRecords:
            state.retrieveResult && state.retrieveResult.hasRecords
              ? state.retrieveResult.hasRecords
              : true,
          count:
            state.retrieveResult && state.retrieveResult.count
              ? state.retrieveResult.count
              : Object.keys(action.result).length,
          data:
            state.retrieveResult && state.retrieveResult.data
              ? repo.dtoShadowGroupDataSourceInput(
                  state.retrieveResult.data.map((item) => {
                    return {
                      ...item,
                      status: action.result[item.name]
                        ? action.result[item.name].status
                        : 'offline',
                    };
                  })
                )
              : [
                  ...Object.keys(action.result).map((key) => {
                    return {
                      name: key,
                      status:
                        action.result[key] && action.result[key].status
                          ? action.result[key].status
                          : 'offline',
                    };
                  }),
                ],
        },
      };
    case CREATE:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createError: null,
        retrieveResult: {
          hasRecords: action.result.hasRecords,
          count: state.retrieveResult.count + 1,
          data: repo.dtoShadowGroupDataSourceInput([
            ...state.retrieveResult.data,
            ...action.result.data,
          ]),
        },
      };
    case UPDATE:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateError: null,
        updateResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoShadowGroupDataSourceInput(action.result.data),
        },
        retrieveResult: {
          ...state.retrieveResult,
          data: repo.dtoShadowGroupDataSourceInput(
            state.retrieveResult.data.reduce((stateAcc, stateCur) => {
              return [
                ...stateAcc,
                ...action.result.data.reduce((acc, cur) => {
                  if (stateCur.name != null && stateCur.name === cur.name) {
                    return [...acc, cur];
                  }
                  return [...acc, stateCur];
                }, []),
              ];
            }, [])
          ),
        },
      };
    case GROUP_CMD:
      return {
        ...state,
        isCmdInProgress: true,
      };
    case GROUP_CMD_SUCCESS:
      return {
        ...state,
        isCmdInProgress: false,
        cmdError: null,
        cmdResult: action.result,
      };

    default:
      return state;
  }
}

export function retrieve(authToken) {
  return {
    types: [RETRIEVE, RETRIEVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.get(`/shadow-params/shadow-groups`, null, authToken),
  };
}

export function receive() {
  return (dispatch) => {
    const newMessage = (message) => {
      return dispatch({
        type: STATUS_UPDATE,
        result: message,
      });
    };

    return dispatch({
      type: 'socket',
      types: [RECEIVE, RECEIVE_SUCCESS, RECEIVE_FAIL],
      promise: (socket) => socket.on(IO_SHADOW_GROUP_STATUS_UPDATE, newMessage),
    });
  };
}

export function create(
  {
    name,
    reportingOrg,
    automatedStreamId,
    manualStreamId,
    executionDelayMillis,
    enabled,
    status,
  },
  authToken
) {
  return {
    types: [CREATE, CREATE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.post(
        '/shadow-params/shadow-groups',
        {
          data: {
            name,
            reportingOrg,
            automatedStreamId,
            manualStreamId,
            executionDelayMillis,
            enabled,
            status,
          },
        },
        authToken
      ),
  };
}

export function update(
  {
    name,
    reportingOrg,
    automatedStreamId,
    manualStreamId,
    executionDelayMillis,
    enabled,
    status,
  },
  authToken
) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.put(
        `/shadow-params/shadow-groups/${name}`,
        {
          data: {
            reportingOrg,
            automatedStreamId,
            executionDelayMillis,
            manualStreamId,
            enabled,
            status,
          },
        },
        authToken
      ),
  };
}

export function cmdGroup({ groupName, action }, authToken) {
  let url = '/shadow-params/shadow-groups/vision/command';
  if (groupName) {
    url = `/shadow-params/shadow-groups/${groupName}/vision/command`;
  }
  return {
    types: [GROUP_CMD, GROUP_CMD_SUCCESS, API_CLIENT_CMD_FAIL],
    promise: (client) =>
      client.put(
        url,
        {
          data: {
            groupName,
            action,
          },
        },
        authToken
      ),
  };
}
