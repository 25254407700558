import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ReactDOM from 'react-dom';

const useStyles = makeStyles(() => ({
  root: {
    borderWidth: '0px',
    padding: 15,
    textAlign: 'center',
    display: 'inline-block',
    float: 'left',
    position: 'fixed',
    backgroundColor: '#f8f8f8',
    outlineColor: '#dde2eb',
    outlineWidth: '1px',
    outlineOffset: '0px',
    outlineStyle: 'solid',
    boxShadow: '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
  },
}));

const TextInput = forwardRef(
  (
    {
      value,
      required,
      labelText,
      helperText,
      margin,
      type,
      variant,
      size,
      textFieldStyle,
      column,
      inputProps,
      isValidatedCheckFn,
    },
    ref
  ) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    // const [isTouched, setIsTouched] = useState(false);
    const refContainer = useRef(null);

    const inputHandler = (e) => {
      // setIsTouched(true);
      setInputValue(e.target.value);
      setIsValid(isValidatedCheckFn(e.target.value));
    };

    useEffect(() => {
      focus();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return inputValue;
        },
        afterGuiAttached: () => {
          setInputValue(value);
        },
        isCancelAfterEnd: () => {
          return !isValid;
        },
        isPopup: () => {
          return true;
        },
      };
    });

    const focus = () => {
      window.setTimeout(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const container = ReactDOM.findDOMNode(refContainer.current);
        if (container) {
          container.focus();
        }
      });
    };

    return (
      <div
        ref={refContainer}
        className={classes.root}
        /* eslint-disable jsx-a11y/tabindex-no-positive */
        /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
        tabIndex={1} // important - without this the key presses wont be caught
        /* eslint-enable jsx-a11y/no-noninteractive-tabindex */
        /* eslint-enable jsx-a11y/tabindex-no-positive */
      >
        <TextField
          label={labelText}
          value={inputValue}
          helperText={helperText}
          required={required}
          error={!isValid}
          margin={margin}
          variant={variant}
          size={size}
          style={textFieldStyle}
          type={type}
          inputProps={inputProps}
          placeholder={`Enter ${column.colId}`}
          onChange={inputHandler}
        />
      </div>
    );
  }
);

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  column: PropTypes.shape({ colId: PropTypes.string }).isRequired,
  required: PropTypes.bool,
  labelText: PropTypes.string,
  helperText: PropTypes.string,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  variant: PropTypes.oneOf(['standard', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium']),
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'email',
    'search',
    'tel',
    'url',
  ]),
  inputProps: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  textFieldStyle: PropTypes.object,

  isValidatedCheckFn: PropTypes.func,
};

TextInput.defaultProps = {
  required: false,
  labelText: '',
  helperText: '',
  margin: 'dense',
  variant: 'outlined',
  size: 'small',
  type: 'text',
  inputProps: { min: null, max: null, step: null },
  textFieldStyle: {},
  isValidatedCheckFn: () => true,
};

export default TextInput;
