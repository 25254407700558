const CONNECT_CMD = 'store/socket/CONNECT_CMD';
const CONNECT_CMD_SUCCESS = 'store/socket/CONNECT_CMD_SUCCESS';
const CONNECT_CMD_FAIL = 'store/socket/CONNECT_CMD_FAIL';

const CONNECT = 'store/socket/CONNECT';
const CONNECT_SUCCESS = 'store/socket/CONNECT_SUCCESS';
const CONNECT_FAIL = 'store/socket/CONNECT_FAIL';

const DISCONNECT_CMD = 'store/socket/DISCONNECT_CMD';
const DISCONNECT_CMD_SUCCESS = 'store/socket/DISCONNECT_CMD_SUCCESS';
const DISCONNECT_CMD_FAIL = 'store/socket/DISCONNECT_CMD_FAIL';

const DISCONNECT = 'store/socket/DISCONNECT';
const DISCONNECT_SUCCESS = 'store/socket/DISCONNECT_SUCCESS';
const DISCONNECT_FAIL = 'store/socket/DISCONNECT_FAIL';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case CONNECT_CMD:
      return {
        ...state,
        isConnecting: true,
        isConnected: false,
        connectError: null,
        isDisconnecting: false,
        isDisconnected: true,
        disconnectError: null,
      };
    case CONNECT_CMD_SUCCESS:
      return {
        ...state,
        isConnecting: false,
        isConnected: true,
        connectError: null,
        isDisconnecting: false,
        isDisconnected: false,
        disconnectError: null,
      };
    case CONNECT_CMD_FAIL:
      return {
        ...state,
        isConnecting: false,
        isConnected: false,
        connectError: action.error.toString(),
        isDisconnecting: false,
        isDisconnected: true,
        disconnectError: null,
      };
    case CONNECT:
      return {
        ...state,
      };
    case CONNECT_SUCCESS:
      return {
        ...state,
        isConnecting: false,
        isConnected: true,
        connectError: null,
        isDisconnecting: false,
        isDisconnected: false,
        disconnectError: null,
      };
    case CONNECT_FAIL:
      return {
        ...state,
        isConnecting: true,
        connectError: action.error.toString(),
      };
    case DISCONNECT_CMD:
      return {
        ...state,
        isDisconnecting: true,
        isDisconnected: false,
        disconnectError: null,
      };
    case DISCONNECT_CMD_SUCCESS:
      return {
        ...state,
        isConnecting: false,
        isConnected: false,
        connectError: null,
        isDisconnecting: false,
        isDisconnected: true,
        disconnectError: null,
      };
    case DISCONNECT_CMD_FAIL:
      return {
        ...state,
        isDisconnecting: false,
        isDisconnected: false,
        disconnectError: action.error.toString(),
      };
    case DISCONNECT:
      return {
        ...state,
      };
    case DISCONNECT_SUCCESS:
      return {
        ...state,
        isConnecting: false,
        isConnected: false,
        connectError: null,
        isDisconnecting: false,
        isDisconnected: true,
        disconnectError: null,
      };
    case DISCONNECT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function connect() {
  return {
    type: 'socket',
    types: [CONNECT_CMD, CONNECT_CMD_SUCCESS, CONNECT_CMD_FAIL],
    promise: (socket) => socket.connect(),
  };
}

export function disconnect() {
  return {
    type: 'socket',
    types: [DISCONNECT_CMD, DISCONNECT_CMD_SUCCESS, DISCONNECT_CMD_FAIL],
    promise: (socket) => socket.disconnect(),
  };
}

export function evtConnected() {
  return (dispatch) => {
    const newState = () => {
      return dispatch({
        type: CONNECT_SUCCESS,
      });
    };
    return dispatch({
      type: 'socket-evt',
      types: [CONNECT_SUCCESS],
      promise: (socket) => socket.on('connect', newState),
    });
  };
}

export function evtConnectError() {
  return (dispatch) => {
    const errorState = (e) => {
      return dispatch({
        type: CONNECT_FAIL,
        error: e,
      });
    };

    return dispatch({
      type: 'socket-evt',
      types: [CONNECT_SUCCESS],
      promise: (socket) => socket.on('connect_error', errorState),
    });
  };
}

export function evtDisconnected() {
  return (dispatch) => {
    const newState = () => {
      return dispatch({
        type: DISCONNECT_SUCCESS,
      });
    };

    return dispatch({
      type: 'socket-evt',
      types: [DISCONNECT_SUCCESS],
      promise: (socket) => socket.on('disconnect', newState),
    });
  };
}
