import { connect } from 'react-redux';

import {
  create as createClientGroupAction,
  remove as removeClientGroupAction,
  selectionChange as clientGroupSelectionChangeAction,
} from '../../../store/modules/client-group';

import CreateGroupForm from './CreateGroupForm';

const mapStateToProps = function mapStateToProps(state) {
  const { clientGroup } = state;
  const { selected: stateSelected } = clientGroup;
  return {
    stateSelected,
  };
};

export default connect(mapStateToProps, {
  createClientGroupAction,
  removeClientGroupAction,
  clientGroupSelectionChangeAction,
})(CreateGroupForm);
