import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  IconButton,
  Hidden,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
} from '@material-ui/icons';

import presenter from './presenter';

import User from './User';
import Logo from '../../../assets/edgefxlogo.png';
import LogoMark from '../../../assets/ewlogo-blue.svg';

const envMap = {
  'om.edgefx.pro': 'NY',
  'om.edgefxpro.uk': 'LD',
  'omsg.edgefxasia.pro': 'SG',
  'om.edgefxasia.pro': 'TY',
  'omuat.edgefx.pro': 'NY (UAT)',
  'ombeta.edgefx.pro': 'NY (BETA)',
};

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: theme.props.layout.drawer.width,
      width: `calc(100% - ${theme.props.layout.drawer.width}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbarContainer: {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    iconButtonRoot: {
      borderRadius: 4,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    menuButtonHidden: {
      display: 'none',
    },
    accountBtnDetailsContainer: {
      maxWidth: '200px',
      marginRight: '1rem',
    },
    accountBtnDetails: {
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    flexGrow: {
      flexGrow: 1,
    },
    signOutButton: {
      marginLeft: theme.spacing(1),
    },
    actionButton: {
      borderRadius: 2,
      backgroundColor: '#fb3c4a', // TODO: move/add to theme
      color: theme.palette.common.white,
    },
    homeLink: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
    brandLogo: {
      height: '36px',
      width: '100%',
    },
    brandLogoMark: {
      height: '50px',
      width: '100%',
    },
    textWrapper: {
      display: 'flex',
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1),
      whiteSpace: 'nowrap',
      fontSize: '1.2rem',
    },
  };
});

function Topbar({ toggleIsDrawerOpenHandler, isDrawerOpen, stateUser }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null);
  const userInfo =
    stateUser != null &&
    stateUser.retrieveResult != null &&
    stateUser.retrieveResult.data != null
      ? stateUser.retrieveResult.data
      : null;

  const isUserProfileOpen = Boolean(anchorEl);
  const id = isUserProfileOpen ? 'topbar-user-popover' : null;

  const adminText = envMap[window.location.host] || '';
  const present = presenter();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbarContainer}>
        <IconButton
          edge="start"
          className={clsx(
            classes.menuButton,
            isDrawerOpen && classes.menuButtonHidden
          )}
          color="inherit"
          aria-label="open primary menu"
          onClick={toggleIsDrawerOpenHandler}
        >
          <MenuIcon />
        </IconButton>
        <RouterLink to="/" className={classes.homeLink}>
          <Hidden only={['xs']}>
            <img
              src={Logo}
              alt="Edgewater FX Admin"
              className={classes.brandLogo}
            />
          </Hidden>
          <Hidden smUp>
            <img
              src={LogoMark}
              alt="Edgewater FX Admin"
              className={classes.brandLogoMark}
            />
          </Hidden>
          <Box className={classes.textWrapper}>{adminText}</Box>
        </RouterLink>
        <div className={classes.flexGrow} />
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          classes={{ root: classes.iconButtonRoot }}
          color="inherit"
        >
          <Box className={classes.accountBtnDetailsContainer}>
            <Typography
              className={classes.accountBtnDetails}
              align="left"
              display="block"
              noWrap
              variant="subtitle2"
            >
              {present.getUserOrg(userInfo)}
            </Typography>
            <Typography
              className={classes.accountBtnDetails}
              align="left"
              display="block"
              noWrap
              variant="subtitle2"
            >
              {present.getUserPreferredUsername(userInfo)}
            </Typography>
          </Box>

          <AccountCircleIcon />
        </IconButton>
        <User
          id={id}
          isOpen={isUserProfileOpen}
          anchorEl={anchorEl}
          userFullName={present.getUserFullName(userInfo)}
          userInitials={present.getUserInitials(userInfo)}
          userOrg={present.getUserOrg(userInfo)}
          userColor={present.getUserColor(userInfo)}
          userPreferredUsername={present.getUserPreferredUsername(userInfo)}
          closeHandler={handleProfileMenuClose}
        />
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  toggleIsDrawerOpenHandler: PropTypes.func.isRequired,
  stateUser: PropTypes.shape({
    isRetrieving: PropTypes.bool,
    retrieveError: PropTypes.instanceOf(Error),
    retrieveResult: PropTypes.shape({
      hasRecords: PropTypes.bool.isRequired,
      count: PropTypes.number.isRequired,
      data: PropTypes.shape({
        sub: PropTypes.string,
        name: PropTypes.string,
        locale: PropTypes.string,
        email: PropTypes.string,
        preferred_username: PropTypes.string,
        given_name: PropTypes.string,
        family_name: PropTypes.string,
        zoneinfo: PropTypes.string,
        updated_at: PropTypes.number,
        email_verified: PropTypes.bool,
        organization: PropTypes.string,
        headers: PropTypes.shape({
          'cache-control': PropTypes.string,
          'content-type': PropTypes.string,
          date: PropTypes.string,
          expires: PropTypes.string,
          pragma: PropTypes.string,
          'x-okta-request-id': PropTypes.string,
          'x-rate-limit-limit': PropTypes.string,
          'x-rate-limit-remaining': PropTypes.string,
          'x-rate-limit-reset': PropTypes.string,
        }),
      }),
    }),
  }),
};

Topbar.defaultProps = {
  stateUser: null,
};

export default Topbar;
