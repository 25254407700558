import { arrayOf, bool, element, number, shape, string } from 'prop-types';

const propTypeShadowStream = shape({
  isRetrieving: bool,
  retrieveError: element,
  retrieveResult: shape({
    hasRecords: bool.isRequired,
    count: number.isRequired,
    data: arrayOf(
      shape({
        name: string,
        reportingOrg: string,
        logMarketData: number,
        enabled: number,
      }).isRequired
    ),
  }),
});

export default propTypeShadowStream;
