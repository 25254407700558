import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const RADIUS_DOT = 8;
const useStyles = makeStyles(() => ({
  circle: {
    borderRadius: RADIUS_DOT,
    height: RADIUS_DOT,
    width: RADIUS_DOT,
    padding: 0,
  },
}));

function StatusIndicator({ color } = {}) {
  const styles = { backgroundColor: color };
  const classes = useStyles();

  return color ? <span className={classes.circle} style={styles} /> : null;
}

StatusIndicator.propTypes = {
  color: PropTypes.oneOf(['green', 'orange', 'red']).isRequired,
};

export default StatusIndicator;
