import React from 'react';
import PropTypes from 'prop-types';

function Fallback({ error, componentStack, eventId, resetError }) {
  return (
    <>
      <h1>You have encountered an unexpected error</h1>
      <p>description: {error.toString()}</p>
      <p>component stack: {componentStack}</p>
      <button
        type="button"
        onClick={() => {
          resetError();
        }}
      >
        RESET
      </button>
      <p>Event ID: {eventId}</p>
    </>
  );
}

Fallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  componentStack: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  resetError: PropTypes.func.isRequired,
};

export default Fallback;
