import ClientGroup from '../../domain/ctx/client-group/ClientGroup';

function dtoClientGroup(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const clientGroup = ClientGroup({
    name: gatewayDataObject.name,
    clients: gatewayDataObject.clients,
  });

  return clientGroup;
}

export default dtoClientGroup;
