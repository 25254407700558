import React from 'react';
import PropTypes from 'prop-types';
import { Switch, FormControlLabel } from '@material-ui/core';

function FormSwitch({
  isChecked,
  changeHandler,
  name,
  color,
  labelText,
  classes,
}) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={isChecked}
          onChange={changeHandler}
          color={color}
          name={name}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          classes={classes}
        />
      }
      label={labelText}
    />
  );
}

FormSwitch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  labelText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
};

FormSwitch.defaultProps = {
  labelText: '',
  color: 'secondary',
  classes: null,
};

export default FormSwitch;
