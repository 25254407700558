function shadowStrategyParamConfigPresenter() {
  function isShowGroupSelect() {
    return true;
  }
  function hasRecords(state) {
    if (state && state.retrieveResult && state.retrieveResult.hasRecords) {
      return true;
    }
    return false;
  }

  function isDataFetching(state) {
    if (
      state.isRetrieving ||
      state.isUpdating ||
      state.isCreating ||
      state.isCmdInProgress
    ) {
      return true;
    }
    return false;
  }

  function isShowStratComp(defaultGroupOption, groupSelected) {
    if (groupSelected !== '' && defaultGroupOption !== groupSelected) {
      return true;
    }
    return false;
  }

  function prepGroupSelectOptions(shadowGroups) {
    if (hasRecords(shadowGroups)) {
      return shadowGroups.retrieveResult.data.map((shadowGroup) => {
        return { label: shadowGroup.name, value: shadowGroup.name };
      });
    }
    return null;
  }

  return Object.freeze({
    isDataFetching,
    isShowGroupSelect,
    isShowStratComp,
    prepGroupSelectOptions,
  });
}

export default shadowStrategyParamConfigPresenter;
