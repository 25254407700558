import { generateColorFromString } from '../../../shared/utils';

function presenter() {
  function getUserFullName(userInfo) {
    return userInfo && userInfo.name;
  }

  function getUserInitials(userInfo) {
    if (userInfo && userInfo.given_name && userInfo.family_name) {
      return `${userInfo.given_name.charAt(0)}${userInfo.family_name
        .split('-')
        .reduce((acc, cur) => {
          return `${acc}${cur.charAt(0)}`;
        }, '')}`;
    }

    return null;
  }

  function getUserOrg(userInfo) {
    return userInfo &&
      userInfo.organization &&
      typeof userInfo.organization === 'string'
      ? userInfo.organization.toUpperCase()
      : '';
  }

  function getUserPreferredUsername(userInfo) {
    return userInfo && userInfo.preferred_username;
  }

  function getUserColor(userInfo) {
    return generateColorFromString(userInfo && userInfo.preferred_username);
  }

  return Object.freeze({
    getUserFullName,
    getUserInitials,
    getUserOrg,
    getUserPreferredUsername,
    getUserColor,
  });
}

export default presenter;
