import ProOrganisation from '../domain/ctx-shadow-strat-param/ProOrganization';

function dtoProOrganization(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const proOrganization = ProOrganisation({
    organization: gatewayDataObject.organization,
    logMarketData: gatewayDataObject.logMarketData,
    enabled: gatewayDataObject.enabled,
  });

  return proOrganization;
}

export default dtoProOrganization;
