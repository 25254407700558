import { connect } from 'react-redux';

import { retrieve as retrieveInstrumentAction } from '../../../store/modules/shadow-instrument';
import {
  create as createShadowGroupStrategyParamAction,
  cmdStrategy as cmdStrategyAction,
} from '../../../store/modules/shadow-group-strategy-param';

import Container from './Container';

const mapStateToProps = function mapStateToProps(state) {
  const {
    shadowGroupStrategyParam: stateShadowGroupStrategyParam,
    shadowInstrument: stateShadowInstrument,
  } = state;
  return {
    stateShadowGroupStrategyParam,
    stateShadowInstrument,
  };
};

export default connect(mapStateToProps, {
  retrieveInstrumentAction,
  createShadowGroupStrategyParamAction,
  cmdStrategyAction,
})(Container);
