import Instrument from '../domain/ctx/price-controls/Instrument';

function dtoPriceControlPresetInstrument(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const instrument = Instrument({
    item: gatewayDataObject.item,
  });

  return instrument;
}

export default dtoPriceControlPresetInstrument;
