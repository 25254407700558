import {
  captureException as sentryCaptureException,
  configureScope as sentryConfigureScope,
  captureMessage as sentryCaptureMessage,
  //   addBreadcrumb as sentryAddBreadcrumb,
} from '@sentry/react';

/**
 * Logging utility
 *
 * Pass redux store in with logger.setStore(store) at app bootstrap
 * (internally) read state with store.getState()
 *
 */

let store = null;

// eslint-disable-next-line no-unused-vars
function logger() {
  let pageVisibilityApiPropName = null;
  let visibilityChangeEvtType = null;
  let isHidden = false;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    pageVisibilityApiPropName = 'hidden';
    visibilityChangeEvtType = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    pageVisibilityApiPropName = 'msHidden';
    visibilityChangeEvtType = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    pageVisibilityApiPropName = 'webkitHidden';
    visibilityChangeEvtType = 'webkitvisibilitychange';
  }

  function handlePageVisibilityChange() {
    if (document[pageVisibilityApiPropName]) {
      isHidden = true;
    } else {
      isHidden = false;
    }
  }

  // Feature detect
  if (
    typeof document.addEventListener === 'undefined' ||
    pageVisibilityApiPropName == null
  ) {
    logWarning(
      'Feature detection failed: addEventListener or page visibility API not supported'
    );
  } else {
    document.addEventListener(
      visibilityChangeEvtType,
      handlePageVisibilityChange,
      false
    );
  }

  /**
   * @typedef {Object} tagObjects - tags
   * @property {string} key - tag key
   * @property {string} value - tag value
   */

  /**
   * @param {Object} userAuthn - authenticated user state
   * @param {string} userAuthn.sub - user auth provider id
   * @param {string} userAuthn.email - user email
   * @param {string} userAuthn.username - username
   * @param {tagObjects[]} [tagObjects] - tags
   */
  const setScope = (authUser, tagObjects) => {
    if (Array.isArray(tagObjects) && tagObjects.length === 0) return;
    sentryConfigureScope(function configureScope(scope) {
      if (tagObjects && Array.isArray(tagObjects)) {
        tagObjects.forEach((tagObject) => {
          const { key, value } = tagObject;
          if (key == null || value == null) return;
          scope.setTag(key, value.toString());
        });
      }
      if (
        authUser != null &&
        (authUser.sub != null ||
          authUser.username != null ||
          authUser.email != null)
      ) {
        scope.setUser({
          id: authUser.sub,
          username: authUser.username,
          email: authUser.email,
        });
      }
    });
  };

  /**
   * @param {tagObjects[]} [tags] - tags
   */
  const setContext = (tags) => {
    const tagObjects = tags && Array.isArray(tags) ? tags : [];
    const authUser = getUser();
    const userDetailTagsArray = [];
    if (authUser != null) {
      if (authUser.username != null)
        userDetailTagsArray.push({ key: 'username', value: authUser.username });
      if (authUser.email != null)
        userDetailTagsArray.push({ key: 'email', value: authUser.email });
      if (authUser.sub != null)
        userDetailTagsArray.push({ key: 'sub', value: authUser.sub });
    }
    const tagObjectsWithDefaults = [
      ...tagObjects,
      ...userDetailTagsArray,
      { key: 'is_hidden', value: isHidden },
    ];
    setScope(authUser, tagObjectsWithDefaults);
  };

  const setStore = (storeObj) => {
    store = storeObj;
  };

  const getUser = () => {
    if (store != null) {
      const storeState = store.getState();
      if (
        storeState != null &&
        storeState.user != null &&
        storeState.user.retrieveResult != null
      ) {
        const userData = storeState.user.retrieveResult.data;
        const { preferred_username: preferredUsername, email, sub } = userData;
        return {
          username: preferredUsername,
          email,
          sub,
        };
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('logger expected redux store to be assigned');
    }
    return null;
  };

  const isError = (obj) => {
    // Source: https://stackoverflow.com/a/61958148/5082594
    return Object.prototype.toString.call(obj) === '[object Error]';
  };

  const getTransportName = (socket) => {
    return (
      socket &&
      socket.io &&
      socket.io.engine &&
      socket.io.engine.transport &&
      socket.io.engine.transport.name
    );
  };

  const getTransportReadyState = (socket) => {
    return (
      socket &&
      socket.io &&
      socket.io.engine &&
      socket.io.engine.transport &&
      socket.io.engine.transport.readyState
    );
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const log = (
    msg,
    {
      tags,
      // breadcrumbs,
      isLogIfHidden = true,
    } = {}
  ) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'log');
  };

  /**
   * @param {Error} error - JS Error
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logCriticalError = (
    error,
    {
      tags,
      // breadcrumbs,
      isLogIfHidden = true,
    } = {}
  ) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    if (isError(error)) {
      sentryCaptureException(error, 'fatal');
    } else {
      sentryCaptureMessage(error, 'fatal');
    }
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logCriticalMsg = (
    msg,
    {
      tags,
      // breadcrumbs,
      isLogIfHidden = true,
    } = {}
  ) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'fatal');
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logDebug = (
    msg,
    {
      tags,
      // breadcrumbs,
      isLogIfHidden = true,
    } = {}
  ) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'debug');
  };

  /**
   * @param {Error} error - JS Error
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logError = (
    error,
    {
      tags,
      // breadcrumbs,
      isLogIfHidden = true,
    } = {}
  ) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    if (isError(error)) {
      sentryCaptureException(error, 'error');
    } else {
      sentryCaptureMessage(error, 'error');
    }
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logFatal = (
    msg,
    {
      tags,
      // breadcrumbs,
      isLogIfHidden = true,
    } = {}
  ) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'fatal');
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logInfo = (
    msg,
    {
      tags,
      // breadcrumbs,
      isLogIfHidden = true,
    } = {}
  ) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'info');
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logWarning = (
    msg,
    {
      tags,
      // breadcrumbs,
      isLogIfHidden = true,
    } = {}
  ) => {
    if (isHidden && !isLogIfHidden) return;

    setContext(tags);
    sentryCaptureMessage(msg, 'warning');
  };

  return {
    log,
    logCriticalError,
    logCriticalMsg,
    logDebug,
    logError,
    logFatal,
    logInfo,
    logWarning,
    setStore,
    setContext,
    getTransportName,
    getTransportReadyState,
  };
}

export default logger;
