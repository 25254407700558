// Dashboard layout
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../hooks/trace-update-hook';

import Loading from '../../components/Loading';

import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import SystemAlertMessages from './SystemAlertMessages';
import ApiClientErrors from '../../components/ApiClientErrors';
import SocketClientErrors from '../../components/SocketClientErrors';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      boxSizing: 'border-box',
      display: 'table',
      width: '100%',
      height: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    aboveFooterContainer: {
      display: 'table-row',
      height: '100%',
    },
    primaryContentContainer: {
      display: 'flex',
      height: '100%',
    },
    primaryContent: {
      // height: '100%',
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      /* align items in Main Axis */
      alignItems: 'stretch',
      /* align items in Cross Axis */
      alignContent: 'stretch',
      margin: 0,
      '&>div': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    content: {
      flex: '1 1 auto',
    },
  };
});

function Main({
  children,
  connectSocketAction,
  disconnectSocketAction,
  receiveOrderHistoryAction,
  receiveOrderUpdateAction,
  socketEvtConnectErrorAction,
  socketEvtConnectedAction,
  socketEvtDisconnectedAction,
  receiveShadowGroupStatusAction,
  receiveCreateOrUpdatePriceControlPresetsAction,
  receiveDestroyPriceControlPresetsAction,
  receiveCreateOrUpdateClientGroupsAction,
  receiveDestroyClientGroupsAction,
  receiveSystemAlertMessageAction,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isSecondaryBreakPoint = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const handleToggleIsDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    function localConnect() {
      Promise.all([
        connectSocketAction(),
        receiveOrderHistoryAction(),
        receiveOrderUpdateAction(),
        socketEvtConnectErrorAction(),
        socketEvtConnectedAction(),
        socketEvtDisconnectedAction(),
        receiveShadowGroupStatusAction(),
        receiveCreateOrUpdatePriceControlPresetsAction(),
        receiveDestroyPriceControlPresetsAction(),
        receiveCreateOrUpdateClientGroupsAction(),
        receiveDestroyClientGroupsAction(),
        receiveSystemAlertMessageAction(),
      ]).then(() => setIsLoaded(true));
    }
    localConnect();

    /*
     * Disconnect from Socket.
     */
    async function localDisconnect() {
      await disconnectSocketAction();
    }
    return localDisconnect;
  }, [
    connectSocketAction,
    receiveOrderHistoryAction,
    receiveOrderUpdateAction,
    socketEvtConnectErrorAction,
    socketEvtConnectedAction,
    socketEvtDisconnectedAction,
    receiveShadowGroupStatusAction,
    receiveCreateOrUpdatePriceControlPresetsAction,
    receiveDestroyPriceControlPresetsAction,
    receiveCreateOrUpdateClientGroupsAction,
    receiveDestroyClientGroupsAction,
    receiveSystemAlertMessageAction,
    disconnectSocketAction,
  ]);

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   connectSocketAction,
  //   disconnectSocketAction,
  //   receiveOrderHistoryAction,
  //   receiveOrderUpdateAction,
  //   receiveShadowGroupStatusAction,
  //   receiveCreateOrUpdatePriceControlPresetsAction,
  //   receiveDestroyPriceControlPresetsAction,
  //   receiveCreateOrUpdateClientGroupsAction,
  //   receiveDestroyClientGroupsAction,
  //   receiveSystemAlertMessageAction,
  // });

  useEffect(() => {
    setIsDrawerOpen(isSecondaryBreakPoint);
  }, [isSecondaryBreakPoint]);

  return (
    <>
      {isLoaded ? (
        <div className={classes.root}>
          <div className={classes.aboveFooterContainer}>
            <Topbar
              toggleIsDrawerOpenHandler={handleToggleIsDrawerOpen}
              isDrawerOpen={isDrawerOpen}
            />
            <div className={classes.primaryContentContainer}>
              <Sidebar
                toggleIsDrawerOpenHandler={handleToggleIsDrawerOpen}
                variant="permanent"
                isDrawerOpen={isDrawerOpen}
              />
              <main className={classes.primaryContent}>
                <div className={classes.appBarSpacer} />
                <div className={classes.content}>{children}</div>
              </main>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <Loading />
      )}
      <SystemAlertMessages />
      <ApiClientErrors />
      <SocketClientErrors />
    </>
  );
}

Main.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  connectSocketAction: PropTypes.func.isRequired,
  disconnectSocketAction: PropTypes.func.isRequired,
  receiveOrderHistoryAction: PropTypes.func.isRequired,
  receiveOrderUpdateAction: PropTypes.func.isRequired,
  socketEvtConnectErrorAction: PropTypes.func.isRequired,
  socketEvtConnectedAction: PropTypes.func.isRequired,
  socketEvtDisconnectedAction: PropTypes.func.isRequired,
  receiveShadowGroupStatusAction: PropTypes.func.isRequired,
  receiveCreateOrUpdatePriceControlPresetsAction: PropTypes.func.isRequired,
  receiveDestroyPriceControlPresetsAction: PropTypes.func.isRequired,
  receiveCreateOrUpdateClientGroupsAction: PropTypes.func.isRequired,
  receiveDestroyClientGroupsAction: PropTypes.func.isRequired,
  receiveSystemAlertMessageAction: PropTypes.func.isRequired,
};

export default Main;
