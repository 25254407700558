import clientRepository from '../../repostitories/client-repository';

import { API_FAIL as API_CLIENT_FAIL } from './api-client-failure';

const RETRIEVE = 'store/client/RETRIEVE';
const RETRIEVE_SUCCESS = 'store/client/RETRIEVE_SUCCESS';

const repo = clientRepository();

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        isRetrieving: true,
      };
    case RETRIEVE_SUCCESS:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: null,
        retrieveResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoClientDataSourceInput(action.result.data),
        },
      };
    default:
      return state;
  }
}

export function retrieve(authToken) {
  return {
    types: [RETRIEVE, RETRIEVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) => client.get(`/clients`, null, authToken),
  };
}
