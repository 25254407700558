const OIDC_CLIENT_APP_ID = process.env.REACT_APP_OIDC_CLIENT_APP_ID;
const OIDC_CLIENT_URL = process.env.REACT_APP_OIDC_CLIENT_URL;
const OIDC_ORG_URL = process.env.REACT_APP_OIDC_ORG_URL;
const OIDC_TESTING_DISABLE_HTTPS_CHECK =
  process.env.REACT_APP_OIDC_TESTING_DISABLE_HTTPS_CHECK || false;
/* eslint-enable prefer-destructuring */

const originUrl =
  typeof OIDC_CLIENT_URL === 'string' && OIDC_CLIENT_URL.length > 0
    ? OIDC_CLIENT_URL
    : window.location.origin;

export default {
  oidc: {
    clientId: OIDC_CLIENT_APP_ID,
    issuer: `${OIDC_ORG_URL}/oauth2/default`,
    redirectUri: `${originUrl}/implicit/callback`,
    scopes: ['openid', 'profile', 'email', 'permissions', 'offline_access'],
    pkce: true,
    disableHttpsCheck: /^true$/i.test(OIDC_TESTING_DISABLE_HTTPS_CHECK),
  },
};
