import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

/**
 * Material-UI colour pallete
 * Reference: https://material-ui.com/customization/color/
 * Source: https://github.com/devias-io/react-material-dashboard
 */
export default {
  black,
  white,
  primary: {
    contrastText: '#fff',
    dark: 'rgb(21, 28, 42)',
    main: '#1e283c',
    light: 'rgb(75, 83, 99)',
  },
  secondary: {
    contrastText: 'rgba(0, 0, 0, 0.87)',
    dark: 'rgb(0, 140, 153)',
    main: '#00c9db',
    light: 'rgb(51, 211, 226)',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: '#8b91a0',
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
    brandPrimary: '#22262e',
    brandSecondary: '#1b1e24',
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};
