export default [
  {
    value: 'LIMIT',
    label: 'LIMIT',
  },
  {
    value: 'MARKET',
    label: 'MARKET',
  },
];
