import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/react';
import thunkMiddleware from 'redux-thunk';
// import createLogger from 'redux-logger';

import socketMiddleware from './middleware/socket-middleware';
import clientMiddleware from './middleware/client-middleware';
import reducer from './modules/reducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  process.env.REACT_APP_ENVIRONMENT === 'development' ||
  process.env.REACT_APP_ENVIRONMENT === 'beta' ||
  process.env.REACT_APP_ENVIRONMENT === 'uat' ||
  process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
        })) ||
      compose
    : compose;
/* eslint-enable no-underscore-dangle */

export default function configureStore(initialState, socketClient, apiClient) {
  // Create middleware
  // const loggerMiddleware = createLogger();
  const middleware = [
    thunkMiddleware,
    socketMiddleware(socketClient),
    clientMiddleware(apiClient),
    // loggerMiddleware,
  ];

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
    // applyMiddleware(...middleware)
  );

  return store;
}
