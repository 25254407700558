function presenter() {
  const presetsWithTypesPivot = ({ data }) => {
    return [
      ...data
        .map((preset) => {
          return {
            ...preset,
            ...preset.types.reduce((acc, cur) => {
              return {
                ...acc,
                ...{
                  [`${
                    cur.primary
                  }${cur.secondary[0].toUpperCase()}${cur.secondary.slice(1)}`]:
                    cur.value,
                },
              };
            }, {}),
          };
        })
        .reduce((acc, cur) => {
          if (cur.isActive) {
            return [
              ...acc,
              {
                id: cur.id,
                name: cur.group || '*',
                instrument: cur.instrument || '*',
                spreadAsk: cur.spreadAsk || 0,
                spreadBid: cur.spreadBid || 0,
                skewAsk: cur.skewAsk || 0,
                skewBid: cur.skewBid || 0,
                postAsk: cur.postAsk || 0,
                postBid: cur.postBid || 0,
              },
            ];
          }
          return acc;
        }, []),
    ];
  };

  return Object.freeze({
    presetsWithTypesPivot,
  });
}

export default presenter;
