import { combineReducers } from 'redux';

import socket from './socket';
import orderUpdate from './order-update';
import orderHistory from './order-history';
import priceControlPresets from './price-control-presets';
import priceControlPresetInstrument from './price-control-preset-instrument';
import client from './client';
import clientGroup from './client-group';
import shadowGroup from './shadow-group';
import shadowProOrganisation from './shadow-pro-organisation';
import shadowInstrument from './shadow-instrument';
import shadowStream from './shadow-stream';
import shadowStrategyParamConfig from './shadow-strategy-param-config';
import shadowGroupStrategyParam from './shadow-group-strategy-param';
import systemAlert from './system-alert';
import user from './user';
import apiClientFailure from './api-client-failure';

export default combineReducers({
  apiClientFailure,
  socket,
  orderUpdate,
  orderHistory,
  priceControlPresets,
  priceControlPresetInstrument,
  client,
  clientGroup,
  shadowGroup,
  shadowProOrganisation,
  shadowInstrument,
  shadowStrategyParamConfig,
  shadowGroupStrategyParam,
  shadowStream,
  systemAlert,
  user,
});
