import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactDOM from 'react-dom';

const useStyles = makeStyles(() => ({
  root: {
    borderWidth: '0px',
    padding: 15,
    textAlign: 'center',
    display: 'inline-block',
    float: 'left',
    position: 'fixed',
    backgroundColor: '#f8f8f8',
    outlineColor: '#dde2eb',
    outlineWidth: '1px',
    outlineOffset: '0px',
    outlineStyle: 'solid',
    boxShadow: '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
  },
}));

const NumberInput = forwardRef(
  (
    {
      value,
      thousandSeparator,
      allowNegative,
      isAllowed,
      decimalScale,
      fixedDecimalScale,
      required,
      labelText,
      helperText,
      margin,
      variant,
      size,
      isValidatedCheckFn,
    },
    ref
  ) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const refContainer = useRef(null);

    const inputHandler = (e) => {
      // setInputValue(e.target.value);
      // setIsValid(isValidatedCheckFn(e.target.value));
      setInputValue(e.value);
      setIsValid(isValidatedCheckFn(e.value));
    };

    useEffect(() => {
      focus();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return inputValue;
        },
        afterGuiAttached: () => {
          setInputValue(value.toString());
        },
        isCancelAfterEnd: () => {
          return !isValid;
        },
        isPopup: () => {
          return true;
        },
      };
    });

    const focus = () => {
      window.setTimeout(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const container = ReactDOM.findDOMNode(refContainer.current);
        if (container) {
          container.focus();
        }
      });
    };

    return (
      <div
        ref={refContainer}
        className={classes.root}
        /* eslint-disable jsx-a11y/tabindex-no-positive */
        /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
        tabIndex={1} // important - without this the key presses wont be caught
        /* eslint-enable jsx-a11y/no-noninteractive-tabindex */
        /* eslint-enable jsx-a11y/tabindex-no-positive */
      >
        <NumberFormat
          value={inputValue}
          label={labelText}
          helperText={helperText}
          required={required}
          error={!isValid}
          margin={margin}
          variant={variant}
          size={size}
          customInput={TextField}
          thousandSeparator={thousandSeparator}
          allowNegative={allowNegative}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          isAllowed={isAllowed}
          // onChange={inputHandler}
          // Known issue: onChange not triggered for minus sign backspace
          // https://github.com/s-yadav/react-number-format/issues/322
          onValueChange={inputHandler}
        />
      </div>
    );
  }
);

NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  thousandSeparator: PropTypes.bool,
  allowNegative: PropTypes.bool,
  isAllowed: PropTypes.func,
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  column: PropTypes.shape({ colId: PropTypes.string }).isRequired,
  required: PropTypes.bool,
  labelText: PropTypes.string,
  helperText: PropTypes.string,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  variant: PropTypes.oneOf(['standard', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium']),
  isValidatedCheckFn: PropTypes.func,
};

NumberInput.defaultProps = {
  required: false,
  labelText: '',
  helperText: '',
  margin: 'dense',
  variant: 'outlined',
  size: 'small',
  thousandSeparator: false,
  allowNegative: true,
  isAllowed: () => true,
  decimalScale: null,
  fixedDecimalScale: false,
  isValidatedCheckFn: () => true,
};

export default NumberInput;
