import React from 'react';
import DeleteRecordRowRenderer from './renderers/DeleteRecordRow';
import staticDataOrderTypes from '../data/static-data-order-types';
import staticDataTimeInForceOpts from '../data/static-data-time-in-force-opts';

import SelectInstrument from '../../SelectInstrument';
import Status from './renderers/Status';

const onFirstDataRendered = (params) => {
  params.api.sizeColumnsToFit();
};

const numberParser = (params) => {
  const value = Number(params.newValue);
  if (Number.isNaN(value)) {
    return params.oldValue;
  }
  // Validate no negative value
  return value >= 0 ? value : params.oldValue;
};

function generateConfig(deleteRecordHandler) {
  const columnDefs = [
    {
      minWidth: 35,
      maxWidth: 35,
      cellRendererFramework: (props) => (
        <DeleteRecordRowRenderer
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          deleteRecordHandler={deleteRecordHandler}
        />
      ),
      suppressColumnsToolPanel: true,
      pinned: 'right',
      editable: false,
    },
    {
      headerName: 'User/Cpty',
      field: 'counterparty',
      minWidth: 100,
      comparator: () => 0,
      editable: false,
    },
    {
      headerName: 'Instrument',
      field: 'instrumentId',
      minWidth: 170,
      editable: false,
      cellRendererFramework: SelectInstrument,
    },
    {
      headerName: 'Enabled',
      field: 'enabled',
      minWidth: 80,
      editable: false,
      cellRendererFramework: Status,
    },
    {
      headerName: 'Order Type',
      field: 'orderType',
      minWidth: 100,
      editable: true,
      cellEditorSelector: () => {
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: staticDataOrderTypes.map((li) => li.value),
          },
        };
      },
    },
    {
      headerName: 'TIF',
      field: 'timeInForce',
      minWidth: 80,
      maxWidth: 80,
      editable: true,
      cellEditorSelector: () => {
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: staticDataTimeInForceOpts.map((li) => li.value),
          },
        };
      },
    },
    {
      headerName: 'Hedge Ratio',
      field: 'hedgeRatio',
      minWidth: 80,
      editable: true,
      valueSetter: (params) => {
        const { newValue, oldValue } = params;
        // Validate value less than 10
        params.data.hedgeRatio =
          newValue > 10 ? oldValue : Number(newValue.toFixed(2));
        return true;
      },
      valueParser: numberParser,
    },
    {
      headerName: 'Copy Min',
      field: 'copyMinQty',
      minWidth: 80,
      valueParser: numberParser,
      valueSetter: (params) => {
        const { data, newValue, oldValue } = params;
        params.data.copyMinQty =
          data.copyMaxQty >= newValue ? newValue : oldValue;
        return true;
      },
    },
    {
      headerName: 'Copy Max',
      field: 'copyMaxQty',
      minWidth: 80,
      valueParser: numberParser,
      valueSetter: (params) => {
        const { data, newValue, oldValue } = params;
        params.data.copyMaxQty =
          data.copyMinQty <= newValue ? newValue : oldValue;
        return true;
      },
    },
    {
      headerName: 'Retry Intervals',
      field: 'retryIntervalMillis',
      minWidth: 100,
      valueParser: numberParser,
    },
  ];

  const defaultColDef = {
    flex: 1,
    sortable: false,
    filter: true,
    editable: true,
  };

  return {
    columnDefs,
    defaultColDef,
    onFirstDataRendered,
  };
}

export default generateConfig;
