import { io } from 'socket.io-client';

import tokenUtils from './token';
import config from '../app-config';
import logUtil from './logger';

const host = `${config.socketIO.baseUrl[process.env.NODE_ENV || 'production']}`;
const path = `${config.socketIO.path[process.env.NODE_ENV || 'production']}`;
const JWT_ERROR_MAX_RETRIES = Number(
  process.env.REACT_APP_JWT_ERROR_MAX_RETRIES || 2
);
const OKTA_TOKEN_STORAGE_KEY =
  process.env.REACT_APP_OKTA_TOKEN_STORAGE_KEY || 'okta-token-storage';
const OKTA_SIGN_IN_PATHNAME =
  process.env.REACT_APP_OKTA_SIGN_IN_PATHNAME || 'sign-in';

const abortAndRedirectToSignIn = ({
  logger,
  isTokenMissing,
  jwtRetryCounter,
}) => {
  // TODO: consider resetting state, then emit event to trigger redirect
  // to sign-in route with better feedback to user describing what just happened
  const { location } = document;
  const urlSignIn = `${location.origin}/${OKTA_SIGN_IN_PATHNAME}`;

  if (isTokenMissing) {
    // eslint-disable-next-line no-console
    console.warn(`JWT access token missing: redirecting to: ${urlSignIn}`);
    logger.logWarning(
      `JWT access token missing:: redirecting to: ${urlSignIn}`
    );
  }

  if (jwtRetryCounter >= JWT_ERROR_MAX_RETRIES) {
    // eslint-disable-next-line no-console
    console.warn(
      `JWT error max retry attempt(s) ${JWT_ERROR_MAX_RETRIES} reached: redirecting to: ${urlSignIn}`
    );
    logger.logWarning(
      `JWT error max retry attempt(s) ${JWT_ERROR_MAX_RETRIES} reached: redirecting to: ${urlSignIn}`
    );
  }

  window.sessionStorage.removeItem(OKTA_TOKEN_STORAGE_KEY);
  window.location.href = urlSignIn;
};

const buildOpts = ({ customPath = '' } = {}) => {
  let opts = {
    autoConnect: false,
  };

  if (typeof customPath === 'string' && customPath.length > 0) {
    opts = {
      ...opts,
      path: customPath,
    };
  }

  return opts;
};

export default class socketAPI {
  constructor(oktaAuth) {
    this.oktaAuth = oktaAuth;
    this.socket = null;
    this.jwtRetryCounter = 0;
    this.logger = logUtil();
  }

  connect() {
    this.socket = io(host, buildOpts({ customPath: path }));
    this.socket.auth = { authToken: tokenUtils.getAccessToken(this.oktaAuth) };
    // this.socket.auth = { authToken: 'bad-token-for-testing-authn' };
    this.socket.connect();

    /**
     * fired by the Socket instance upon connection AND reconnection
     */
    this.socket.on('connect', () => {
      // eslint-disable-next-line no-console
      console.log(`socket connected: ${this.socket.connected}`);
      // eslint-disable-next-line no-console
      console.log(`socket id: ${this.socket.id}`);
      this.logger.logDebug('socket connected');

      // /**
      //  * !!! START DEV TESTING ONLY !!!
      //  * replace token with expired token
      //  */
      // const prevData = JSON.parse(sessionStorage.getItem('okta-token-storage'));
      // // access token expiry: 1686393846 or Saturday, 10 June 2023 11:44:06 GMT+01:00 DST
      // prevData.accessToken.accessToken =
      //   'eyJraWQiOiJ3TVpwSFd0WC11amxuampFbG53VTFRNzNzVzVEWVJiLWVvTTdRblZYcEIwIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkw5QnExMHBGb3FxZFFRclIwZVJtWkE3alptNExJY3I2YUZTajlsdDZ1Njgub2FyMWRob3kxZnZncFIyQ3A0eDciLCJpc3MiOiJodHRwczovL29pZGMuZWRnZWZ4LnByby9vYXV0aDIvZGVmYXVsdCIsImF1ZCI6ImFwaTovL2RlZmF1bHQiLCJpYXQiOjE2ODYzOTAyNDYsImV4cCI6MTY4NjM5Mzg0NiwiY2lkIjoiMG9hc2VoamFsRGlWOEFGMTM0eDYiLCJ1aWQiOiIwMHVxZDRmdHhpdXJqRVpSUzR4NiIsInNjcCI6WyJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJlbWFpbCIsIm9wZW5pZCIsInBlcm1pc3Npb25zIl0sImF1dGhfdGltZSI6MTY4NjMxMDM2MCwic3ViIjoibGZ1cnpld2FkZG9ja0BlZGdld2F0ZXJtYXJrZXRzLmNvbSIsImdyb3VwcyI6WyJFdmVyeW9uZSJdfQ.UgpOfuYc9spoGNE3oNh8KJX9ODtNErfZKdzA_GjwrMPoiw-YflomhW9kONfIDTEByK8N405NI_OHz5wQwumuJiVcF_U_cg_1wMv0SKVNxS3xOGqyyfzvczlL_wlNH0TwyAvPHqrC783Q682kRk1gmzoQ4pILORvak7TC1ih_QEsWY8TSo1Iv4AP2-9XUpgtDyVLBoampUWFKiGaHji9qK6aeMKiMC8w7B0OOuCScb_r-nqGGqSPpXmCQWtpASjM9RCGjMD4J-t4bybmR9QYQESREuBwtTwVRjtUgp-1hb-vg1ktB41Jx6TUy7PfKITxM208beIL6MKMllKS90cOMqg';
      // sessionStorage.setItem('okta-token-storage', JSON.stringify(prevData));
      // /**
      //  * !!! END DEV TESTING ONLY !!!
      //  */
    });
    /**
     * fired when:
     *      1) the low-level connection cannot be established: Socket will automatically try to reconnect, after a given delay
     *      2) the connection is denied by the server in a middleware function: may need to manually reconnect. You might need to update the credentials
     */
    this.socket.on('connect_error', (e) => {
      if (e != null && e.data != null) {
        // eslint-disable-next-line no-console
        console.log(
          `socket connect_error JWT retry ${this.jwtRetryCounter} of ${JWT_ERROR_MAX_RETRIES}`
        );
        let jsonErrorData = null;
        try {
          jsonErrorData = JSON.parse(e.data);
          // eslint-disable-next-line no-console
          console.info('socket connect_error data', e.data);
        } catch (jsonParseErr) {
          // eslint-disable-next-line no-console
          console.error(
            'parse connect_error data property error',
            jsonParseErr
          );
        }

        if (jsonErrorData.name === 'JwtAuthTokenMissing') {
          this.jwtRetryCounter = 0;
          abortAndRedirectToSignIn({
            logger: this.logger,
            isTokenMissing: true,
            jwtRetryCounter: this.jwtRetryCounter,
          });
        } else if (jsonErrorData.name === 'JwtParseError') {
          this.jwtRetryCounter += 1;
          if (this.jwtRetryCounter <= JWT_ERROR_MAX_RETRIES) {
            if (
              jsonErrorData.message &&
              typeof jsonErrorData.message === 'string' &&
              jsonErrorData.message.toUpperCase() === 'JWT IS EXPIRED'
            ) {
              // eslint-disable-next-line no-console
              console.warn('access token expired!');
              // eslint-disable-next-line no-console
              console.log('attempt manual JWT access token renew');
              this.oktaAuth.tokenManager
                .renew('accessToken')
                .then((jwt) => {
                  // eslint-disable-next-line no-console
                  console.log('manual JWT access token renew succeeded');
                  this.socket.auth = {
                    authToken: jwt.accessToken,
                  };
                  // eslint-disable-next-line no-console
                  console.log('attempt manual connect');
                  this.socket.connect();
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error('manual JWT access token renew failed:', err);
                  this.logger.logError(err);
                });
            } else {
              this.socket.auth = {
                authToken: tokenUtils.getAccessToken(this.oktaAuth),
              };
              this.socket.connect();
            }
          } else {
            this.jwtRetryCounter = 0;
            abortAndRedirectToSignIn({
              logger: this.logger,
              isTokenMissing: false,
              jwtRetryCounter: this.jwtRetryCounter,
            });
          }
        }
      }
      // eslint-disable-next-line no-console
      console.error('socket connect_error', e);
      this.logger.logError(e);
    });
    this.socket.io.on('reconnect', (attempt) => {
      // eslint-disable-next-line no-console
      console.log('socket reconnected at attempt', attempt);
      this.logger.logDebug('socket reconnected');
    });
    this.socket.io.on('reconnect_attempt', (attempt) => {
      this.socket.auth = {
        authToken: tokenUtils.getAccessToken(this.oktaAuth),
      };
      // eslint-disable-next-line no-console
      console.log('socket reconnect attempt', attempt);
    });
    this.socket.io.on('reconnect_error', (e) => {
      // eslint-disable-next-line no-console
      console.error('socket reconnect_error', e);
      this.logger.logError(e);
    });
    this.socket.io.on('reconnect_failed', () => {
      // eslint-disable-next-line no-console
      console.warn(
        `socket reconnect failed within max reconnection attempts: abort reconnect!`
      );
      this.logger.logCriticalMsg('socket reconnect_failed');
      if (!this.socket.connected) {
        this.logger.logInfo('socket not connected: attempt manual connect');
        this.socket.auth = {
          authToken: tokenUtils.getAccessToken(this.oktaAuth),
        };
        this.socket.connect();
      }
    });
    this.socket.io.on('ping', () => {
      // eslint-disable-next-line no-console
      console.log('socket ping: socket connected', this.socket.connected);
    });
    this.socket.io.on('error', (e) => {
      // eslint-disable-next-line no-console
      console.error('socket connection error', e);
      this.logger.logError(e);
    });
    this.socket.on('disconnect', (reason) => {
      /**
       * The server has forcefully disconnected the socket with socket.disconnect(): NOT auto-reconnect
       * Client will NOT try to reconnect
       */
      if (reason === 'io server disconnect') {
        // eslint-disable-next-line no-console
        console.warn('socket disconnect initiated by: server');
        this.logger.logWarning('socket disconnect initiated by: server');
        this.socket.auth = {
          authToken: tokenUtils.getAccessToken(this.oktaAuth),
        };
        this.socket.connect();
        this.logger.logInfo('manual connect attempted');
      }

      /**
       * The socket was manually disconnected using socket.disconnect(): NOT auto-reconnect
       * Client will NOT try to reconnect
       */
      if (reason === 'io client disconnect') {
        // eslint-disable-next-line no-console
        console.log('socket disconnect initiated by: client');
      }

      /**
       * The server did not send a PING within the pingInterval + pingTimeout range: auto-reconnect
       * Client will wait for a small random delay and then try to reconnect
       */
      if (reason === 'ping timeout') {
        // eslint-disable-next-line no-console
        console.warn('socket disconnected: ping timeout');
        this.logger.logWarning('socket disconnected: ping timeout');
      }

      /**
       * The connection was closed (example: the user has lost connection, or the network was changed from WiFi to 4G): auto-reconnect
       * Client will wait for a small random delay and then try to reconnect
       */
      if (reason === 'transport close') {
        // eslint-disable-next-line no-console
        console.warn('socket disconnected: transport close');
        this.logger.logWarning('socket disconnected: transport close');
      }

      /**
       * The connection has encountered an error (example: the server was killed during a HTTP long-polling cycle): auto-reconnect
       */
      if (reason === 'transport error') {
        // eslint-disable-next-line no-console
        console.warn('socket disconnected: transport error');
        this.logger.logWarning('socket disconnected: transport error');
      }

      this.logger.logDebug(`previous socket disconnection due to: ${reason}`);
    });

    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => {
        return resolve();
      });
      this.socket.on('connect_error', (error) => reject(error)); // Fired upon a connection error
      this.socket.io.on('error', (error) => reject(error)); // Fired upon a connection error.
    });
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.disconnect(() => {
        this.socket = null;
        resolve();
      });
    });
  }

  emit(event, data) {
    return new Promise((resolve, reject) => {
      if (!this.socket) reject(new Error('No socket connection.'));

      this.socket.emit(event, data, (response) => {
        if (response.error) {
          // eslint-disable-next-line no-console
          console.warn('socket emit received error in response');
          // eslint-disable-next-line no-console
          console.error(response.error);
          this.logger.logError(response.error);
          reject(response.error);
        }
        resolve(response);
      });
    });
  }

  on(event, fun) {
    // No promise is needed here, but let's be consistent.
    return new Promise((resolve, reject) => {
      if (!this.socket) reject(new Error('No socket connection.'));
      this.socket.on(event, fun);
      resolve();
    });
  }
}
