import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Loader({ color, size, thickness }) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color={color} size={size} thickness={thickness} />
    </Backdrop>
  );
}

Loader.propTypes = {
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.number,
};

Loader.defaultProps = {
  color: 'primary',
  size: 40,
  thickness: 3.6,
};

export default Loader;
