import presentersCommon from '../../../presenters/common';

function presenter({
  ID_CTRL_PRE_ACTIVE,
  ID_CTRL_PRE_INACTIVE,
  ID_CTRL_SKEW_ACTIVE,
  ID_CTRL_SKEW_INACTIVE,
  ID_CTRL_POST_ACTIVE,
  ID_CTRL_POST_INACTIVE,
}) {
  const initialFormState = {
    [ID_CTRL_PRE_ACTIVE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: [],
      options: [],
      validators: [],
    },
    [ID_CTRL_PRE_INACTIVE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: [],
      options: [],
      validators: [],
    },
    [ID_CTRL_SKEW_ACTIVE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: [],
      options: [],
      validators: [],
    },
    [ID_CTRL_SKEW_INACTIVE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: [],
      options: [],
      validators: [],
    },
    [ID_CTRL_POST_ACTIVE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: [],
      options: [],
      validators: [],
    },
    [ID_CTRL_POST_INACTIVE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: [],
      options: [],
      validators: [],
    },
    crtlPresetPairs: [
      {
        name: 'spread',
        id: {
          active: ID_CTRL_PRE_ACTIVE,
          inactive: ID_CTRL_PRE_INACTIVE,
        },
      },
      {
        name: 'skew',
        id: {
          active: ID_CTRL_SKEW_ACTIVE,
          inactive: ID_CTRL_SKEW_INACTIVE,
        },
      },
      {
        name: 'post',
        id: {
          active: ID_CTRL_POST_ACTIVE,
          inactive: ID_CTRL_POST_INACTIVE,
        },
      },
    ],
  };

  const presetsWithTypeNamesFlat = (presets) => {
    return presets.map((preset) => {
      const { id, name, types, isActive } = preset;
      const typeNames = types.reduce((acc, cur) => {
        if (!acc.includes(cur.primary)) {
          return [...acc, cur.primary];
        }
        return acc;
      }, []);
      return {
        id,
        name,
        isActive,
        types: typeNames,
      };
    });
  };

  // REDUNDANT
  // const presetsWithTypesPivot = ({
  //   data,
  //   selectedValues,
  //   isEnabled = false,
  // } = {}) => {
  //   return [
  //     ...data
  //       .map((preset) => {
  //         return {
  //           ...preset,
  //           ...preset.types.reduce((acc, cur) => {
  //             return {
  //               ...acc,
  //               ...{
  //                 [`${
  //                   cur.primary
  //                 }${cur.secondary[0].toUpperCase()}${cur.secondary.slice(
  //                   1
  //                 )}`]: cur.value,
  //               },
  //             };
  //           }, {}),
  //         };
  //       })
  //       .reduce((acc, cur) => {
  //         const rows = [];
  //         for (let i = 0; i < selectedValues.length; i += 1) {
  //           if (selectedValues[i].id === cur.id) {
  //             rows.push({
  //               id: cur.id,
  //               enabled: isEnabled ? 1 : 0,
  //               group: cur.group || '*',
  //               instrument: cur.instrument || '*',
  //               askSpread: cur.spreadAsk || 0,
  //               bidSpread: cur.spreadBid || 0,
  //               askSkew: cur.skewAsk || 0,
  //               bidSkew: cur.skewBid || 0,
  //               buyPost: cur.postBuy || 0,
  //               sellPost: cur.postSell || 0,
  //             });
  //           }
  //         }
  //         return [...acc, ...rows];
  //       }, []),
  //   ];
  // };

  // REPLACED
  // const presetsWithTypesPivot = ({
  //   prevState,
  //   data,
  //   optionValues,
  //   selectedValues,
  // }) => {
  //   return [
  //     ...prevState.filter(
  //       (state) => [...optionValues].indexOf(state.name) === -1
  //     ),
  //     ...data
  //       .map((preset) => {
  //         return {
  //           ...preset,
  //           ...preset.types.reduce((acc, cur) => {
  //             return {
  //               ...acc,
  //               ...{
  //                 [`${
  //                   cur.primary
  //                 }${cur.secondary[0].toUpperCase()}${cur.secondary.slice(
  //                   1
  //                 )}`]: cur.value,
  //               },
  //             };
  //           }, {}),
  //         };
  //       })
  //       .reduce((acc, cur) => {
  //         const rows = [];
  //         for (let i = 0; i < selectedValues.length; i += 1) {
  //           if (selectedValues[i].id === cur.id) {
  //             rows.push({
  //               id: cur.id,
  //               name: cur.group || '*',
  //               instrument: cur.instrument || '*',
  //               spreadAsk: cur.spreadAsk || 0,
  //               spreadBid: cur.spreadBid || 0,
  //               skewAsk: cur.skewAsk || 0,
  //               skewBid: cur.skewBid || 0,
  //               postBuy: cur.postBuy || 0,
  //               postSell: cur.postSell || 0,
  //             });
  //           }
  //         }
  //         return [...acc, ...rows];
  //       }, []),
  //   ];
  // };

  const getPresetOptionsByTypeAndIsActive = ({
    presets,
    pairName,
    isActive,
  }) => {
    return presets.reduce((acc, cur) => {
      if (cur.types.includes(pairName) && cur.isActive === isActive) {
        return [
          ...acc,
          {
            id: cur.id,
            name: cur.name,
          },
        ];
      }
      return acc;
    }, []);
  };

  const loadCtrlState = ({ state, name, options }) => {
    const def = { ...state[name] };

    return {
      ...state,
      [name]: {
        ...def,
        options,
      },
    };
  };

  const updateValuesCtrlStateSliceProp = ({
    state,
    identifier,
    propName,
    values,
  }) => {
    const def = { ...state[identifier] };

    return {
      ...state,
      [identifier]: {
        ...def,
        [propName]: [...def[propName], ...values],
      },
    };
  };

  const replaceValuesCtrlStateSliceProp = ({
    state,
    identifier,
    propName,
    values,
  }) => {
    const def = { ...state[identifier] };

    const ids = values.map((value) => value.id);

    const options = def[propName].filter((opt) => {
      return ids.indexOf(opt.id) === -1;
    });

    return {
      ...state,
      [identifier]: {
        ...def,
        [propName]: options,
      },
    };
  };

  const replaceValueCtrlStateSliceProp = ({
    state,
    identifier,
    propName,
    value,
  }) => {
    const def = { ...state[identifier] };

    return {
      ...state,
      [identifier]: {
        ...def,
        [propName]: value,
      },
    };
  };

  const getSelectedOptionValues = (options) => {
    return [...options].reduce((acc, cur) => {
      if (cur.selected) return [...acc, { id: +cur.value, name: cur.label }];
      return acc;
    }, []);
  };

  // const getOptionValues = (options) => {
  //   return [...options].map((option) => option.value);
  // };

  const calcualteSelectCtrlHeight = (options) => {
    if (options.length) return `${options.length * 18}px`;
    return '18px';
  };

  return Object.freeze({
    initialFormState,
    presetsWithTypeNamesFlat,
    // presetsWithTypesPivot,
    loadCtrlState,
    getPresetOptionsByTypeAndIsActive,
    updateValuesCtrlStateSliceProp,
    replaceValueCtrlStateSliceProp,
    replaceValuesCtrlStateSliceProp,
    manageCtrlState: presentersCommon.manageCtrlState,
    hasErrors: presentersCommon.hasErrors,
    validateCtrls: presentersCommon.validateCtrls,
    sortObjArrByProp: presentersCommon.sortObjArrByProp,
    getSelectedOptionValues,
    // getOptionValues,
    calcualteSelectCtrlHeight,
  });
}

export default presenter;
