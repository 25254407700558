import ValTypeBoolNum from '../../ValTypeBoolNum';

const entityStream = {
  id: Number,
  streamDescription: String,
  streamLogic: Number,
  enabled: ValTypeBoolNum,
};

export default entityStream;
