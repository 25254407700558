import Client from '../../domain/ctx/client/Client';

function dtoClient(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const client = Client({
    name: gatewayDataObject.name,
  });

  if (gatewayDataObject.groups) {
    client.groups = gatewayDataObject.groups;
  }

  return client;
}

export default dtoClient;
