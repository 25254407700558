import ValTypeAlertLevel from '../ValTypeAlertLevel';
import ValTypeHtmlSimpleMarkup from '../../../ValTypeHtmlSimpleMarkup';

const valTypeAlertMessage = {
  level: ValTypeAlertLevel,
  datacenter: String,
  timestamp: Date,
  summary: String,
  htmlDetails: ValTypeHtmlSimpleMarkup,
  isSendEmail: Boolean,
};

export default valTypeAlertMessage;
