import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ToastAlert from '../../../components/ToastAlert';

import presenter from './presenter';

const useStyles = makeStyles(() => ({
  alertAction: {
    alignItems: 'start',
  },
}));

function SystemAlertMessages({ stateSystemAlert }) {
  const classes = useStyles();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const present = presenter();
  const presentVm = useCallback(present.vm, []);
  const [vm, setVm] = useState(presentVm(null));

  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  useEffect(() => {
    if (
      stateSystemAlert &&
      (stateSystemAlert.retrieveResult || stateSystemAlert.retrieveError)
    ) {
      setIsAlertOpen(true);
      setVm(presentVm(stateSystemAlert.retrieveResult));
    }
  }, [stateSystemAlert, presentVm]);

  return (
    <ToastAlert
      severity={vm.severity}
      isAlertOpen={isAlertOpen}
      closeAlertHandler={handleCloseAlert}
      alertTitleCopy={vm.alertTitleCopy}
      alertBodyCopy={vm.alertBodyCopy}
      alertClasses={{ action: classes.alertAction }}
    />
  );
}

SystemAlertMessages.propTypes = {
  stateSystemAlert: PropTypes.shape({
    retrieveResult: PropTypes.shape({
      level: PropTypes.oneOf(['URGENT', 'WARNING', 'LOW']),
      datacenter: PropTypes.oneOf(['NY4', 'LD4', 'TY3']),
      timestamp: PropTypes.instanceOf(Date),
      summary: PropTypes.string,
      isSendEmail: PropTypes.bool,
      htmlDetails: PropTypes.string,
    }),
    retrieveError: PropTypes.instanceOf(Error),
  }),
};

SystemAlertMessages.defaultProps = {
  stateSystemAlert: null,
};

export default SystemAlertMessages;
