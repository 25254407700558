import React from 'react';
import { ErrorBoundary } from '@sentry/react';

import ForgottenPasswordForm from '../../forms/ForgottenPasswordForm';
import Fallback from '../../components/Fallback';

function ForgottenPassword() {
  return (
    <ErrorBoundary fallback={Fallback}>
      <ForgottenPasswordForm />
    </ErrorBoundary>
  );
}

export default ForgottenPassword;
