import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';

/*
Note issue #7 workaround: CSS injection order

Use 'injectFirst' prop on StylesProvider to force CSS 
specificity downgrade of CSS injected from this component.

Use 'classes' prop on Switch to apply required 'switchBase' 
CSS otherwise missing/ignored due to CSS specificity downgrade, 
resulting from the use of 'injectFirst'.
*/
const useStyles = makeStyles(() => ({
  ctrl: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  formCtrlLabel: {
    fontSize: '0.8rem',
  },
  switchBase: {
    top: 0,
    left: 0,
    color: '#fafafa',
    zIndex: 1,
    position: 'absolute',
    transition:
      'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

function BinarySwitch({ setValue, value, size }) {
  const classes = useStyles();
  return (
    <div>
      <StylesProvider injectFirst>
        <FormControlLabel
          className={classes.ctrl}
          classes={{ label: classes.formCtrlLabel }}
          control={
            <Switch
              classes={{ switchBase: classes.switchBase }}
              checked={value}
              size={size}
              onChange={() => setValue(!value)}
            />
          }
          label="Active"
        />
      </StylesProvider>
    </div>
  );
}

BinarySwitch.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
};

BinarySwitch.defaultProps = {
  size: 'small',
};

export default BinarySwitch;
