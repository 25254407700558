// Login layout
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
}));

function Minimal({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

Minimal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default Minimal;
