import { connect } from 'react-redux';

import PriceControlsEditor from './PriceControlsEditor';

import {
  retrieve as retrievePriceControlPresetsAction,
  update as updatePriceControlPresetsAction,
  remove as removePriceControlPresetsAction,
  cmd as cmdPriceControlPresetsAction,
} from '../../store/modules/price-control-presets';
import { retrieve as retrieveClientGroupAction } from '../../store/modules/client-group';
import { retrieve as retrievePriceControlPresetInstrumentAction } from '../../store/modules/price-control-preset-instrument';

const mapStateToProps = function mapStateToProps(state) {
  const {
    clientGroup: stateClientGroup,
    priceControlPresets: statePriceControlPresets,
    priceControlPresetInstrument: statePriceControlPresetInstrument,
  } = state;
  return {
    stateClientGroup,
    statePriceControlPresets,
    statePriceControlPresetInstrument,
  };
};

export default connect(mapStateToProps, {
  retrieveClientGroupAction,
  retrievePriceControlPresetsAction,
  updatePriceControlPresetsAction,
  removePriceControlPresetsAction,
  retrievePriceControlPresetInstrumentAction,
  cmdPriceControlPresetsAction,
})(PriceControlsEditor);
