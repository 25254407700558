import React from 'react';

import DeleteRowRenderer from '../../../components/ag-grid/renderers/DeleteRow';
// import BinaryOnOffSwitchRenderer from '../../../components/ag-grid/renderers/BinaryOnOffSwitch';
import BinarySwitchRenderer from '../../../components/ag-grid/renderers/BinarySwitch';

import RadioBtnsEditor from '../../../components/ag-grid/editors/RadioBtns';
import AutoCompleteEditor from '../../../components/ag-grid/editors/AutoComplete';
import TextInputEditor from '../../../components/ag-grid/editors/TextInput';
import NumberInputEditor from '../../../components/ag-grid/editors/NumberInput';

const sizeColumnsToFit = (params) => {
  params.api.sizeColumnsToFit();
};

const onFirstDataRendered = (params) => {
  sizeColumnsToFit(params);
};

const onGridSizeChanged = (params) => {
  sizeColumnsToFit(params);
};

const toUpperCase = (params) => {
  if (params.value == null) return '';
  return params.value.toUpperCase();
};

const cellClassRules = {
  'is-active-flash': (params) => {
    return params.data.isActive;
  },
  'not-active-flash': (params) => {
    return !params.data.isActive;
  },
};

function generateConfig({
  stateInstrumentData,
  stateGroupData,
  deleteHandler,
}) {
  const sharedColDefs = [
    {
      headerName: 'Status',
      field: 'isActive',
      minWidth: 100,
      maxWidth: 100,
      pinned: 'left',
      editable: false,
      // cellRenderer: 'binaryOnOffSwitchRenderer',
      cellRenderer: 'binarySwitchRenderer',
    },
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 200,
      cellEditor: 'textInputEditor',
      cellEditorParams: {
        isValidatedCheckFn: function isValidatedCheckFn(input) {
          const alphaNumDashRegex = /^([A-Za-z\d-])+$/i;
          /* eslint-enable */
          if (
            !input.trim().length > 0 ||
            !input.trim().match(alphaNumDashRegex)
          )
            return false;
          return true;
        },
        labelText: 'Preset Name',
        helperText: 'Enter name for preset',
        textFieldStyle: { width: '300px' },
        required: true,
      },
    },
    {
      headerName: 'Group Name',
      field: 'group',
      minWidth: 200,
      cellEditor: 'autoCompleteEditor',
      cellEditorParams: {
        options: stateGroupData.map((item) => ({
          id: item.name,
          label: item.name,
        })),
        labelText: 'Groups',
        helperText: 'Select a group',
        autoCompleteStyle: { width: '300px', padding: '0 10px' },
      },
    },
    {
      headerName: 'Instrument',
      field: 'instrument',
      minWidth: 150,
      maxWidth: 150,
      cellEditor: 'autoCompleteEditor',
      cellEditorParams: {
        options: stateInstrumentData.map((item) => ({
          id: item.item,
          label: item.item,
        })),
        labelText: 'Instruments',
        helperText: 'Select an instrument',
        autoCompleteStyle: { width: '200px', padding: '0 10px' },
      },
    },
    {
      headerName: 'Tool',
      field: 'type',
      minWidth: 100,
      maxWidth: 100,
      valueFormatter: toUpperCase,
      // Note: impl below DOES NOT WORK, because ref is an empty object
      // TODO: investigate

      // cellEditorFramework: (props, ref) => (
      //   <ProfitTools
      //     // eslint-disable-next-line react/jsx-props-no-spreading
      //     {...props}
      //     ref={ref}
      //   />
      // ),
      cellEditor: 'radioBtnsEditor',
      cellEditorParams: {
        options: [
          {
            value: 'spread',
            label: 'Spread',
            labelPlacement: 'top',
            radioBtnSize: 'small',
          },
          {
            value: 'skew',
            label: 'Skew',
            labelPlacement: 'top',
            radioBtnSize: 'small',
          },
          {
            value: 'post',
            label: 'Post',
            labelPlacement: 'top',
            radioBtnSize: 'small',
          },
        ],
        required: true,
        labelText: 'Profit Tools',
        helperText: 'Select profit tool',
      },
    },
    {
      headerName: 'Left Side',
      field: 'leftSide',
      minWidth: 100,
      maxWidth: 100,
      cellEditor: 'numberInputEditor',
      cellEditorParams: {
        isValidatedCheckFn: function isValidatedCheckFn(input) {
          if (!input.trim().length > 0) return false;
          return true;
        },
        labelText: 'Left Side',
        helperText: 'Enter value',
        decimalScale: 5,
        required: true,
      },
    },
    {
      headerName: 'Right Side',
      field: 'rightSide',
      minWidth: 100,
      maxWidth: 100,
      cellEditor: 'numberInputEditor',
      cellEditorParams: {
        isValidatedCheckFn: function isValidatedCheckFn(input) {
          if (!input.trim().length > 0) return false;
          return true;
        },
        labelText: 'Right Side',
        helperText: 'Enter value',
        decimalScale: 5,
        required: true,
      },
    },
  ];

  const sharedDefaultColDefs = {
    flex: 1,
    sortable: true,
    filter: true,
    editable: true,
    cellClassRules,
  };

  return {
    columnDefs: [
      {
        minWidth: 35,
        maxWidth: 35,
        cellRendererFramework: (props) => {
          // eslint-disable-next-line react/prop-types
          const { value, data } = props;
          // eslint-disable-next-line react/prop-types
          const { id } = data;
          return (
            <DeleteRowRenderer
              confirmDialogTitle="Delete price control preset?"
              confirmDialogActionLabel="delete"
              confirmDialogEntityLabel="preset"
              disabled={value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              deleteRowHandler={() => deleteHandler({ id })}
            />
          );
        },
        headerName: '',
        field: 'isActive',
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        pinned: 'right',
        editable: false,
        sortable: false,
      },
      ...sharedColDefs,
    ],
    frameworkComponents: {
      autoCompleteEditor: AutoCompleteEditor,
      radioBtnsEditor: RadioBtnsEditor,
      textInputEditor: TextInputEditor,
      numberInputEditor: NumberInputEditor,
      // binaryOnOffSwitchRenderer: BinaryOnOffSwitchRenderer,
      binarySwitchRenderer: BinarySwitchRenderer,
    },
    defaultColDef: {
      ...sharedDefaultColDefs,
    },
    sharedColDefs,
    sharedDefaultColDefs,
    onFirstDataRendered,
    onGridSizeChanged,
  };
}

export default generateConfig;
