import { arrayOf, bool, number, shape, string } from 'prop-types';

const propTypeStatePriceControlPreset = shape({
  isRetrieving: bool,
  isCreating: bool,
  isCmdInProgress: bool,
  retrieveResult: shape({
    hasRecords: bool,
    count: number,
    data: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
        group: string,
        instrument: string,
        isActive: bool.isRequired,
        types: arrayOf(
          shape({
            id: number.isRequired,
            primary: string.isRequired,
            secondary: string.isRequired,
            value: number.isRequired,
            priceControlPresetId: number.isRequired,
          })
        ),
      })
    ),
  }),
});

export default propTypeStatePriceControlPreset;
