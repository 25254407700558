import { arrayOf, bool, element, number, shape, string } from 'prop-types';

const propTypeGroup = shape({
  isRetrieving: bool,
  retrieveError: element,
  retrieveResult: shape({
    hasRecords: bool.isRequired,
    count: number.isRequired,
    data: arrayOf(
      shape({
        name: string,
      })
    ),
  }),
  selected: string,
});

export default propTypeGroup;
