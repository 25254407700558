import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorBoundary } from '@sentry/react';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../hooks/trace-update-hook';

import WidgetOrderBlotter from '../../widgets/OrderBlotter';
import Fallback from '../../components/Fallback';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function Dashboard() {
  const classes = useStyles();

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate(props);

  return (
    <Container maxWidth={false} className={classes.root}>
      <ErrorBoundary fallback={Fallback}>
        <WidgetOrderBlotter />
      </ErrorBoundary>
    </Container>
  );
}

export default Dashboard;
