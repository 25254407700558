import he from 'he';

function presenter() {
  const presetsWithTypesPivot = ({ data }) => {
    return [
      ...data
        .map((preset) => {
          return {
            ...preset,
            ...preset.types.reduce((acc, cur) => {
              return {
                ...acc,
                ...{
                  type: cur.primary,
                  [cur.secondary]: cur.value,
                },
              };
            }, {}),
          };
        })
        .reduce((acc, cur) => {
          return [
            ...acc,
            {
              id: cur.id,
              isActive: cur.isActive,
              name: cur.name,
              group: cur.group != null ? he.decode(cur.group) : '*',
              instrument:
                cur.instrument != null ? he.decode(cur.instrument) : '*',
              type: cur.type,
              leftSide: cur.bid || 0,
              rightSide: cur.ask || 0,
            },
          ];
        }, []),
    ];
  };

  function mergePresetUpdates(statePreset, colField, newValue) {
    if (
      colField === 'name' ||
      colField === 'group' ||
      colField === 'instrument'
    ) {
      return {
        ...statePreset,
        [colField]: newValue,
      };
    }

    if (colField === 'type') {
      return {
        ...statePreset,
        types: statePreset.types.map((type) => ({
          ...type,
          primary: newValue,
        })),
      };
    }

    if (colField === 'leftSide') {
      return {
        ...statePreset,
        types: statePreset.types.map((type) => {
          if (type.secondary === 'bid') {
            return {
              ...type,
              value: newValue,
            };
          }
          return type;
        }),
      };
    }

    if (colField === 'rightSide') {
      return {
        ...statePreset,
        types: statePreset.types.map((type) => {
          if (type.secondary === 'ask') {
            return {
              ...type,
              value: newValue,
            };
          }
          return type;
        }),
      };
    }

    if (colField === 'isActive') {
      return {
        ...statePreset,
        isActive: newValue,
      };
    }

    return statePreset;
  }

  function prepNewPreset(statePreset, colField, newValue) {
    const updatedPreset = mergePresetUpdates(statePreset, colField, newValue);
    const newPreset = {
      id: updatedPreset.id,
      name: he.decode(updatedPreset.name),
      group:
        updatedPreset.group != null ? he.decode(updatedPreset.group) : null,
      instrument:
        updatedPreset.instrument != null
          ? he.decode(updatedPreset.instrument)
          : null,
      isActive: updatedPreset.isActive,
      types: updatedPreset.types.map((type) => {
        return {
          id: type.id,
          primary: type.primary,
          secondary: type.secondary,
          value: type.value,
          priceControlPresetId: type.priceControlPresetId,
        };
      }),
    };

    return newPreset;
  }

  return Object.freeze({
    presetsWithTypesPivot,
    prepNewPreset,
  });
}

export default presenter;
