import React, { useState, useRef } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { Alert } from '@material-ui/lab';
import {
  Avatar,
  Button,
  Container,
  Paper,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { LockOutlined as LockIcon } from '@material-ui/icons';

import Loader from '../../components/Loader';
import FormInput from '../FormInput';

import presenter from './presenter';

import configAuth from '../../config/auth';

const ID_CTRL_EMAIL = 'sign-in-form-email-input';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.icon,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ForgottenPasswordForm() {
  const classes = useStyles();
  const presenting = presenter({
    ID_CTRL_EMAIL,
  });
  const initialFormState = useRef(presenting.initialFormState);
  const [ctrlState, setCtrlState] = useState(initialFormState.current);
  const [isPending, setIsPending] = useState(false);
  const [serverErrMsg, setServerErrMsg] = useState(null);
  const [clientNoticeMsg, setClientNoticeMsg] = useState(null);

  const { issuer, pkce, clientId, redirectUri } = configAuth.oidc;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (presenting.hasErrors(ctrlState)) {
      setCtrlState(presenting.validateCtrls(ctrlState));
      setIsPending(false);
    } else {
      setIsPending(true);
      const oktaAuth = new OktaAuth({
        issuer,
        clientId,
        redirectUri,
        pkce,
        tokenManager: {
          autoRenew: true,
          secure: true,
          storage: 'sessionStorage',
          expireEarlySeconds: 120,
          cookies: {
            secure: true,
            sameSite: 'none',
          },
        },
      });
      oktaAuth
        .forgotPassword({
          username: ctrlState[ID_CTRL_EMAIL].value,
          factorType: 'EMAIL',
        })
        .then((transaction) => {
          // TODO: handle other transaction statuses
          // info: https://github.com/okta/okta-auth-js#transactionstatus

          // // eslint-disable-next-line no-console
          // console.log('transaction', transaction);
          if (transaction.status === 'RECOVERY_CHALLENGE') {
            setClientNoticeMsg(
              'Thank you. An email has been sent from Okta with a link to reset your password.'
            );
            setIsPending(false);
            return transaction;
          }
          throw new Error('transaction', JSON.stringify(transaction));
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('oktaAuth.forgotPassword error', err);
          setServerErrMsg(err.message);
          setIsPending(false);
        });
    }
  };

  const handleInputUpdate = (e) => {
    const { id, value } = e.target;
    setCtrlState(
      presenting.manageCtrlState({ state: ctrlState, name: id, value })
    );
  };

  return isPending ? (
    <Loader color="secondary" size="9em" />
  ) : (
    <Container maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h3">
          Forgotten password reset
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <FormInput
            value={ctrlState[ID_CTRL_EMAIL].value}
            error={ctrlState[ID_CTRL_EMAIL].isError}
            disabled={ctrlState[ID_CTRL_EMAIL].isDisabled}
            required={ctrlState[ID_CTRL_EMAIL].isRequired}
            type="email"
            changeHandler={handleInputUpdate}
            blurHandler={handleInputUpdate}
            helperText="Enter username or email"
            labelText="Username *"
            margin="dense"
            fullWidth
            elementId={ID_CTRL_EMAIL}
            autoComplete="email"
            autoFocus
          />
          {serverErrMsg && <Alert severity="error">{serverErrMsg}</Alert>}
          {clientNoticeMsg && (
            <Alert severity="success">{clientNoticeMsg}</Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Reset forgotten password
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default ForgottenPasswordForm;
