function presenter() {
  const vm = (state, severity, status) => {
    const severityLevels = {
      URGENT: 'error',
      WARNING: 'warning',
      LOW: 'info',
    };

    const generateFurtherInfo = (errorMsg) => {
      const errorMsgCopy =
        errorMsg != null
          ? `<h3>Further information</h3><p>Real-time data socket error message: <strong>${errorMsg}</strong></p>`
          : `<h3>No further information</h3>`;

      return `${errorMsgCopy} <h3>Recommendation</h3><p>You should sign-out, carry out a full / hard page refresh </strong>(Ctrl + F5)</strong>, then sign back in.</p><p>If the problem persists, there may be an internet connectivity problem between your PC and the Web API and/or the Web API has a problem.</p>`;
    };

    const generateErrorReport = (stateErrors) =>
      stateErrors.reduce((acc, cur) => {
        if (acc.length === 0) return `${acc}${generateFurtherInfo(cur.state)}`;
        return `${acc}<hr/>${generateFurtherInfo(cur.state)}`;
      }, '');

    if (Array.isArray(state)) {
      return {
        alertTitleCopy: 'Error(s)',
        alertBodyCopy: `<h2>Socket ${
          status.isConnected
            ? 'connected'
            : 'not connected: cannot send and/or receive new data!'
        }<h2> ${generateErrorReport(state)}`,
        severity: severityLevels[severity],
        hasAlertMessages: true,
      };
    }

    return null;
  };

  return Object.freeze({
    vm,
  });
}

export default presenter;
