import presentersCommon from '../../../presenters/common';

function presenter({
  ID_CTRL_PRESET_NAME,
  ID_CTRL_PRESET_TYPE,
  ID_CTRL_GROUP_NAME,
  ID_CTRL_INSTRUMENT,
  ID_CTRL_LEFT_SIDE,
  ID_CTRL_RIGHT_SIDE,
}) {
  const initialFormState = {
    [ID_CTRL_PRESET_NAME]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: true,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Preset name: required',
        },
        {
          rule: 'isAlphaNumDash',
          feedback: 'Group Name: alphanumeric characters with dashes only',
        },
      ],
    },
    [ID_CTRL_PRESET_TYPE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: true,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Preset type: required',
        },
      ],
    },
    [ID_CTRL_GROUP_NAME]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: false,
      value: { id: 0, label: '' },
      inputValue: '',
      validators: [],
    },
    [ID_CTRL_INSTRUMENT]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: false,
      value: { id: 0, label: '' },
      inputValue: '',
      validators: [],
    },
    [ID_CTRL_LEFT_SIDE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: true,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Left side: required',
        },
      ],
    },
    [ID_CTRL_RIGHT_SIDE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: true,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Right side: required',
        },
      ],
    },
  };

  function prepPayload({
    name,
    group,
    instrument,
    isActive,
    presetType,
    leftSide,
    rightSide,
  }) {
    const types = [
      {
        primary: presetType.value.toLowerCase(),
        secondary: 'bid',
        value: +leftSide.value,
      },
      {
        primary: presetType.value.toLowerCase(),
        secondary: 'ask',
        value: +rightSide.value,
      },
    ];

    return {
      name: name.value.toUpperCase(),
      group: group && group.value && group.value.label,
      instrument: instrument && instrument.value && instrument.value.label,
      isActive,
      types,
    };
  }

  // TODO: DRY
  const replaceValueCtrlStateSliceProp = ({
    state,
    identifier,
    propName,
    value,
  }) => {
    const def = { ...state[identifier] };

    return {
      ...state,
      [identifier]: {
        ...def,
        [propName]: value,
      },
    };
  };

  function validateBizRules({ formState, presetNames }) {
    const errors = [];
    // Check for dupe preset names
    if (
      presetNames
        .map((name) => name.toUpperCase())
        .includes(formState[ID_CTRL_PRESET_NAME].value.toUpperCase())
    ) {
      errors.push(ID_CTRL_PRESET_NAME);
    }

    // Check for negative spread/posts
    const presetType = formState[ID_CTRL_PRESET_TYPE].value;

    if (presetType === 'spread' || presetType === 'post') {
      if (formState[ID_CTRL_LEFT_SIDE].value < 0)
        errors.push(ID_CTRL_LEFT_SIDE);
      if (formState[ID_CTRL_RIGHT_SIDE].value < 0)
        errors.push(ID_CTRL_RIGHT_SIDE);
    }

    return errors;
  }

  return Object.freeze({
    initialFormState,
    replaceValueCtrlStateSliceProp,
    prepPayload,
    validateBizRules,
    manageCtrlState: presentersCommon.manageCtrlState,
    hasErrors: presentersCommon.hasErrors,
    validateCtrls: presentersCommon.validateCtrls,
  });
}

export default presenter;
