function presenter() {
  const vm = (state) => {
    const severityLevels = {
      URGENT: 'error',
      WARNING: 'warning',
      LOW: 'info',
    };

    const generateFurtherInfo = ({ config, name, message }) =>
      config != null && config.url && config.method
        ? `<h2>${name}: ${message}</h2><h3>Further information</h3><p>You attempted to <strong>${config.method.toUpperCase()}</strong> <strong>${
            typeof config.data === 'string' && config.data.length > 0
              ? `data ${config.data}`
              : ''
          }</strong> <strong>${
            config.method.toUpperCase() === 'GET' ? 'from' : 'to'
          }</strong> url with path: <strong>${
            config.url
          }</strong>, but the operation <strong>failed</strong> with message: <strong>${message}.</strong></p><h3>Recommendation</h3><p>Before attempting this operation again, you should sign-out, carry out a full / hard page refresh </strong>(Ctrl + F5)</strong>, then sign back in.</p><p>If the problem persists, there may be an internet connectivity problem between your PC and the Web API and/or the Web API has a problem.</p>`
        : `<h3>No further information</h3>`;

    const generateErrorReport = (stateErrors) =>
      stateErrors.reduce((acc, cur) => {
        if (acc.length === 0) return `${acc}${generateFurtherInfo(cur.state)}`;
        return `${acc}<hr/>${generateFurtherInfo(cur.state)}`;
      }, '');

    if (Array.isArray(state) && state.length > 0) {
      return {
        alertTitleCopy: 'Error(s)',
        alertBodyCopy: generateErrorReport(state),
        severity: severityLevels.URGENT,
        hasAlertMessages: true,
      };
    }

    return null;
  };

  return Object.freeze({
    vm,
  });
}

export default presenter;
