import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Paper,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Fullscreen, Close } from '@material-ui/icons';

const useStyles = makeStyles(() => {
  return {
    actionButton: {
      borderRadius: 2,
      backgroundColor: 'transparent',
      color: 'white',
    },
    toolbar: {
      backgroundColor: '#22262e',
      color: 'white',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  };
});

function TableExtension({ children, title, actions }) {
  const [fullScreen, setFullScreen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Paper variant="outlined" square>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="subtitle1">{title}</Typography>
          <IconButton
            className={classes.actionButton}
            size="small"
            onClick={() => setFullScreen(true)}
          >
            <Fullscreen />
          </IconButton>
        </Toolbar>
        <Toolbar variant="dense">{actions}</Toolbar>
        {children}
      </Paper>
      <Dialog fullScreen open={fullScreen}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="subtitle1">{title}</Typography>
          <IconButton
            className={classes.actionButton}
            size="small"
            onClick={() => setFullScreen(false)}
          >
            <Close />
          </IconButton>
        </Toolbar>
        <Toolbar variant="dense">{actions}</Toolbar>
        {children}
      </Dialog>
    </>
  );
}

TableExtension.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

TableExtension.defaultProps = {
  children: null,
};

export default TableExtension;
