import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { Cancel } from '@material-ui/icons';

import presenter from './presenter';

import FormSelectMultiple from '../FormSelectMultiple';
import FormIconButton from '../../../forms/FormIconButton';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '8px',
    },
  },
  cardHeader: {
    padding: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
    flexWrap: 'wrap',
  },
  cardHeaderAction: {
    marginTop: '0px',
  },
  cardContent: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  cardActive: {
    backgroundColor: theme.palette.success.light,
  },
  cardInactive: {
    backgroundColor: theme.palette.error.light,
  },
  destroy: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
}));

const ID_CTRL_PRE_ACTIVE = 'price-controls-form-pre-active-select';
const ID_CTRL_PRE_INACTIVE = 'price-controls-form-pre-inactive-select';
const ID_CTRL_SKEW_ACTIVE = 'price-controls-form-skew-active-select';
const ID_CTRL_SKEW_INACTIVE = 'price-controls-form-skew-inactive-select';
const ID_CTRL_POST_ACTIVE = 'price-controls-form-post-active-select';
const ID_CTRL_POST_INACTIVE = 'price-controls-form-post-inactive-select';

const stateCtrlPairMatrix = {
  [ID_CTRL_PRE_ACTIVE]: ID_CTRL_PRE_INACTIVE,
  [ID_CTRL_SKEW_ACTIVE]: ID_CTRL_SKEW_INACTIVE,
  [ID_CTRL_POST_ACTIVE]: ID_CTRL_POST_INACTIVE,
  [ID_CTRL_PRE_INACTIVE]: ID_CTRL_PRE_ACTIVE,
  [ID_CTRL_SKEW_INACTIVE]: ID_CTRL_SKEW_ACTIVE,
  [ID_CTRL_POST_INACTIVE]: ID_CTRL_POST_ACTIVE,
};

function Controls({ cmdPriceControlPresetsAction, statePriceControlPresets }) {
  const classes = useStyles();
  const present = presenter({
    ID_CTRL_PRE_ACTIVE,
    ID_CTRL_PRE_INACTIVE,
    ID_CTRL_SKEW_ACTIVE,
    ID_CTRL_SKEW_INACTIVE,
    ID_CTRL_POST_ACTIVE,
    ID_CTRL_POST_INACTIVE,
  });
  const presentLoadCtrlState = useCallback(present.loadCtrlState, []);
  const presentPresetsWithTypeNamesFlat = useCallback(
    present.presetsWithTypeNamesFlat,
    []
  );
  const presentGetPresetOptionsByTypeAndIsActive = useCallback(
    present.getPresetOptionsByTypeAndIsActive,
    []
  );
  const initialFormState = useRef(present.initialFormState);
  const [ctrlState, setCtrlState] = useState(initialFormState.current);
  const [ctrlIdIsKeyDown, setCtrlIdIsKeyDown] = useState(null);
  const [ctrlIdInFocus, setCtrlIdInFocus] = useState(null);

  const commitChanges = (id, selectedValues) => {
    // // UI add options
    // setCtrlState((prevState) =>
    //   present.updateValuesCtrlStateSliceProp({
    //     state: prevState,
    //     identifier: stateCtrlPairMatrix[id],
    //     propName: 'options',
    //     values: selectedValues,
    //   })
    // );

    // // UI remove options
    // setCtrlState((prevState) =>
    //   present.replaceValuesCtrlStateSliceProp({
    //     state: prevState,
    //     identifier: id,
    //     propName: 'options',
    //     values: selectedValues,
    //   })
    // );

    const hasActiveIdent = /-active/;
    const { retrieveResult } = statePriceControlPresets;
    const { data } = retrieveResult;

    const selectedPresets = data.filter((preset) =>
      selectedValues.map((selected) => selected.id).includes(preset.id)
    );

    const selectedPresetsWithActiveStatus = selectedPresets.map((preset) => {
      return {
        ...preset,
        isActive: hasActiveIdent.test(stateCtrlPairMatrix[id]),
      };
    });
    selectedPresetsWithActiveStatus.forEach((cmdPayload) => {
      cmdPriceControlPresetsAction(cmdPayload);
    });

    // // Reset UI selected values
    // setCtrlState((prevState) =>
    //   present.replaceValueCtrlStateSliceProp({
    //     state: prevState,
    //     identifier: id,
    //     propName: 'value',
    //     value: [],
    //   })
    // );

    // Reset id for UI which last had key down evt
    setCtrlIdIsKeyDown(null);
  };

  const handleChangeMultiple = (e) => {
    const { id, options } = e.target;
    const selectedValues = present.getSelectedOptionValues(options);

    // Persist selections
    setCtrlState((prevState) =>
      present.replaceValueCtrlStateSliceProp({
        state: prevState,
        identifier: id,
        propName: 'value',
        value: selectedValues.map((selectedValue) => selectedValue.id),
      })
    );

    if (ctrlIdIsKeyDown) return;

    // Remove selections
    // setCtrlState((prevState) =>
    //   present.replaceValueCtrlStateSliceProp({
    //     state: prevState,
    //     identifier: id,
    //     propName: 'value',
    //     value: [],
    //   })
    // );

    commitChanges(id, selectedValues);
  };

  const keyDownHandler = () => {
    setCtrlIdIsKeyDown((prevState) => {
      if (prevState !== ctrlIdInFocus) {
        return ctrlIdInFocus;
      }
      return prevState;
    });
  };

  const keyUpHandler = (e) => {
    const { id, options } = e.target;

    setCtrlIdIsKeyDown(null);

    const selectedValues = present.getSelectedOptionValues(options);

    commitChanges(id, selectedValues);
  };

  const focusHandler = (e) => {
    const { id } = e.target;

    setCtrlIdInFocus((prevState) => {
      if (prevState !== id) {
        return id;
      }
      return prevState;
    });
  };

  const blurHandler = () => {
    // Remove selections
    setCtrlState((prevState) =>
      present.replaceValueCtrlStateSliceProp({
        state: prevState,
        identifier: ctrlIdInFocus,
        propName: 'value',
        value: [],
      })
    );

    setCtrlIdInFocus(null);
  };

  const disableHandler = (e) => {
    const { currentTarget } = e;
    const { id } = currentTarget;
    const hash = {
      spread: ID_CTRL_PRE_ACTIVE,
      skew: ID_CTRL_SKEW_ACTIVE,
      post: ID_CTRL_POST_ACTIVE,
    };
    const multiSelectId = hash[id];
    const selectedValues = ctrlState[multiSelectId].options;

    // // UI remove options
    // setCtrlState((prevState) =>
    //   present.replaceValuesCtrlStateSliceProp({
    //     state: prevState,
    //     identifier: multiSelectId,
    //     propName: 'options',
    //     values: selectedValues,
    //   })
    // );
    // // UI add options
    // setCtrlState((prevState) =>
    //   present.updateValuesCtrlStateSliceProp({
    //     state: prevState,
    //     identifier: stateCtrlPairMatrix[multiSelectId],
    //     propName: 'options',
    //     values: selectedValues,
    //   })
    // );

    commitChanges(id, selectedValues);
  };
  useEffect(() => {
    if (
      statePriceControlPresets &&
      statePriceControlPresets.retrieveResult &&
      statePriceControlPresets.retrieveResult.data &&
      !(
        statePriceControlPresets.isRetrieving ||
        statePriceControlPresets.isCreating ||
        statePriceControlPresets.isCmdInProgress
      )
    ) {
      const presets = presentPresetsWithTypeNamesFlat(
        statePriceControlPresets.retrieveResult.data
      );

      ctrlState.crtlPresetPairs.forEach((pair) => {
        // load active controls
        setCtrlState((prevState) =>
          presentLoadCtrlState({
            state: prevState,
            name: pair.id.active,
            options: presentGetPresetOptionsByTypeAndIsActive({
              presets,
              pairName: pair.name,
              isActive: true,
            }),
          })
        );
        // load inactive controls
        setCtrlState((prevState) =>
          presentLoadCtrlState({
            state: prevState,
            name: pair.id.inactive,
            options: presentGetPresetOptionsByTypeAndIsActive({
              presets,
              pairName: pair.name,
              isActive: false,
            }),
          })
        );
      });
    }
  }, [
    presentLoadCtrlState,
    presentPresetsWithTypeNamesFlat,
    presentGetPresetOptionsByTypeAndIsActive,
    ctrlState.crtlPresetPairs,
    statePriceControlPresets,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item md={12} lg={4}>
        <Card className={classes.cardContainer}>
          <CardHeader
            title="Spread"
            titleTypographyProps={{ variant: 'subtitle1' }}
            className={classes.cardHeader}
            classes={{ action: classes.cardHeaderAction }}
            action={
              <FormIconButton
                id="spread"
                icon={<Cancel />}
                variant="outlined"
                color="secondary"
                classes={{ outlinedSecondary: classes.destroy }}
                size="small"
                disabled={!ctrlState[ID_CTRL_PRE_ACTIVE].options.length}
                onClick={disableHandler}
              >
                Disable
              </FormIconButton>
            }
          />
          <Divider />
          <CardContent
            className={clsx(classes.cardContent, classes.cardActive)}
          >
            <FormSelectMultiple
              selectStyle={{
                height: present.calcualteSelectCtrlHeight(
                  ctrlState[ID_CTRL_PRE_ACTIVE].options
                ),
              }}
              elementId={ID_CTRL_PRE_ACTIVE}
              value={ctrlState[ID_CTRL_PRE_ACTIVE].value}
              options={ctrlState[ID_CTRL_PRE_ACTIVE].options.sort(
                present.sortObjArrByProp('name', 'asc')
              )}
              onChangeHandler={handleChangeMultiple}
              onKeyDownHandler={keyDownHandler}
              onKeyUpHandler={keyUpHandler}
              onFocusHandler={focusHandler}
              onBlurHandler={blurHandler}
              labelText="Spread Active"
            />
          </CardContent>
          <Divider />
          <CardContent
            className={clsx(classes.cardContent, classes.cardInactive)}
          >
            <FormSelectMultiple
              selectStyle={{
                height: present.calcualteSelectCtrlHeight(
                  ctrlState[ID_CTRL_PRE_INACTIVE].options
                ),
              }}
              elementId={ID_CTRL_PRE_INACTIVE}
              value={ctrlState[ID_CTRL_PRE_INACTIVE].value}
              options={ctrlState[ID_CTRL_PRE_INACTIVE].options.sort(
                present.sortObjArrByProp('name', 'asc')
              )}
              onChangeHandler={handleChangeMultiple}
              onKeyDownHandler={keyDownHandler}
              onKeyUpHandler={keyUpHandler}
              onFocusHandler={focusHandler}
              onBlurHandler={blurHandler}
              labelText="Spread Inactive"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} lg={4}>
        <Card className={classes.cardContainer}>
          <CardHeader
            title="Skew"
            titleTypographyProps={{ variant: 'subtitle1' }}
            className={classes.cardHeader}
            classes={{ action: classes.cardHeaderAction }}
            action={
              <FormIconButton
                id="skew"
                variant="outlined"
                color="secondary"
                classes={{ outlinedSecondary: classes.destroy }}
                size="small"
                icon={<Cancel />}
                disabled={!ctrlState[ID_CTRL_SKEW_ACTIVE].options.length}
                onClick={disableHandler}
              >
                Disable
              </FormIconButton>
            }
          />
          <Divider />
          <CardContent
            className={clsx(classes.cardContent, classes.cardActive)}
          >
            <FormSelectMultiple
              selectStyle={{
                height: present.calcualteSelectCtrlHeight(
                  ctrlState[ID_CTRL_SKEW_ACTIVE].options
                ),
              }}
              elementId={ID_CTRL_SKEW_ACTIVE}
              value={ctrlState[ID_CTRL_SKEW_ACTIVE].value}
              options={ctrlState[ID_CTRL_SKEW_ACTIVE].options.sort(
                present.sortObjArrByProp('name', 'asc')
              )}
              onChangeHandler={handleChangeMultiple}
              onKeyDownHandler={keyDownHandler}
              onKeyUpHandler={keyUpHandler}
              onFocusHandler={focusHandler}
              onBlurHandler={blurHandler}
              labelText="Skew Active"
            />
          </CardContent>
          <Divider />
          <CardContent
            className={clsx(classes.cardContent, classes.cardInactive)}
          >
            <FormSelectMultiple
              selectStyle={{
                height: present.calcualteSelectCtrlHeight(
                  ctrlState[ID_CTRL_SKEW_INACTIVE].options
                ),
              }}
              elementId={ID_CTRL_SKEW_INACTIVE}
              value={ctrlState[ID_CTRL_SKEW_INACTIVE].value}
              options={ctrlState[ID_CTRL_SKEW_INACTIVE].options.sort(
                present.sortObjArrByProp('name', 'asc')
              )}
              onChangeHandler={handleChangeMultiple}
              onKeyDownHandler={keyDownHandler}
              onKeyUpHandler={keyUpHandler}
              onFocusHandler={focusHandler}
              onBlurHandler={blurHandler}
              labelText="Skew Inactive"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} lg={4}>
        <Card className={classes.cardContainer}>
          <CardHeader
            title="Post"
            titleTypographyProps={{ variant: 'subtitle1' }}
            className={classes.cardHeader}
            classes={{ action: classes.cardHeaderAction }}
            action={
              <FormIconButton
                id="post"
                variant="outlined"
                color="secondary"
                classes={{ outlinedSecondary: classes.destroy }}
                size="small"
                icon={<Cancel />}
                disabled={!ctrlState[ID_CTRL_POST_ACTIVE].options.length}
                onClick={disableHandler}
              >
                Disable
              </FormIconButton>
            }
          />
          <Divider />
          <CardContent
            className={clsx(classes.cardContent, classes.cardActive)}
          >
            <FormSelectMultiple
              selectStyle={{
                height: present.calcualteSelectCtrlHeight(
                  ctrlState[ID_CTRL_POST_ACTIVE].options
                ),
              }}
              elementId={ID_CTRL_POST_ACTIVE}
              value={ctrlState[ID_CTRL_POST_ACTIVE].value}
              options={ctrlState[ID_CTRL_POST_ACTIVE].options.sort(
                present.sortObjArrByProp('name', 'asc')
              )}
              onChangeHandler={handleChangeMultiple}
              onKeyDownHandler={keyDownHandler}
              onKeyUpHandler={keyUpHandler}
              onFocusHandler={focusHandler}
              onBlurHandler={blurHandler}
              labelText="Post Active"
            />
          </CardContent>
          <Divider />
          <CardContent
            className={clsx(classes.cardContent, classes.cardInactive)}
          >
            <FormSelectMultiple
              selectStyle={{
                height: present.calcualteSelectCtrlHeight(
                  ctrlState[ID_CTRL_POST_INACTIVE].options
                ),
              }}
              elementId={ID_CTRL_POST_INACTIVE}
              value={ctrlState[ID_CTRL_POST_INACTIVE].value}
              options={ctrlState[ID_CTRL_POST_INACTIVE].options.sort(
                present.sortObjArrByProp('name', 'asc')
              )}
              onChangeHandler={handleChangeMultiple}
              onKeyDownHandler={keyDownHandler}
              onKeyUpHandler={keyUpHandler}
              onFocusHandler={focusHandler}
              onBlurHandler={blurHandler}
              labelText="Post Inactive"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

Controls.propTypes = {
  cmdPriceControlPresetsAction: PropTypes.func.isRequired,
  statePriceControlPresets: PropTypes.shape({
    isRetrieving: PropTypes.bool,
    isCreating: PropTypes.bool,
    isCmdInProgress: PropTypes.bool,
    retrieveResult: PropTypes.shape({
      hasRecords: PropTypes.bool,
      count: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          group: PropTypes.string,
          instrument: PropTypes.string,
          isActive: PropTypes.bool.isRequired,
          types: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              primary: PropTypes.string.isRequired,
              secondary: PropTypes.string.isRequired,
              value: PropTypes.number.isRequired,
              priceControlPresetId: PropTypes.number.isRequired,
            })
          ),
        })
      ),
    }),
  }),
};

Controls.defaultProps = {
  statePriceControlPresets: null,
};

export default Controls;
