import { arrayOf, bool, number, shape, string } from 'prop-types';

const propTypeStatePriceControlPresetInstrument = shape({
  isRetrieving: bool,
  isCreating: bool,
  isCmdInProgress: bool,
  retrieveResult: shape({
    hasRecords: bool,
    count: number,
    data: arrayOf(
      shape({
        item: string.isRequired,
      })
    ),
  }),
});

export default propTypeStatePriceControlPresetInstrument;
