import shadowStratParamRepository from '../../repostitories/shadow-strat-param';

import { API_FAIL as API_CLIENT_FAIL } from './api-client-failure';

const RETRIEVE = 'store/shadow-instrument/RETRIEVE';
const RETRIEVE_SUCCESS = 'store/shadow-instrument/RETRIEVE_SUCCESS';

const repo = shadowStratParamRepository();

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        isRetrieving: true,
      };
    case RETRIEVE_SUCCESS:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: null,
        retrieveResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoInstrumentDataSourceInput(action.result.data),
        },
      };

    default:
      return state;
  }
}

export function retrieve(authToken) {
  return {
    types: [RETRIEVE, RETRIEVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.get(`/shadow-params/instruments`, null, authToken),
  };
}
