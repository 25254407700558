import React from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { ErrorBoundary } from '@sentry/react';

import tokenUtils from '../../shared/token';
import SignInForm from '../../forms/SignInForm';
import Fallback from '../../components/Fallback';

function SignIn() {
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState) {
    return <div>Loading...</div>;
  }

  return authState.isAuthenticated ? (
    (() => {
      const storageLocation = oktaAuth.tokenManager.options.storage;
      const pathname = tokenUtils.prepPathname(
        tokenUtils.getStoredState(storageLocation)
      );
      return (
        <Redirect
          to={{
            pathname,
          }}
        />
      );
    })()
  ) : (
    <ErrorBoundary fallback={Fallback}>
      <SignInForm />
    </ErrorBoundary>
  );
}

export default SignIn;
