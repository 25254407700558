import Stream from '../domain/ctx-shadow-strat-param/Stream';

function dtoStream(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const stream = Stream({
    id: gatewayDataObject.id,
    streamDescription: gatewayDataObject.streamDescription,
    streamLogic: gatewayDataObject.streamLogic,
    enabled: gatewayDataObject.enabled,
  });

  return stream;
}

export default dtoStream;
