import axios from 'axios';

import tokenUtils from './token';
import config from '../app-config';

const host = `${config.api.baseUrl[process.env.NODE_ENV || 'production']}`;

export default class clientAPI {
  constructor(oktaAuth) {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    this.oktaAuth = oktaAuth;
  }

  prepMethodCfg({ method = 'GET', url, params, authToken } = {}) {
    const methodCfg = {
      method,
      url,
      headers: authToken
        ? { ...this.headers, Authorization: `Bearer ${authToken}` }
        : this.headers,
      withCredentials: true,
    };
    if (params && params.data) {
      methodCfg.data = JSON.stringify(params.data);
    }
    return methodCfg;
  }

  get(url, params) {
    const fullUrl = `${host}${url}`;
    return axios(
      this.prepMethodCfg({
        method: 'GET',
        url: fullUrl,
        params,
        authToken: tokenUtils.getAccessToken(this.oktaAuth),
      })
    );
  }

  post(url, params) {
    const fullUrl = `${host}${url}`;
    return axios(
      this.prepMethodCfg({
        method: 'POST',
        url: fullUrl,
        params,
        authToken: tokenUtils.getAccessToken(this.oktaAuth),
      })
    );
  }

  put(url, params) {
    const fullUrl = `${host}${url}`;
    return axios(
      this.prepMethodCfg({
        method: 'PUT',
        url: fullUrl,
        params,
        authToken: tokenUtils.getAccessToken(this.oktaAuth),
      })
    );
  }

  delete(url, params) {
    const fullUrl = `${host}${url}`;
    return axios(
      this.prepMethodCfg({
        method: 'DELETE',
        url: fullUrl,
        params,
        authToken: tokenUtils.getAccessToken(this.oktaAuth),
      })
    );
  }
}
