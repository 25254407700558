import ShadowParameterUpdate from '../domain/ctx-shadow-strat-param/ShadowParameterUpdate';

function dtoShadowParameterUpdate(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const shadowParameterUpdate = ShadowParameterUpdate({
    groupName: gatewayDataObject.groupName,
    pkCounterparty: gatewayDataObject.pkCounterparty,
    pkInstrumentId: gatewayDataObject.pkInstrumentId,
    counterparty: gatewayDataObject.counterparty,
    instrumentId: gatewayDataObject.instrumentId,
    strategy: gatewayDataObject.strategy,
    enabled: gatewayDataObject.enabled,
    orderType: gatewayDataObject.orderType,
    timeInForce: gatewayDataObject.timeInForce,
    hedgeRatio: gatewayDataObject.hedgeRatio,
    copyMinQty: gatewayDataObject.copyMinQty,
    copyMaxQty: gatewayDataObject.copyMaxQty,
    retryIntervalMillis: gatewayDataObject.retryIntervalMillis,
  });

  return shadowParameterUpdate;
}

export default dtoShadowParameterUpdate;
