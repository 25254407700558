const UPDATE_SELECTED_GROUP =
  'store/shadow-strategy-param-config/UPDATE_SELECTED_GROUP';

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_SELECTED_GROUP:
      return {
        ...state,
        groupSelected: action.result,
      };
    default:
      return state;
  }
}

export function selectionChange(key) {
  return { type: UPDATE_SELECTED_GROUP, result: key };
}
