import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    borderWidth: '0px',
    padding: 15,
    textAlign: 'center',
    display: 'inline-block',
    float: 'left',
    position: 'fixed',
    backgroundColor: '#f8f8f8',
    outlineColor: '#dde2eb',
    outlineWidth: '1px',
    outlineOffset: '0px',
    outlineStyle: 'solid',
    boxShadow: '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
  },
  radioFormCtrlLegend: {
    textAlign: 'left',
    fontSize: '11px',
    marginBottom: '12px',
  },
}));

const RadioBtns = forwardRef(
  ({ value, options, required, labelText, helperText, api }, ref) => {
    const classes = useStyles();
    const [ctrlVal, setCtrlVal] = useState(value);
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);

    const handleRadioGroupChange = (e) => {
      const { value: rbVal } = e.target;
      setCtrlVal(rbVal);
    };

    useEffect(() => {
      focus();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return ctrlVal;
        },

        isPopup() {
          return true;
        },
      };
    });

    const focus = () => {
      window.setTimeout(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const container = ReactDOM.findDOMNode(refContainer.current);
        if (container) {
          container.focus();
        }
      });
    };

    useEffect(() => {
      if (!editing) {
        api.stopEditing();
      }
    }, [api, editing]);

    return (
      <div
        ref={refContainer}
        className={classes.root}
        /* eslint-disable jsx-a11y/tabindex-no-positive */
        /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
        tabIndex={1} // important - without this the key presses wont be caught
        /* eslint-enable jsx-a11y/no-noninteractive-tabindex */
        /* eslint-enable jsx-a11y/tabindex-no-positive */
      >
        <FormControl component="fieldset" required={required}>
          {labelText && (
            <FormLabel
              component="legend"
              className={classes.radioFormCtrlLegend}
            >
              {labelText}
            </FormLabel>
          )}

          <RadioGroup
            aria-label={labelText}
            name="radio-group"
            value={ctrlVal}
            onChange={(e) => {
              handleRadioGroupChange(e);
              setEditing(false);
            }}
            row
          >
            {options.map((option) => (
              <FormControlLabel
                value={option.value}
                key={option.value}
                control={<Radio size={option.radioBtnSize} />}
                label={option.label}
                labelPlacement={option.labelPlacement}
              />
            ))}
          </RadioGroup>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </div>
    );
  }
);

RadioBtns.propTypes = {
  value: PropTypes.string.isRequired,
  api: PropTypes.shape({ stopEditing: PropTypes.func }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      radioBtnSize: PropTypes.oneOf(['small', 'medium']),
      labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
    })
  ).isRequired,
  required: PropTypes.bool,
  labelText: PropTypes.string,
  helperText: PropTypes.string,
};

RadioBtns.defaultProps = {
  required: false,
  labelText: null,
  helperText: null,
};

export default RadioBtns;
