import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@sentry/react';

import ShadowGroups from '../../components/ShadowGroups';
import Loading from '../../components/Loading';
import Fallback from '../../components/Fallback';

import propDefaultApiGet from '../../prop-defaults/api-get';
import propTypeShadowGroup from '../../prop-types/shadow-group';

function ShadowStrategyGroups({
  stateShadowGroup,
  retrieveShadowGroupsAction,
}) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    function retrieveFromApi() {
      retrieveShadowGroupsAction().then(() => setIsLoaded(true));
    }
    retrieveFromApi();
  }, [retrieveShadowGroupsAction]);

  return isLoaded ? (
    <ErrorBoundary fallback={Fallback}>
      <ShadowGroups stateShadowGroup={stateShadowGroup} />
    </ErrorBoundary>
  ) : (
    <Loading />
  );
}

ShadowStrategyGroups.propTypes = {
  stateShadowGroup: propTypeShadowGroup,
  retrieveShadowGroupsAction: PropTypes.func.isRequired,
};

ShadowStrategyGroups.defaultProps = {
  stateShadowGroup: propDefaultApiGet,
};

export default ShadowStrategyGroups;
