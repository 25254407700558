import React, { useState, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  makeStyles,
  Typography,
} from '@material-ui/core';
import LogoMark from '../../assets/ewlogo-blue.svg';

import Loader from '../../components/Loader';
import FormInput from '../FormInput';

import presenter from './presenter';
import token from '../../shared/token';

import configAuth from '../../config/auth';

const ID_CTRL_EMAIL = 'sign-in-form-email-input';
const ID_CTRL_PASSWORD = 'sign-in-form-password-input';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.icon,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: theme.palette.primary.main,
  },
  logoWrapper: {
    height: '100px',
    width: '100px',
  },
}));

function SignInForm() {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();
  const presenting = presenter({
    ID_CTRL_EMAIL,
    ID_CTRL_PASSWORD,
  });
  const initialFormState = useRef(presenting.initialFormState);
  const [ctrlState, setCtrlState] = useState(initialFormState.current);
  const [isPending, setIsPending] = useState(false);
  const [serverErrMsg, setServerErrMsg] = useState(null);

  // TODO receive as props instead?
  const { scopes } = configAuth.oidc;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (presenting.hasErrors(ctrlState)) {
      setCtrlState(presenting.validateCtrls(ctrlState));
      setIsPending(false);
    } else {
      setIsPending(true);
      oktaAuth
        .signInWithCredentials({
          username: ctrlState[ID_CTRL_EMAIL].value,
          password: ctrlState[ID_CTRL_PASSWORD].value,
        })
        .then((transaction) => {
          // TODO: handle other transaction statuses
          // info: https://github.com/okta/okta-auth-js#transactionstatus

          if (transaction.status === 'SUCCESS') {
            const { sessionToken } = transaction;
            return sessionToken;
          }
          throw new Error('transaction: ', transaction);
        })
        .then((sessionToken) => {
          return token.swapSessionForTokens(oktaAuth, {
            sessionToken,
            scopes,
          });
        })
        .then((tokens) => {
          token.storeTokenOrTokens(oktaAuth, tokens);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('oktaAuth.signIn error', err);
          setServerErrMsg(err.message);
          setIsPending(false);
        });
    }
  };

  const handleInputUpdate = (e) => {
    const { id, value } = e.target;
    setCtrlState(
      presenting.manageCtrlState({ state: ctrlState, name: id, value })
    );
  };

  return isPending ? (
    <Loader color="secondary" size="9em" />
  ) : (
    <Container maxWidth="xs">
      <Paper className={classes.paper}>
        <Box className={classes.logoWrapper}>
          <img src={LogoMark} alt="Edgewater FX Admin" />
        </Box>
        <Typography component="h1" variant="h3">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <FormInput
            value={ctrlState[ID_CTRL_EMAIL].value}
            error={ctrlState[ID_CTRL_EMAIL].isError}
            disabled={ctrlState[ID_CTRL_EMAIL].isDisabled}
            required={ctrlState[ID_CTRL_EMAIL].isRequired}
            type="email"
            changeHandler={handleInputUpdate}
            blurHandler={handleInputUpdate}
            helperText="Enter username or email"
            labelText="Username *"
            margin="dense"
            fullWidth
            elementId={ID_CTRL_EMAIL}
            autoComplete="username"
            autoFocus
          />
          <FormInput
            value={ctrlState[ID_CTRL_PASSWORD].value}
            error={ctrlState[ID_CTRL_PASSWORD].isError}
            disabled={ctrlState[ID_CTRL_PASSWORD].isDisabled}
            required={ctrlState[ID_CTRL_PASSWORD].isRequired}
            type="password"
            changeHandler={handleInputUpdate}
            blurHandler={handleInputUpdate}
            helperText="Enter password"
            labelText="Password *"
            margin="dense"
            fullWidth
            elementId={ID_CTRL_PASSWORD}
            autoComplete="current-password"
          />
          {serverErrMsg && <Alert severity="error">{serverErrMsg}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container align="left">
            <Grid item xs>
              <Link
                to="/forgotten-password"
                variant="body2"
                color="secondary"
                className={classes.link}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default SignInForm;
