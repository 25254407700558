import logUtil from '../../shared/logger';

export default function socketMiddleware(socket) {
  const logger = logUtil();
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      /*
       * Socket middleware usage.
       * promise: (socket) => socket.emit('MESSAGE', 'hello world!')
       * type: 'socket' or 'socket-evt'
       * types: [REQUEST, SUCCESS, FAILURE]
       *
       * note: for 'socket-evt' only single item in types array required
       */
      const { promise, type, types, ...rest } = action;

      if ((type !== 'socket' && type !== 'socket-evt') || !promise) {
        // Move on! Not a socket request or a badly formed one.
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      if (type !== 'socket-evt') next({ ...rest, type: REQUEST });

      if (type === 'socket-evt') return promise(socket);
      return promise(socket)
        .then((result) => {
          return next({ ...rest, result, type: SUCCESS });
        })
        .catch((error) => {
          logger.logError(error);
          return next({ ...rest, error, type: FAILURE });
        });
    };
}
