import { connect } from 'react-redux';

import ClientGroupManagement from './ClientGroupManagement';

import {
  retrieve as retrieveClientGroupAction,
  selectionChange as clientGroupSelectionChangeAction,
} from '../../store/modules/client-group';

const mapStateToProps = function mapStateToProps(state) {
  const { clientGroup: stateClientGroup } = state;
  return {
    stateClientGroup,
  };
};

export default connect(mapStateToProps, {
  clientGroupSelectionChangeAction,
  retrieveClientGroupAction,
})(ClientGroupManagement);
