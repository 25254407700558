import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    '& > h2': {
      fontSize: '1.5em',
    },
  },
}));

const localThemeOverride = (theme) =>
  createTheme({
    palette: {
      primary: theme.palette.success,
      secondary: theme.palette.error,
    },
  });

function ConfirmDialog({
  isOpen,
  onCloseHandler,
  onConfirmHandler,
  selectedRowsCount,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const localTheme = localThemeOverride(theme);
  return (
    <Dialog
      open={isOpen}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
        Cancel selected strategies?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span>
            {`Please 'confirm' you wish to cancel ${selectedRowsCount} selected strategies.`}
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={localTheme}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ClearIcon />}
            onClick={onCloseHandler}
          >
            Abort
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={() => {
              onCloseHandler();
              onConfirmHandler();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  onConfirmHandler: PropTypes.func.isRequired,
  selectedRowsCount: PropTypes.number.isRequired,
};

export default ConfirmDialog;
