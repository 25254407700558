import entityProOrganization from '../defs/ctx-shadow-strat-param/entity-pro-organization';
import SealedModel from '../helpers/SealedModel';

const ProOrganization = SealedModel({
  ...entityProOrganization,
}).defaultTo({
  logMarketData: 0,
  enabled: 1,
});

export default ProOrganization;
