import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@material-ui/core';

function Copyright({ classNameContainer }) {
  return (
    <Box className={classNameContainer}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.edgewatermarkets.com/">
          Edgewater
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

Copyright.propTypes = {
  classNameContainer: PropTypes.string,
};

Copyright.defaultProps = {
  classNameContainer: null,
};

export default Copyright;
