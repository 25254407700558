import ShadowGroup from '../../domain/ctx-shadow-strat-param/ShadowGroup';

function dtoShadowGroup(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const shadowGroup = ShadowGroup({
    name: gatewayDataObject.name,
    reportingOrg: gatewayDataObject.reportingOrg,
    automatedStreamId: gatewayDataObject.automatedStreamId,
    manualStreamId: gatewayDataObject.manualStreamId,
    executionDelayMillis: gatewayDataObject.executionDelayMillis,
    enabled: gatewayDataObject.enabled,
    status: gatewayDataObject.status,
  });

  return shadowGroup;
}

export default dtoShadowGroup;
