export const API_FAIL = 'store/api-client-failure/API_FAIL';
const API_FAIL_RESET = 'store/api-client-failure/API_FAIL_RESET';

export const CMD_FAIL = 'store/api-client-failure/CMD_FAIL';
const CMD_FAIL_RESET = 'store/api-client-failure/CMD_FAIL_RESET';

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case API_FAIL:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: action.error,
        retrieveResult: null,
      };
    case API_FAIL_RESET:
      return {
        ...state,
        retrieveError: null,
      };
    case CMD_FAIL:
      return {
        ...state,
        isCmdInProgress: false,
        cmdError: action.error,
      };
    case CMD_FAIL_RESET:
      return {
        ...state,
        cmdError: null,
      };
    default:
      return state;
  }
}

export function retrieveError(e) {
  return (dispatch) => {
    return dispatch({
      type: API_FAIL,
      error: e,
    });
  };
}

export function retrieveResetError() {
  return (dispatch) => {
    return dispatch({
      type: API_FAIL_RESET,
      result: null,
    });
  };
}

export function cmdResetError() {
  return (dispatch) => {
    return dispatch({
      type: CMD_FAIL_RESET,
      result: null,
    });
  };
}
