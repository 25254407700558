import { arrayOf, bool, element, number, shape, string } from 'prop-types';

const propTypeShadowGroupStrategyParam = shape({
  isRetrieving: bool,
  retrieveError: element,
  retrieveResult: shape({
    hasRecords: bool.isRequired,
    count: number.isRequired,
    data: arrayOf(
      shape({
        groupName: string.isRequired,
        counterparty: string.isRequired,
        instrumentId: number.isRequired,
        strategy: string,
        enabled: number.isRequired,
        orderType: string.isRequired,
        timeInForce: string.isRequired,
        hedgeRatio: number.isRequired,
        copyMinQty: number.isRequired,
        copyMaxQty: number.isRequired,
        retryIntervalMillis: number.isRequired,
      }).isRequired
    ).isRequired,
  }),
});

export default propTypeShadowGroupStrategyParam;
