import presentersCommon from '../../../presenters/common';

function presenter({ ID_CTRL_CLIENT_CHOICE_FILTER_INPUT }) {
  const initialFormState = {
    [ID_CTRL_CLIENT_CHOICE_FILTER_INPUT]: {
      isTouched: false,
      isError: false,
      isDisabled: true,
      isRequired: false,
      value: '',
      validators: [],
    },
  };
  function prepSelectedOptions(collection, findItem) {
    const itemFound = collection.find(
      (clientGroup) => clientGroup.name === findItem
    );

    if (itemFound && Array.isArray(itemFound.clients)) {
      return itemFound.clients.map((client) => client.name);
    }
    return [];
  }

  const not = (function notMemo() {
    const memo = {};
    function f(x, n) {
      let value;

      memo[x] = memo[x] || {};

      if (x in memo && n in memo[x]) {
        value = memo[x][n];
      } else {
        value = x.filter((val) => n.indexOf(val) === -1);
        memo[x][n] = value;
      }

      return value;
    }

    return f;
  })();
  const intersection = (function intersectionMemo() {
    const memo = {};
    function f(x, n) {
      let value;

      memo[x] = memo[x] || {};

      if (x in memo && n in memo[x]) {
        value = memo[x][n];
      } else {
        value = x.filter((val) => n.indexOf(val) !== -1);
        memo[x][n] = value;
      }

      return value;
    }

    return f;
  })();

  const union = (function unionMemo() {
    const memo = {};
    function f(x, n) {
      let value;

      memo[x] = memo[x] || {};

      if (x in memo && n in memo[x]) {
        value = memo[x][n];
      } else {
        value = [...x, ...not(n, x)];
        memo[x][n] = value;
      }

      return value;
    }

    return f;
  })();

  return Object.freeze({
    initialFormState,
    prepSelectedOptions,
    not,
    intersection,
    union,
    updateCtrlState: presentersCommon.updateCtrlState,
  });
}

export default presenter;
