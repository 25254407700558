export default [
  {
    value: 'GTC',
    label: 'GTC',
  },
  {
    value: 'IOC',
    label: 'IOC',
  },
  {
    value: 'FOK',
    label: 'FOK',
  },
];
