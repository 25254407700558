import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, Paper, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LockOpenOutlined as LockOpenOutlinedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

import FormIconButton from '../../../../forms/FormIconButton';

import logUtil from '../../../../shared/logger';
import presenter from './presenter';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '425px',
    padding: theme.spacing(2),
  },
  gridContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  signOutContainer: {
    textAlign: 'right',
  },
  avatarLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

function User({
  id,
  isOpen,
  anchorEl,
  userPreferredUsername,
  userFullName,
  userInitials,
  userOrg,
  userColor,
  closeHandler,
}) {
  const classes = useStyles();
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();
  const [isSignOutDisabled, setIsSignOutDisabled] = useState(false);

  const logger = logUtil();
  const present = presenter({ authState, oktaAuth, history });

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={closeHandler}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.root}>
        {present.isDisplaySignOut ? (
          <Grid
            container
            spacing={0}
            alignItems="center"
            className={classes.gridContainer}
          >
            <Grid item xs={7}>
              <Paper className={classes.paper} elevation={0}>
                <Typography align="left" noWrap component="h1" variant="h5">
                  {userOrg}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Paper
                className={clsx(classes.paper, classes.signOutContainer)}
                elevation={0}
              >
                <FormIconButton
                  id="topbar-user-sign-out-button"
                  ariaLabel="Sign out"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  icon={<LockOpenOutlinedIcon />}
                  onClick={async () => {
                    setIsSignOutDisabled(true);
                    logger.logDebug('user signing out');
                    try {
                      await present.signOutHandler();
                    } catch (e) {
                      logger.logError(e);
                      setIsSignOutDisabled(false);
                    }
                  }}
                  disabled={isSignOutDisabled}
                >
                  Sign out
                </FormIconButton>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper} elevation={0}>
                <Avatar
                  className={classes.avatarLarge}
                  style={{
                    backgroundColor: userColor,
                  }}
                >
                  {userInitials}
                </Avatar>
              </Paper>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Paper className={classes.paper} elevation={0}>
                    <Typography
                      align="left"
                      noWrap
                      component="h2"
                      variant="subtitle1"
                    >
                      {userFullName}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper} elevation={0}>
                    <Typography
                      align="left"
                      noWrap
                      component="h3"
                      variant="subtitle2"
                    >
                      {userPreferredUsername}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div>TODO: Log in</div>
        )}
      </div>
    </Popover>
  );
}

User.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  userFullName: PropTypes.string,
  userInitials: PropTypes.string,
  userOrg: PropTypes.string,
  userColor: PropTypes.string,
  userPreferredUsername: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
};

User.defaultProps = {
  id: null,
  anchorEl: null,
  userFullName: null,
  userInitials: null,
  userOrg: null,
  userColor: null,
  userPreferredUsername: null,
};

export default User;
