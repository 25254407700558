import dtoPriceControlPreset from '../infrastructure/dto-price-control-preset';

function priceControlsRepository() {
  function dtoPriceControlPresetDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoPriceControlPreset(gatewayDataObject);
    });
  }

  return Object.freeze({
    dtoPriceControlPresetDataSourceInput,
  });
}

export default priceControlsRepository;
