import Instrument from '../domain/ctx-shadow-strat-param/Instrument';

function dtoInstrument(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const instrument = Instrument({
    id: gatewayDataObject.id,
    instrument: gatewayDataObject.instrument,
    symbol: gatewayDataObject.symbol,
    tenor: gatewayDataObject.tenor,
    productType: gatewayDataObject.productType,
    dps: gatewayDataObject.dps,
    enabled: gatewayDataObject.enabled,
  });

  return instrument;
}

export default dtoInstrument;
