import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SidebarNav from './SidebarNav';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.brandSecondary,
      borderColor: theme.palette.primary.dark,
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: theme.props.layout.drawer.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 56,
      [theme.breakpoints.up('sm')]: {
        width: 56,
      },
    },
    drawerPaperHide: {
      width: 0,
    },
    divider: {
      margin: theme.spacing(0),
      backgroundColor: theme.palette.text.primary,
    },
    nav: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  };
});

function Sidebar({ isDrawerOpen, variant, toggleIsDrawerOpenHandler }) {
  const classes = useStyles();
  const [isDrawerHidden, setIsDrawerHidden] = useState(false);

  const isCollapsed = !isDrawerOpen;

  const handleHideDrawer = () => setIsDrawerHidden(true);
  useEffect(() => {
    if (isDrawerOpen) setIsDrawerHidden(false);
  }, [isDrawerOpen]);

  return (
    <Drawer
      classes={{
        paper: clsx(
          clsx(
            classes.root,
            classes.drawerPaper,
            isDrawerHidden && classes.drawerPaperHide,
            !isDrawerOpen && classes.drawerPaperClose
          )
        ),
      }}
      open={isDrawerOpen}
      variant={variant}
    >
      <aside>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={toggleIsDrawerOpenHandler} color="inherit">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          // onClose={toggleIsDrawerOpenHandler}
          isCollapsed={isCollapsed}
        />
        <Divider className={classes.divider} />
        <IconButton
          color="inherit"
          style={{ display: isCollapsed ? 'block' : 'none' }}
          onClick={handleHideDrawer}
        >
          <ChevronLeftIcon />
        </IconButton>
      </aside>
    </Drawer>
  );
}

Sidebar.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  toggleIsDrawerOpenHandler: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']).isRequired,
};
export default Sidebar;
