import { connect } from 'react-redux';

import RealtimeStatus from './RealtimeStatus';

const mapStateToProps = function mapStateToProps(state) {
  const { socket: stateSocket } = state;

  const {
    isConnecting,
    isConnected,
    connectError,
    isDisconnecting,
    isDisconnected,
    disconnectError,
  } = stateSocket;

  return {
    isConnecting,
    isConnected,
    connectError,
    isDisconnecting,
    isDisconnected,
    disconnectError,
  };
};
export default connect(mapStateToProps)(RealtimeStatus);
