import { connect } from 'react-redux';

import Main from './Main';

import {
  connect as connectSocketAction,
  disconnect as disconnectSocketAction,
  evtConnectError as socketEvtConnectErrorAction,
  evtConnected as socketEvtConnectedAction,
  evtDisconnected as socketEvtDisconnectedAction,
} from '../store/modules/socket';
import { receive as receiveOrderHistoryAction } from '../store/modules/order-history';
import { receive as receiveOrderUpdateAction } from '../store/modules/order-update';
import { receive as receiveShadowGroupStatusAction } from '../store/modules/shadow-group';
import {
  receiveCreateOrUpdate as receiveCreateOrUpdatePriceControlPresetsAction,
  receiveDestroy as receiveDestroyPriceControlPresetsAction,
} from '../store/modules/price-control-presets';
import {
  receiveCreateOrUpdate as receiveCreateOrUpdateClientGroupsAction,
  receiveDestroy as receiveDestroyClientGroupsAction,
} from '../store/modules/client-group';
import { receive as receiveSystemAlertMessageAction } from '../store/modules/system-alert';

export default connect(null, {
  connectSocketAction,
  disconnectSocketAction,
  receiveOrderHistoryAction,
  receiveOrderUpdateAction,
  socketEvtConnectErrorAction,
  socketEvtConnectedAction,
  socketEvtDisconnectedAction,
  receiveShadowGroupStatusAction,
  receiveCreateOrUpdatePriceControlPresetsAction,
  receiveDestroyPriceControlPresetsAction,
  receiveCreateOrUpdateClientGroupsAction,
  receiveDestroyClientGroupsAction,
  receiveSystemAlertMessageAction,
})(Main);
