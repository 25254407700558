import React from 'react';
import ReactDOM from 'react-dom';
import { init as sentryInit, BrowserTracing } from '@sentry/react';

import * as serviceWorker from './serviceWorker';
import App from './App';

import { appCfg } from './config/app';
import { sentryCfg } from './config/sentry';

import styles from './index.module.css';

sentryInit({
  dsn: sentryCfg.sentryDsn,
  environment: appCfg.appEnv,
  release: appCfg.appVersion,
  autoSessionTracking: false,
  integrations: [new BrowserTracing()],
  beforeSend(event) {
    if (appCfg.appNodeEnv !== 'production' && sentryCfg.isSentryInProdOnly) {
      return null;
    }
    return event;
  },
  // Sampling Error Events
  // Info: https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#sampling-error-events
  sampleRate: 1.0,
  // Performance Monitoring:
  // Info: https://docs.sentry.io/platforms/javascript/guides/react/#monitor-performance
  tracesSampleRate: 1.0,
});

const rootElement =
  document.getElementById('root') || document.createElement('div');
rootElement.className = styles.root;

// TODO: Fix <React.StrictMode>
ReactDOM.render(<App />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
