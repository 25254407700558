import entityStream from '../defs/ctx-shadow-strat-param/entity-stream';
import SealedModel from '../helpers/SealedModel';

const Stream = SealedModel({
  ...entityStream,
}).defaultTo({
  streamLogic: 0,
  enabled: 1,
});

export default Stream;
