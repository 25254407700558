import { connect } from 'react-redux';

import {
  retrieve as retrieveShadowGroupStrategyParam,
  update as updateShadowGroupStrategyParamAction,
  remove as removeShadowGroupStrategyParamAction,
  cmdStrategy as cmdStrategyAction,
} from '../../../store/modules/shadow-group-strategy-param';
import { cmdGroup as cmdGroupAction } from '../../../store/modules/shadow-group';
import { retrieve as retrieveInstruments } from '../../../store/modules/shadow-instrument';

import GridComponent from './GridComponent';

const mapStateToProps = function mapStateToProps(state) {
  const {
    shadowGroupStrategyParam: stateShadowGroupStrategyParam,
    shadowStrategyParamConfig: stateShadowStrategyParamConfig,
    shadowInstrument: stateShadowInstruments,
  } = state;
  const { groupSelected: stateGroupSelected } =
    stateShadowStrategyParamConfig || {};
  return {
    stateShadowGroupStrategyParam,
    stateGroupSelected,
    stateShadowInstruments,
  };
};

export default connect(mapStateToProps, {
  retrieveShadowGroupStrategyParam,
  updateShadowGroupStrategyParamAction,
  removeShadowGroupStrategyParamAction,
  retrieveInstruments,
  cmdGroupAction,
  cmdStrategyAction,
})(GridComponent);
