import { connect } from 'react-redux';

import Controls from './Controls';

import { cmd as cmdPriceControlPresetsAction } from '../../../store/modules/price-control-presets';

const mapStateToProps = function mapStateToProps(state) {
  const { priceControlPresets: statePriceControlPresets } = state;
  return {
    statePriceControlPresets,
  };
};

export default connect(mapStateToProps, {
  cmdPriceControlPresetsAction,
})(Controls);
