function shadowStrategyGroupFormPresenter({
  ID_CTRL_GROUP_NAME,
  ID_CTRL_PRO_ORGANISATION,
  ID_CTRL_AUTOMATED_STREAM,
  ID_CTRL_MANUAL_STREAM,
  ID_CTRL_EXECUTION_DELAY,
}) {
  const MAX_EXECUTION_DELAY = 3600000; // = 1 x hour

  const initialFormState = {
    [ID_CTRL_GROUP_NAME]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Group Name: required',
        },
        {
          rule: 'minStringLength:1',
          feedback: 'Group Name: minimum length 1 character',
        },
        {
          rule: 'isAlphaNumDash',
          feedback: 'Group Name: alphanumeric characters with dashes only',
        },
      ],
    },
    [ID_CTRL_PRO_ORGANISATION]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      options: [{ label: '', value: '' }],
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Reporting Org.: required',
        },
      ],
    },
    [ID_CTRL_AUTOMATED_STREAM]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Stream: required',
        },
      ],
    },
    [ID_CTRL_MANUAL_STREAM]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Stream: required',
        },
      ],
    },
    [ID_CTRL_EXECUTION_DELAY]: {
      isTouched: true,
      isError: false,
      isDisabled: false,
      value: 0,
      validator: ({ floatValue }) => floatValue <= MAX_EXECUTION_DELAY,
      validators: [
        {
          rule: 'required',
          feedback: 'Execution Delay: required',
        },
      ],
    },
  };

  function hasRecordsInState(state) {
    if (
      !state.isRetrieving &&
      state.retrieveResult &&
      state.retrieveResult.data
    ) {
      return true;
    }
    return false;
  }

  function prepProOrgUnallocatedSelectOptions(
    shadowProOrganization,
    shadowGroup
  ) {
    if (
      hasRecordsInState(shadowProOrganization) &&
      hasRecordsInState(shadowGroup)
    ) {
      const allocatedProOrganizations = new Set(
        shadowGroup.retrieveResult.data.map((group) => group.reportingOrg)
      );
      return shadowProOrganization.retrieveResult.data.reduce((acc, cur) => {
        if (!allocatedProOrganizations.has(cur.organization)) {
          return [
            ...acc,
            {
              label: cur.organization,
              value: cur.organization,
            },
          ];
        }
        return acc;
      }, []);
    }
    return null;
  }

  function prepProOrgSelectOptions(shadowProOrganization) {
    if (hasRecordsInState(shadowProOrganization)) {
      return shadowProOrganization.retrieveResult.data.reduce((acc, cur) => {
        return [
          ...acc,
          {
            label: cur.organization,
            value: cur.organization,
          },
        ];
      }, []);
    }
    return null;
  }

  function prepStreamSelectOptions(shadowStream) {
    if (hasRecordsInState(shadowStream)) {
      return shadowStream.retrieveResult.data.map((stream) => {
        return {
          label: stream.streamDescription,
          value: stream.id,
        };
      });
    }
    return [
      {
        label: '',
        value: '',
      },
    ];
  }

  function isSubmitDisabled(
    ctrlState,
    isUpdateMode,
    initialSelectedGroup,
    isChecked
  ) {
    return (
      ctrlState[ID_CTRL_GROUP_NAME].isError ||
      ctrlState[ID_CTRL_PRO_ORGANISATION].isError ||
      ctrlState[ID_CTRL_AUTOMATED_STREAM].isError ||
      ctrlState[ID_CTRL_EXECUTION_DELAY].isError ||
      ctrlState[ID_CTRL_MANUAL_STREAM].isError ||
      (isUpdateMode &&
        initialSelectedGroup &&
        ctrlState[ID_CTRL_AUTOMATED_STREAM].value ===
          initialSelectedGroup.automatedStreamId &&
        ctrlState[ID_CTRL_EXECUTION_DELAY].value ===
          initialSelectedGroup.executionDelayMillis &&
        ctrlState[ID_CTRL_MANUAL_STREAM].value ===
          initialSelectedGroup.manualStreamId &&
        (isChecked ? 1 : 0) === initialSelectedGroup.enabled)
    );
  }

  return Object.freeze({
    initialFormState,
    prepProOrgUnallocatedSelectOptions,
    prepProOrgSelectOptions,
    prepStreamSelectOptions,
    isSubmitDisabled,
  });
}

export default shadowStrategyGroupFormPresenter;
