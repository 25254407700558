import React from 'react';
import PropTypes from 'prop-types';
import { AllModules, LicenseManager } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import clsx from 'clsx';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckOutlined, CancelOutlined } from '@material-ui/icons';

import generateConfig from './grid.config';
import appConfig from '../../app-config';
import TableExtension from '../TableExtension';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../hooks/trace-update-hook';

import propTypeShadowGroup from '../../prop-types/shadow-group';

LicenseManager.setLicenseKey(appConfig.agGrid.licence);

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', margin: 0, padding: theme.spacing(1) },
  dataGridContainer: {
    height: '50vh',
    width: '100%',
  },
  destroy: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
}));

function ShadowGroups({ stateShadowGroup, cmdGroupAction }) {
  const classes = useStyles();
  const { retrieveResult } = stateShadowGroup;
  // TODO: Use presenter to filter by enabled
  const data =
    retrieveResult != null
      ? (retrieveResult.data || []).filter((li) => li.enabled === 1)
      : [];

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   stateShadowGroup,
  // });

  const config = generateConfig();

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} md={12} lg={8}>
        <TableExtension
          title="Strategy Management"
          actions={
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  onClick={() => cmdGroupAction({ action: 'start' })}
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<CheckOutlined />}
                >
                  Start all
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => cmdGroupAction({ action: 'stop' })}
                  size="small"
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  classes={{ outlinedSecondary: classes.destroy }}
                  startIcon={<CancelOutlined />}
                >
                  Stop all
                </Button>
              </Grid>
            </Grid>
          }
        >
          <div className={clsx(classes.dataGridContainer, 'ag-theme-alpine')}>
            <AgGridReact
              columnDefs={config.columnDefs}
              animateRows
              rowData={data}
              rowSelection="multiple"
              defaultColDef={config.defaultColDef}
              domLayout="normal"
              autoSizePadding={4}
              modules={AllModules}
              immutableData
              getRowNodeId={(rowData) => rowData.name}
              getRowStyle={config.getRowStyle}
              rowHeight={30}
              frameworkComponents={config.frameworkComponents}
              onFirstDataRendered={config.onFirstDataRendered}
            />
          </div>
        </TableExtension>
      </Grid>
    </Grid>
  );
}

ShadowGroups.propTypes = {
  stateShadowGroup: propTypeShadowGroup.isRequired,
  cmdGroupAction: PropTypes.func.isRequired,
};

export default ShadowGroups;
