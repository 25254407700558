import formValidation from '../shared/form-validation';

const manageCtrlState = ({ state, name, value }) => {
  const def = { ...state[name] };
  const { validators } = def;
  const errors = formValidation.checkValidity(value, validators);

  return {
    ...state,
    [name]: {
      ...def,
      isTouched: true,
      isError: !!errors.length,
      value,
    },
  };
};

const updateCtrlState = ({ state, name, value, prop = 'value' }) => {
  const def = { ...state[name] };
  const { validators } = def;
  const errors = formValidation.checkValidity(value, validators);

  return {
    ...state,
    [name]: {
      ...def,
      isTouched: true,
      isError: !!errors.length,
      [prop]: value,
    },
  };
};

const ruleErrors = (state) => {
  return Object.keys(state).reduce((acc, cur) => {
    return [
      ...acc,
      ...formValidation.checkValidity(state[cur].value, state[cur].validators),
    ];
  }, []);
};

const hasErrors = (state) => {
  return !!ruleErrors(state).length;
};

const validateCtrlState = ({ state, name }) => {
  const def = { ...state[name] };
  const { value, validators } = def;
  const errors = formValidation.checkValidity(value, validators);

  return {
    [name]: {
      ...def,
      isError: !!errors.length,
    },
  };
};

const validateCtrls = (state) => {
  return Object.keys(state).reduce((acc, cur) => {
    return {
      ...acc,
      ...validateCtrlState({ state, name: cur }),
    };
  }, {});
};
const sortObjArrByProp =
  (prop, direction = 'asc') =>
  (a, b) => {
    if (a[prop] < b[prop]) {
      if (direction.toLowerCase() === 'asc') {
        return -1;
      }
      return 1;
    }
    if (a[prop] > b[prop]) {
      if (direction.toLowerCase() === 'asc') {
        return 1;
      }
      return -1;
    }
    return 0;
  };
export default Object.freeze({
  manageCtrlState,
  updateCtrlState,
  hasErrors,
  validateCtrls,
  validateCtrlState,
  sortObjArrByProp,
});
