import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    '& > h2': {
      fontSize: '1.5em',
    },
  },
}));

const localThemeOverride = (theme) =>
  createTheme({
    palette: {
      primary: theme.palette.success,
      secondary: theme.palette.error,
    },
  });

function ConfirmDialog({
  isOpen,
  title,
  actionLabel,
  entityLabel,
  cancelBtnLabel,
  confirmBtnLabel,
  onCloseHandler,
  onConfirmHandler,
  selectedRowsCount,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const localTheme = localThemeOverride(theme);
  return (
    <Dialog
      open={isOpen}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span>
            {`Please 'confirm' you wish to ${actionLabel} ${selectedRowsCount} selected ${entityLabel}.`}
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={localTheme}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ClearIcon />}
            onClick={onCloseHandler}
          >
            {cancelBtnLabel}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={() => {
              onCloseHandler();
              onConfirmHandler();
            }}
            autoFocus
          >
            {confirmBtnLabel}
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  actionLabel: PropTypes.string,
  entityLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  confirmBtnLabel: PropTypes.string,
  onCloseHandler: PropTypes.func.isRequired,
  onConfirmHandler: PropTypes.func.isRequired,
  selectedRowsCount: PropTypes.number.isRequired,
};

ConfirmDialog.defaultProps = {
  title: '',
  actionLabel: '',
  entityLabel: '',
  cancelBtnLabel: 'Abort',
  confirmBtnLabel: 'Confirm',
};

export default ConfirmDialog;
