import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, LicenseManager } from '@ag-grid-enterprise/all-modules';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

import groupStrategiesFormEditPresenter from '../../../presenters/group-strategies-form-edit';

import propTypeShadowGroupStrategyParam from '../../../prop-types/shadow-group-strategy-param';
import propTypeShadowInstrument from '../../../prop-types/shadow-instrument';
import propDefaultApiGet from '../../../prop-defaults/api-get';

import appConfig from '../../../app-config';
import gridConfig from './grid-config';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../../hooks/trace-update-hook';

import '../../../theme/ag-grid-alpine-component.scss';
import styles from './grid-component.module.css';
import TableExtension from '../../../components/TableExtension';

LicenseManager.setLicenseKey(appConfig.agGrid.licence);

const useStyles = makeStyles(() => ({
  root: {},
}));

/*
 * This component serves to display the row data (provided by redux)
 * TODO: improve UX
 * - Prevent jank when strategy is added/edited because grid is rebuilt with new data
 * - Improve change tracking to prevent unnecessary 'save changes' API calls
 * - Improve row state tracking by using row key rather than unstable state array position
 * - Improve 'autocomplete' component integration: on focus loss restore previous selection if nothing entered
 * - Improve biz rule validation: handle/prevent duplicate row keys
 */
function GridComponent({
  stateShadowInstruments,
  stateShadowGroupStrategyParam,
  retrieveShadowGroupStrategyParam,
  updateShadowGroupStrategyParamAction,
  removeShadowGroupStrategyParamAction,
  cmdGroupAction,
  cmdStrategyAction,
  retrieveInstruments,
  stateGroupSelected,
  handleCreateStratDialogOpen,
}) {
  const classes = useStyles();
  const [isCellInEditMode, setIsCellInEditMode] = useState(false);
  const [mutRecords, setMutRecords] = useState([]);
  // Note: updateQueue hash table (array position used as key)
  // TODO: use db composite key instead: groupName-instrumentId-counterparty
  // stores object with changed key/value pairs against hash table key
  const [updateQueue, setUpdateQueue] = useState({});
  const presenter = groupStrategiesFormEditPresenter();

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   stateShadowInstruments,
  //   stateShadowGroupStrategyParam,
  //   retrieveShadowGroupStrategyParam,
  //   updateShadowGroupStrategyParamAction,
  //   removeShadowGroupStrategyParamAction,
  //   cmdGroupAction,
  //   retrieveInstruments,
  //   stateGroupSelected,
  //   handleCreateStratDialogOpen,
  //   updateQueue,
  //   mutRecords,
  // });

  useEffect(() => {
    if (
      stateShadowGroupStrategyParam &&
      stateShadowGroupStrategyParam.retrieveResult &&
      stateShadowGroupStrategyParam.retrieveResult.data
    ) {
      let newMutRecords = JSON.parse(
        JSON.stringify(stateShadowGroupStrategyParam.retrieveResult.data)
      );
      newMutRecords = newMutRecords.map((rec) => {
        return { ...rec, errored: false };
      });
      setMutRecords(newMutRecords);
    }
  }, [stateShadowGroupStrategyParam]);

  useEffect(() => {
    function retrieveFromApi({ groupName }) {
      retrieveShadowGroupStrategyParam({ groupName });
      retrieveInstruments();
    }
    retrieveFromApi({ groupName: stateGroupSelected });
  }, [
    retrieveShadowGroupStrategyParam,
    retrieveInstruments,
    stateGroupSelected,
  ]);

  const handleCellUpdate = (event) => {
    const { colDef, rowIndex, newValue, oldValue, node, data } = event;
    if (newValue !== oldValue) {
      const mutObject = { ...updateQueue };
      mutObject[rowIndex] = {
        ...(mutObject[rowIndex] || {}),
        [colDef.field]: newValue,
      };
      const mutArray = [...mutRecords];
      mutArray[rowIndex] = {
        ...mutArray[rowIndex],
        [colDef.field]: newValue,
      };
      // Detect errors
      const { counterparty, groupName, instrumentId } = data;
      const repeatedPKs = mutArray.filter(
        (obj) =>
          obj.counterparty === counterparty &&
          obj.groupName === groupName &&
          obj.instrumentId === instrumentId
      );
      if (repeatedPKs.length > 1) {
        mutArray[rowIndex].errored = true;
      }
      setMutRecords(mutArray);
      setUpdateQueue(mutObject);
      setTimeout(() => node.setData(data), 0);
    }
  };

  const handleCellEditStart = () => {
    setIsCellInEditMode(true);
  };

  const handleCellEditStop = () => {
    setIsCellInEditMode(false);
  };

  const getRowStyle = (params) => {
    const { node } = params;
    const { rowIndex, data } = node;
    const currentData =
      stateShadowGroupStrategyParam.retrieveResult.data[rowIndex];
    let background = 'white';
    const currentDataStr = JSON.stringify(currentData);
    const rowData = { ...data };
    delete rowData.errored;
    const rowDataStr = JSON.stringify(rowData);
    if (currentDataStr !== rowDataStr) {
      background = 'yellow';
    }
    if (data.errored) {
      background = '#AD1100';
    }
    return {
      background,
    };
  };

  const handleUpdateRecord = async () => {
    if (
      stateShadowGroupStrategyParam.retrieveResult &&
      stateShadowGroupStrategyParam.retrieveResult.data
    ) {
      const updatedIndexes = Object.keys(updateQueue);
      const payload = updatedIndexes.map((index) => {
        const data = stateShadowGroupStrategyParam.retrieveResult.data[index];
        const values = {
          ...data,
          ...updateQueue[index],
        };
        delete values.errored;
        return {
          pk: {
            groupName: data.groupName,
            instrumentId: data.instrumentId,
            counterparty: data.counterparty,
          },
          values,
        };
      });
      setUpdateQueue({});
      await updateShadowGroupStrategyParamAction({
        shadowGroupParamUpdates: payload,
      });
      cmdGroupAction({ groupName: stateGroupSelected, action: 'sync' });
    }
  };

  const handleDeleteRecord = async ({
    groupName,
    counterparty,
    instrumentId,
  }) => {
    await removeShadowGroupStrategyParamAction({
      shadowGroupParamsToRemove: presenter.prepSubmitPayload({
        groupName,
        counterparty,
        instrumentId,
      }),
    });
    cmdStrategyAction(
      presenter.prepCmdPayload({
        groupName,
        counterparty,
        instrumentId,
        action: 'delete',
      })
    );
  };

  const config = gridConfig(handleDeleteRecord);

  return (
    <div className={classes.root}>
      <TableExtension
        title="Strategy"
        actions={
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleCreateStratDialogOpen}
              >
                Add Strategy
              </Button>
            </Grid>
            <Grid item xs={4} style={{ color: '#22262e' }}>
              {Object.keys(updateQueue).length !== 0 && (
                <Button
                  disabled={isCellInEditMode}
                  variant="outlined"
                  color="inherit"
                  fullWidth
                  onClick={handleUpdateRecord}
                >
                  Save changes
                </Button>
              )}
            </Grid>
          </Grid>
        }
      >
        {stateShadowInstruments &&
        stateShadowInstruments.retrieveResult &&
        stateShadowInstruments.retrieveResult.data &&
        !stateShadowGroupStrategyParam.isUpdating &&
        !stateShadowGroupStrategyParam.isRetrieving &&
        !stateShadowGroupStrategyParam.isCreating ? (
          <div className={clsx(styles.container, 'ag-theme-alpine')}>
            <AgGridReact
              // properties
              // debug
              columnDefs={config.columnDefs}
              animateRows
              singleClickEdit
              stopEditingWhenGridLosesFocus
              rowData={mutRecords}
              onCellValueChanged={handleCellUpdate}
              onCellEditingStarted={handleCellEditStart}
              onCellEditingStopped={handleCellEditStop}
              modules={AllModules}
              defaultColDef={config.defaultColDef}
              domLayout="normal"
              autoSizePadding={4}
              // Note: disabled to prevent automatic merging of records behaviour
              // when end user changes record props 'User/Cpty' and 'Instrument'
              // to match an existing record: highlights row instead to indicate error
              // immutableData
              getRowNodeId={(data) =>
                `${data.counterparty}${data.instrumentId}`
              }
              getRowStyle={getRowStyle}
              rowHeight={30}
              // events
              onFirstDataRendered={config.onFirstDataRendered}
            />
          </div>
        ) : null}
      </TableExtension>
    </div>
  );
}

GridComponent.propTypes = {
  stateShadowInstruments: propTypeShadowInstrument,
  stateShadowGroupStrategyParam: propTypeShadowGroupStrategyParam,
  retrieveShadowGroupStrategyParam: PropTypes.func.isRequired,
  updateShadowGroupStrategyParamAction: PropTypes.func.isRequired,
  removeShadowGroupStrategyParamAction: PropTypes.func.isRequired,
  cmdGroupAction: PropTypes.func.isRequired,
  cmdStrategyAction: PropTypes.func.isRequired,
  retrieveInstruments: PropTypes.func.isRequired,
  stateGroupSelected: PropTypes.string,
  handleCreateStratDialogOpen: PropTypes.func.isRequired,
};

GridComponent.defaultProps = {
  stateShadowInstruments: propDefaultApiGet,
  stateShadowGroupStrategyParam: propDefaultApiGet,
  stateGroupSelected: '',
};

export default GridComponent;
