import { connect } from 'react-redux';

import ClientGroup from './ClientGroup';

const mapStateToProps = function mapStateToProps(state) {
  const { clientGroup } = state;
  const { selected: stateSelected } = clientGroup;
  return {
    stateSelected,
  };
};

export default connect(mapStateToProps)(ClientGroup);
