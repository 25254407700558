export const IO_ORDER_UPDATE = 'order:update';
export const IO_ORDER_HISTORY = 'order:history';

export const IO_SHADOW_GROUP_STATUS_UPDATE =
  'vision:shadow-group:status-update';

export const IO_PRICE_CONTROL_PRESET_CREATE_OR_UPDATE =
  'vision:price-control-preset:create-or-update';
export const IO_PRICE_CONTROL_PRESET_DESTROY =
  'vision:price-control-preset:destroy';

export const IO_CLIENT_GROUP_CREATE_OR_UPDATE =
  'vision:client-group:create-or-update';
export const IO_CLIENT_GROUP_DESTROY = 'vision:client-group:destroy';

export const IO_SYSTEM_ALERT_MESSAGE = 'vision:system:alert';
