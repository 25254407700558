import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

import ConfirmDialog from '../ConfirmDialog';

const styles = (theme) => ({
  smallButton: {
    paddingBottom: '0.2em',
  },
  smallIcon: {
    fontSize: '1.1em',
  },
  destroy: {
    color: theme.palette.error.main,
  },
});

class DeleteRecordRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDeleteRecord = () => {
    const { data, deleteRecordHandler } = this.props;
    const { counterparty, groupName, instrumentId } = data;

    deleteRecordHandler({ counterparty, groupName, instrumentId });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <>
        <IconButton
          className={classes.smallButton}
          color="secondary"
          aria-label="cancel"
          size="small"
          variant="contained"
          classes={{ colorSecondary: classes.destroy }}
          onClick={this.handleClickOpen}
        >
          <CancelPresentationIcon className={classes.smallIcon} />
        </IconButton>
        <ConfirmDialog
          isOpen={open}
          onCloseHandler={this.handleClose}
          onConfirmHandler={() => this.handleDeleteRecord()}
          selectedRowsCount={1}
        />
      </>
    );
  }
}

DeleteRecordRow.propTypes = {
  classes: PropTypes.shape({
    smallButton: PropTypes.string.isRequired,
    smallIcon: PropTypes.string.isRequired,
    destroy: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    internalId: PropTypes.string,
    counterparty: PropTypes.string,
    groupName: PropTypes.string,
    instrumentId: PropTypes.number,
    copyMaxQty: PropTypes.number,
    copyMinQty: PropTypes.number,
    enabled: PropTypes.number,
    errored: PropTypes.bool,
    hedgeRatio: PropTypes.number,
    orderType: PropTypes.string,
    retryIntervalMillis: PropTypes.number,
    strategy: PropTypes.string,
    timeInForce: PropTypes.string,
  }),
  deleteRecordHandler: PropTypes.func.isRequired,
};

DeleteRecordRow.defaultProps = {
  data: {
    inernalId: null,
    counterparty: null,
    groupName: null,
    instrumentId: null,
    copyMaxQty: null,
    copyMinQty: null,
    enabled: null,
    errored: null,
    hedgeRatio: null,
    orderType: null,
    retryIntervalMillis: null,
    strategy: null,
    timeInForce: null,
  },
};

export default withStyles(styles)(DeleteRecordRow);
