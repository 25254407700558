import instrumentRepository from '../../repostitories/price-control-preset-instrument-repository';

import { API_FAIL as API_CLIENT_FAIL } from './api-client-failure';

const RETRIEVE = 'store/price-control-preset-instrument/RETRIEVE';
const RETRIEVE_SUCCESS =
  'store/price-control-preset-instrument/RETRIEVE_SUCCESS';

const repo = instrumentRepository();

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        isRetrieving: true,
      };
    case RETRIEVE_SUCCESS:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: null,
        retrieveResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoPriceControlPresetInstrumentDataSourceInput(
            action.result.data
          ),
        },
      };

    default:
      return state;
  }
}

export function retrieve(authToken) {
  return {
    types: [RETRIEVE, RETRIEVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.get(`/price-control-preset-instruments`, null, authToken),
  };
}
