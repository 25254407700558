import { connect } from 'react-redux';

import { retrieve as retrieveProOrganisationAction } from '../../../store/modules/shadow-pro-organisation';
import { retrieve as retrieveStreamAction } from '../../../store/modules/shadow-stream';
import {
  create as createShadowGroupAction,
  update as updateShadowGroupAction,
  cmdGroup as cmdGroupAction,
} from '../../../store/modules/shadow-group';

import GroupForm from './GroupForm';

const mapStateToProps = function mapStateToProps(state) {
  const {
    shadowProOrganisation: stateShadowProOrganisation,
    shadowStream: stateShadowStream,
    shadowStrategyParamConfig: stateShadowStrategyParamConfig,
  } = state;
  const { groupSelected: stateGroupSelected } =
    stateShadowStrategyParamConfig || {};
  return {
    stateShadowProOrganisation,
    stateShadowStream,
    stateGroupSelected,
  };
};

export default connect(mapStateToProps, {
  retrieveProOrganisationAction,
  retrieveStreamAction,
  createShadowGroupAction,
  updateShadowGroupAction,
  cmdGroupAction,
})(GroupForm);
