import ValTypeBoolNum from '../../ValTypeBoolNum';
import ValTypePositiveInteger from '../../ValTypePositiveInteger';
import ValTypeTif from '../../ctx-shadow-strat-param/ValTypeTif';
import ValTypeHedgeRatio from '../../ctx-shadow-strat-param/ValTypeHedgeRatio';
import ValTypeAlphaNumDash from '../../ValTypeAlphaNumDash';

const entityShadowParameter = {
  groupName: ValTypeAlphaNumDash,
  counterparty: String,
  instrumentId: Number,
  strategy: [String],
  enabled: ValTypeBoolNum,
  orderType: String,
  timeInForce: ValTypeTif,
  hedgeRatio: ValTypeHedgeRatio,
  copyMinQty: ValTypePositiveInteger,
  copyMaxQty: ValTypePositiveInteger,
  retryIntervalMillis: ValTypePositiveInteger,
};

export default entityShadowParameter;
