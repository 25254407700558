export const RETRIEVE = 'store/user/RETRIEVE';
export const RETRIEVE_SUCCESS = 'store/user/RETRIEVE_SUCCESS';
export const RETRIEVE_FAIL = 'store/user/RETRIEVE_FAIL';

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        isRetrieving: true,
      };
    case RETRIEVE_SUCCESS:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: null,
        retrieveResult: {
          hasRecords: !!action.result,
          count: 1,
          data: action.result,
        },
      };
    case RETRIEVE_FAIL:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: action.error,
        retrieveResult: null,
      };
    default:
      return state;
  }
}
