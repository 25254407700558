export default /^\s*(<{1}(.*)+>{1})+(.*)?(<{1}.+?>{1})?$/im;
// note: It appears 'g' (global) option flag not supported by ObjectModel.js

// --> loose rules
// content should begin with a tag: <...>,
// optionally followed with spaces/or characters
// optionally followed with closing tag: <...>,

// --> matches everything below

/*


<!DOCTYPE HTML>
<html>
<head>
<title>#Title</title>
<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<link href="../static/theme.css" rel="stylesheet" type="text/css"/>
<script src="../static/content.js" type="text/javascript"></script>
</head>
<body>
<h1>#Heading 1</h1><br />
<br />
<p style="font-color:red">Paragraph 1 link: (<a href="ExitApp.htm">ExitApp</a>).</p>
<pre class="Syntax">#Persistent</pre>
<p>Paragraph 2.0 link: <a href="_SingleInstance.htm">#SingleInstance</a>.</p>
<h3>Heading 2</h3>
<ul>
	<li>List Item 1</li>
	<li>List Item 2</li>
</ul>
<p><a href="_SingleInstance.htm">#SingleInstance</a>, <a href="SetTimer.htm">SetTimer</a>, <a href="Menu.htm">Menu</a>, <a href="Exit.htm">Exit</a>, <a href="ExitApp.htm">ExitApp</a></p>
<h3>Example</h3>
<pre class="NoIndent">#Persistent</pre>
</body>
</html>


*/
