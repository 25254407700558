// Derived from source:
// https://github.com/modularcode/modular-material-admin-react

import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Dashboard as IconDashboard,
  Group as IconGroup,
  LocalOffer as IconLocalOffer,
} from '@material-ui/icons';
import { Strategy as IconStrategy } from '../../../../components/icons';

import SidebarNavItems from './SidebarNavItems';

const useStyles = makeStyles((theme) =>
  createStyles({
    navList: {
      width: theme.props.layout.drawer.width,
      fontSize: '1.1em',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
  })
);

function SidebarNav({ isCollapsed, className }) {
  const classes = useStyles();

  const itemsApp = [
    {
      name: 'Dashboard',
      link: '/',
      Icon: IconDashboard,
    },
    {
      name: 'Strategies',
      Icon: IconStrategy,
      items: [
        {
          name: 'Params',
          link: '/parameters/strategy-params',
        },
        {
          name: 'Mangement',
          link: '/parameters/strategy-management',
        },
      ],
    },
    {
      name: 'Fastmode',
      Icon: IconLocalOffer,
      items: [
        {
          name: 'Controls',
          link: '/price-controls',
        },
        {
          name: 'Editor',
          link: '/price-controls-editor',
        },
      ],
    },
    {
      name: 'Client Group Management',
      link: '/client-group-management',
      Icon: IconGroup,
    },
  ];

  return (
    <nav className={className}>
      <List className={classes.navList} disablePadding>
        <SidebarNavItems isCollapsed={isCollapsed} items={itemsApp} />
      </List>
    </nav>
  );
}

SidebarNav.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

SidebarNav.defaultProps = {
  className: null,
};

export default SidebarNav;
