import { shape, string } from 'prop-types';

const propTypeOrderUpdate = shape({
  msgType: string.isRequired,
  timeEntered: string.isRequired,
  timeModified: string.isRequired,
  expirationTime: string,
  instrument: string.isRequired,
  side: string.isRequired,
  initialQty: string.isRequired,
  quantity: string.isRequired,
  filledQty: string.isRequired,
  remainingQty: string.isRequired,
  price: string.isRequired,
  status: string.isRequired,
  source: string.isRequired,
  counterparty: string.isRequired,
  orderType: string.isRequired,
  tif: string.isRequired,
  stopPrice: string,
  internalId: string.isRequired,
});

export default propTypeOrderUpdate;
