import React from 'react';
import PropTypes from 'prop-types';

import propTypeNavItem from '../../../../prop-types/nav-item';

import SidebarNavItem from './SidebarNavItem';

function SidebarNavItems({
  items = [],
  isCollapsed = false,
  isNested = false,
}) {
  return (
    <>
      {items.map((item) => (
        <SidebarNavItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...item}
          isCollapsed={isCollapsed}
          isNested={isNested}
          key={item.name}
        />
      ))}
    </>
  );
}

SidebarNavItems.propTypes = {
  items: PropTypes.arrayOf(propTypeNavItem).isRequired,
  isCollapsed: PropTypes.bool,
  isNested: PropTypes.bool,
};

SidebarNavItems.defaultProps = {
  isCollapsed: false,
  isNested: false,
};

export default SidebarNavItems;
