import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactDOM from 'react-dom';

const useStyles = makeStyles(() => ({
  root: {
    borderWidth: '0px',
    padding: 15,
    textAlign: 'center',
    display: 'inline-block',
    float: 'left',
    position: 'fixed',
    backgroundColor: '#f8f8f8',
    outlineColor: '#dde2eb',
    outlineWidth: '1px',
    outlineOffset: '0px',
    outlineStyle: 'solid',
    boxShadow: '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
  },
}));

const AutoComplete = forwardRef(
  (
    {
      value,
      api,
      options,
      column,
      variant,
      margin,
      size,
      error,
      disabled,
      required,
      labelText,
      helperText,
      className,
      autoCompleteStyle,
      textFieldStyle,
      getOptionLabel,
      getOptionSelected,
    },
    ref
  ) => {
    const classes = useStyles();
    const [ctrlVal, setCtrlVal] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);

    const onChangeHandler = (e, selected) => {
      setCtrlVal((selected && selected.id) || selected);
      setEditing(false);
    };

    const onInputChangeHandler = (e, inputVal) => {
      setInputValue(inputVal);
    };

    useEffect(() => {
      focus();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return ctrlVal;
        },
        afterGuiAttached: () => {
          setCtrlVal(value);
        },
        // Note: isPopup = true requires grid prop 'stopEditingWhenGridLosesFocus' = false
        // otherwise events 'onChange' & 'onInputChange' are never called
        isPopup: () => {
          return true;
        },
      };
    });

    const focus = () => {
      window.setTimeout(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const container = ReactDOM.findDOMNode(refContainer.current);
        if (container) {
          container.focus();
        }
      });
    };

    useEffect(() => {
      if (!editing) {
        api.stopEditing();
      }
    }, [api, editing]);

    return (
      <div
        ref={refContainer}
        className={classes.root}
        /* eslint-disable jsx-a11y/tabindex-no-positive */
        /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
        tabIndex={1} // important - without this the key presses wont be caught
        /* eslint-enable jsx-a11y/no-noninteractive-tabindex */
        /* eslint-enable jsx-a11y/tabindex-no-positive */
      >
        <Autocomplete
          style={autoCompleteStyle}
          className={className}
          options={options}
          value={ctrlVal}
          inputValue={inputValue}
          onChange={onChangeHandler}
          onInputChange={onInputChangeHandler}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          renderInput={(params) => {
            return (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                style={textFieldStyle}
                placeholder={`Select ${column.colId}`}
                variant={variant}
                margin={margin}
                size={size}
                error={error}
                disabled={disabled}
                required={required}
                helperText={helperText}
                label={labelText}
              />
            );
          }}
        />
      </div>
    );
  }
);

const propTypeOption = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
});

AutoComplete.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(propTypeOption).isRequired,
  column: PropTypes.shape({ colId: PropTypes.string }).isRequired,
  api: PropTypes.shape({ stopEditing: PropTypes.func }).isRequired,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  variant: PropTypes.oneOf(['standard', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium']),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelText: PropTypes.string,
  helperText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  autoCompleteStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  textFieldStyle: PropTypes.object,
  className: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
};

AutoComplete.defaultProps = {
  value: { id: 0, label: '' },
  margin: 'dense',
  variant: 'outlined',
  size: 'small',
  error: false,
  disabled: false,
  required: false,
  labelText: '',
  helperText: '',
  autoCompleteStyle: { padding: '0 10px' },
  textFieldStyle: { padding: '5px 0' },
  className: null,
  getOptionLabel: (option) => (option.label && option.label) || option,
  getOptionSelected: (option, selected) =>
    (option.id && option.id) || option === selected.id,
};

export default AutoComplete;
