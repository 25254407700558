import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ToastAlert from '../ToastAlert';

import presenter from './presenter';

const useStyles = makeStyles(() => ({
  alertAction: {
    alignItems: 'start',
  },
}));

function SocketClientErrors({ stateSystemAlert }) {
  const classes = useStyles();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const present = presenter();
  const presentVm = useCallback(present.vm, []);
  const [vm, setVm] = useState(presentVm(null));

  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  useEffect(() => {
    if (
      stateSystemAlert &&
      stateSystemAlert.errors != null &&
      Array.isArray(stateSystemAlert.errors) &&
      stateSystemAlert.errors.length > 0
    ) {
      setIsAlertOpen(true);
      const displayContent = stateSystemAlert.errors;

      setVm(
        presentVm(
          displayContent,
          stateSystemAlert.severity,
          stateSystemAlert.status
        )
      );
    } else {
      setIsAlertOpen(false);
    }
  }, [stateSystemAlert, presentVm]);

  return (
    vm != null && (
      <ToastAlert
        severity={vm.severity}
        isAlertOpen={isAlertOpen}
        closeAlertHandler={handleCloseAlert}
        alertTitleCopy={vm.alertTitleCopy}
        alertBodyCopy={vm.alertBodyCopy}
        alertClasses={{ action: classes.alertAction }}
      />
    )
  );
}

SocketClientErrors.propTypes = {
  stateSystemAlert: PropTypes.shape({
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        state: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    severity: PropTypes.oneOf(['URGENT', 'WARNING', 'LOW']),
    status: PropTypes.shape({
      isConnected: PropTypes.bool,
      isConnecting: PropTypes.bool,
      isDisconnecting: PropTypes.bool,
      isDisconnected: PropTypes.bool,
    }),
  }),
};

SocketClientErrors.defaultProps = {
  stateSystemAlert: null,
};

export default SocketClientErrors;
