import { connect } from 'react-redux';

import PriceControls from './PriceControls';

import { retrieve as retrievePriceControlPresetsAction } from '../../store/modules/price-control-presets';

const mapStateToProps = function mapStateToProps(state) {
  const { priceControlPresets: statePriceControlPresets } = state;
  return {
    statePriceControlPresets,
  };
};

export default connect(mapStateToProps, {
  retrievePriceControlPresetsAction,
})(PriceControls);
