import { IO_SYSTEM_ALERT_MESSAGE } from './constants';
import systemAlertRepository from '../../repostitories/system-alert-repository';

// Receive doesn't actualy receive messages.
// but prepares the socket to receive messages.
const RECEIVE = 'store/system-alert/RECEIVE';
const RECEIVE_SUCCESS = 'store/system-alert/RECEIVE_SUCCESS';
const RECEIVE_FAIL = 'store/system-alert/RECEIVE_FAIL';

const MESSAGE = 'store/system-alert/MESSAGE';

const repo = systemAlertRepository();

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case MESSAGE:
      return {
        ...state,
        retrieveResult: state.retrieveResult
          ? {
              ...state.retrieveResult,
              ...repo.dtoSystemAlertMsgDataSourceInput(action.result),
            }
          : repo.dtoSystemAlertMsgDataSourceInput(action.result),
      };
    default:
      return state;
  }
}

export function receive() {
  return (dispatch) => {
    const newMessage = (message) => {
      return dispatch({
        type: MESSAGE,
        result: message,
      });
    };

    return dispatch({
      type: 'socket',
      types: [RECEIVE, RECEIVE_SUCCESS, RECEIVE_FAIL],
      promise: (socket) => socket.on(IO_SYSTEM_ALERT_MESSAGE, newMessage),
    });
  };
}
