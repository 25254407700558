import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Drawer,
  Grid,
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { ErrorBoundary } from '@sentry/react';

import Loading from '../../components/Loading';
import GridComponent from './GridComponent';
import FormIconButton from '../../forms/FormIconButton';
import CreatePresetForm from '../../forms/PriceControls/CreatePresetForm';

import propTypePriceControlPreset from '../../prop-types/price-control-preset';
import propTypePriceControlPresetInstrument from '../../prop-types/price-control-preset-instrument';
import propTypeClientGroup from '../../prop-types/client-group';
import Fallback from '../../components/Fallback';

import presenter from './presenter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: '100%',
  },
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flex: '1 1 100%',
    width: '100%',
    margin: 0,
    padding: theme.spacing(1),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '4px',
    },
  },
  cardHeader: {
    padding: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  cardHeaderAction: {
    marginTop: '0px',
  },
  cardContent: {
    padding: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    height: '100%',
  },
}));

function PriceControlsEditor({
  retrievePriceControlPresetsAction,
  retrievePriceControlPresetInstrumentAction,
  retrieveClientGroupAction,
  updatePriceControlPresetsAction,
  removePriceControlPresetsAction,
  cmdPriceControlPresetsAction,
  statePriceControlPresets,
  statePriceControlPresetInstrument,
  stateClientGroup,
}) {
  const classes = useStyles();
  const present = presenter();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const retrieveResultData =
    statePriceControlPresets &&
    statePriceControlPresets.retrieveResult &&
    statePriceControlPresets.retrieveResult.data &&
    !statePriceControlPresets.retrieveResult.isRetrieving &&
    !statePriceControlPresets.retrieveResult.isUpdating &&
    !statePriceControlPresets.retrieveResult.isCmdInProgress
      ? statePriceControlPresets.retrieveResult.data
      : [];

  const priceCtrlPresetInsRetrieveResData =
    statePriceControlPresetInstrument &&
    statePriceControlPresetInstrument.retrieveResult &&
    statePriceControlPresetInstrument.retrieveResult.data
      ? statePriceControlPresetInstrument.retrieveResult.data
      : [];

  const clientGroupRetrieveResData =
    stateClientGroup &&
    stateClientGroup.retrieveResult &&
    stateClientGroup.retrieveResult.data
      ? stateClientGroup.retrieveResult.data
      : [];

  const tableRows = present.presetsWithTypesPivot({
    data: retrieveResultData,
  });

  const handleCellEditStarted = (params) => {
    if (params.data.isActive) {
      params.api.stopEditing(true);
      const rowNode1 = params.api.getDisplayedRowAtIndex(params.rowIndex);
      params.api.flashCells({
        rowNodes: [rowNode1],
      });
    }
  };

  const handleCellValueChanged = (data) => async (params) => {
    if (params.type !== 'cellValueChanged') return;

    const statePreset = data.find((preset) => preset.id === params.data.id);
    const colField = params.colDef.field;
    const { newValue } = params;
    const payload = present.prepNewPreset(statePreset, colField, newValue);

    if (colField === 'isActive') cmdPriceControlPresetsAction(payload);

    if (colField !== 'isActive') {
      updatePriceControlPresetsAction(payload);
    }
  };

  const toggleDrawerHandler = (isOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpen(isOpen);
  };

  useEffect(() => {
    function retrieveFromApi() {
      Promise.all([
        retrievePriceControlPresetInstrumentAction(),
        retrievePriceControlPresetsAction(),
        retrieveClientGroupAction(),
      ]).then(() => setIsLoaded(true));
    }
    retrieveFromApi();
  }, [
    retrievePriceControlPresetsAction,
    retrievePriceControlPresetInstrumentAction,
    retrieveClientGroupAction,
  ]);

  return (
    <ErrorBoundary fallback={Fallback}>
      <div className={classes.root}>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12}>
            <Card className={classes.cardContainer}>
              <CardHeader
                title="Price Controls Editor"
                titleTypographyProps={{ variant: 'subtitle1' }}
                className={classes.cardHeader}
                classes={{ action: classes.cardHeaderAction }}
                action={
                  <FormIconButton
                    id="toggle-context-drawer-button"
                    variant="outlined"
                    color="primary"
                    size="small"
                    icon={<Create />}
                    onClick={toggleDrawerHandler(true)}
                  >
                    Create
                  </FormIconButton>
                }
              />
              <Divider />
              <CardContent className={classes.cardContent}>
                {isLoaded ? (
                  <GridComponent
                    rowData={tableRows}
                    stateInstrumentData={priceCtrlPresetInsRetrieveResData}
                    stateGroupData={clientGroupRetrieveResData}
                    deleteHandler={removePriceControlPresetsAction}
                    cellValueChangedHandler={handleCellValueChanged(
                      retrieveResultData
                    )}
                    cellEditStartedHandler={handleCellEditStarted}
                  />
                ) : (
                  <Loading />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawerHandler(false)}
      >
        {isLoaded && (
          <CreatePresetForm
            formHeading="Create Preset"
            postSubmitCleanupHandler={({ isShowForm = false } = {}) =>
              setIsDrawerOpen(isShowForm)
            }
            statePresetNames={retrieveResultData.map((preset) => preset.name)}
          />
        )}
      </Drawer>
    </ErrorBoundary>
  );
}

PriceControlsEditor.propTypes = {
  stateClientGroup: propTypeClientGroup,
  statePriceControlPresets: propTypePriceControlPreset,
  statePriceControlPresetInstrument: propTypePriceControlPresetInstrument,
  retrieveClientGroupAction: PropTypes.func.isRequired,
  retrievePriceControlPresetsAction: PropTypes.func.isRequired,
  retrievePriceControlPresetInstrumentAction: PropTypes.func.isRequired,
  updatePriceControlPresetsAction: PropTypes.func.isRequired,
  removePriceControlPresetsAction: PropTypes.func.isRequired,
  cmdPriceControlPresetsAction: PropTypes.func.isRequired,
};

PriceControlsEditor.defaultProps = {
  stateClientGroup: null,
  statePriceControlPresets: null,
  statePriceControlPresetInstrument: null,
};

export default PriceControlsEditor;
