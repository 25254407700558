import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';
import htmlReactParser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  alertTitleRoot: {
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  alertBodyCopy: {
    color: `${theme.palette.common.white} !important`,
    '& p': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  snackbar: {
    [theme.breakpoints.down('xs')]: {
      top: 90,
    },
  },
}));

function ToastAlert({
  severity,
  isAlertOpen,
  alertTitleCopy,
  alertBodyCopy,
  alertClasses,
  closeAlertHandler,
}) {
  const classes = useStyles();

  return (
    <Snackbar
      open={isAlertOpen}
      // autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={classes.snackbar}
      // onClose={closeAlertHandler}
    >
      <Alert
        // onClose={handleClose}
        elevation={6}
        variant="filled"
        severity={severity}
        classes={alertClasses}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={closeAlertHandler}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle classes={{ root: classes.alertTitleRoot }}>
          {alertTitleCopy}
        </AlertTitle>
        <span className={classes.alertBodyCopy}>
          {htmlReactParser(alertBodyCopy)}
        </span>
      </Alert>
    </Snackbar>
  );
}

ToastAlert.propTypes = {
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  isAlertOpen: PropTypes.bool.isRequired,
  closeAlertHandler: PropTypes.func.isRequired,
  alertTitleCopy: PropTypes.string,
  alertBodyCopy: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  alertClasses: PropTypes.object,
};

ToastAlert.defaultProps = {
  severity: 'info',
  alertTitleCopy: '',
  alertBodyCopy: '',
  alertClasses: null,
};

export default ToastAlert;
