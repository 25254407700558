import dtoClientGroup from '../infrastructure/dto/dto-client-group';

function clientGroupRepository() {
  function dtoClientGroupDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoClientGroup(gatewayDataObject);
    });
  }

  return Object.freeze({
    dtoClientGroupDataSourceInput,
  });
}

export default clientGroupRepository;
