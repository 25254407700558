import { IO_ORDER_UPDATE } from './constants';
import { mergeArrObjsWithProp } from '../../shared/utils';

import { API_FAIL as API_CLIENT_FAIL } from './api-client-failure';

// Receive doesn't actualy receive messages.
// but prepares the socket to receive messages.
const RECEIVE = 'store/order-update/RECEIVE';
const RECEIVE_SUCCESS = 'store/order-update/RECEIVE_SUCCESS';
const RECEIVE_FAIL = 'store/order-update/RECEIVE_FAIL';

const REMOVE = 'store/order-update/REMOVE';
const REMOVE_SUCCESS = 'store/order-update/REMOVE_SUCCESS';

const ORDER_UPDATE = 'store/order-update/ORDER_UPDATE';

const mergeArrObjsWithInternalId = mergeArrObjsWithProp('internalId');

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case ORDER_UPDATE:
      return {
        ...state,
        retrieveResult: state.retrieveResult
          ? mergeArrObjsWithInternalId(state.retrieveResult, action.result)
          : action.result,
      };
    case REMOVE:
      return {
        ...state,
        isRemoving: true,
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        isRemoving: false,
        removeError: null,
        removeResult: action.result,
      };

    default:
      return state;
  }
}

export function receive() {
  return (dispatch) => {
    const newMessage = (message) => {
      return dispatch({
        type: ORDER_UPDATE,
        result: message,
      });
    };

    return dispatch({
      type: 'socket',
      types: [RECEIVE, RECEIVE_SUCCESS, RECEIVE_FAIL],
      promise: (socket) => socket.on(IO_ORDER_UPDATE, newMessage),
    });
  };
}

export function remove({ ids }, authToken) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.delete(
        `/orders/cancel`,
        { data: { ids: ids.map((id) => id) } },
        authToken
      ),
  };
}
