import { createTheme, responsiveFontSizes } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
// import overrides from './overrides';

const theme = responsiveFontSizes(
  createTheme({
    palette,
    typography,
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: '#22262e',
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'white',
        },
      },
      MuiPaper: {
        outlined: {
          border: '1px solid #22262e',
        },
      },
    },
    props: {
      layout: {
        drawer: {
          width: 240,
        },
        menu: {
          width: 240,
          collapsedWidth: 50,
        },
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  })
);

export default theme;
