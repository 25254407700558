import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TableCell, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import {
  AutoSizer,
  Column,
  createTableMultiSort as createMultiSort,
  Table,
  SortIndicator,
} from 'react-virtualized';

import './table-styles.css';

function sortMethodAsc(a, b) {
  // eslint-disable-next-line eqeqeq
  if (a > b) {
    return 1;
    // eslint-disable-next-line no-else-return
  } else if (a < b) {
    return -1;
  }
  return 0;
}

function sortMethodWithDirection(direction) {
  if (direction === undefined || direction === 'ASC') {
    return sortMethodAsc;
  }
  return function reverseSort(a, b) {
    return -sortMethodAsc(a, b);
  };
}

function sortMethodWithDirectionByColumn(columnName, direction) {
  const sortMethod = sortMethodWithDirection(direction);
  return function sortWithDirectionByColumn(a, b) {
    return sortMethod(a[columnName], b[columnName]);
  };
}

function sortMethodWithDirectionMultiColumn(sortArray) {
  const sortMethodsForColumn = (sortArray || []).map((item) =>
    sortMethodWithDirectionByColumn(item.column, item.direction)
  );
  return function sortWithDirectionMultiColumn(a, b) {
    let sorted = 0;
    let index = 0;
    while (sorted === 0 && index < sortMethodsForColumn.length) {
      // eslint-disable-next-line no-plusplus
      sorted = sortMethodsForColumn[index++](a, b);
    }
    return sorted;
  };
}

// function lala({ cellData, columnIndex }) {
//   console.log('lala');
//   console.log('lala');
//   console.log('cellData', cellData);
//   console.log('columnIndex', columnIndex);
// }

class MuiVirtualizedTable extends React.Component {
  sortState = createMultiSort(
    ({ sortBy, sortDirection }) => {
      const { rows } = this.props;
      this.setState({ sortBy, sortDirection });

      return this.sortRows({ rows, sortDirection });
    },
    (() => {
      const { sortColumnsBy, sortColumnsDirection } = this.props;
      return {
        defaultSortBy: sortColumnsBy,
        defaultSortDirection: sortColumnsDirection,
      };
    })()
  );

  constructor(props) {
    super(props);
    const { sortColumnsBy, sortColumnsDirection } = props;
    this.state = {
      sortBy: sortColumnsBy,
      sortDirection: sortColumnsDirection,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  sortRows = ({ rows, sortDirection }) => {
    const sortColumnDirections = Object.entries(sortDirection).map((method) => {
      return {
        column: method[0],
        direction: method[1],
      };
    });

    // sample of sortColumnDirections
    // sortColumnDirections = [
    //     { column: "column5", direction: "ASC" },
    //     { column: "column3", direction: "DESC" }
    // ]

    const sortMethod = sortMethodWithDirectionMultiColumn(sortColumnDirections);

    return rows.sort(sortMethod);
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(
      index % 2 === 0 ? classes.tableRow : classes.tableHeader,
      classes.flexContainer,
      {
        [classes.tableRowHover]: index !== -1 && onRowClick != null,
      }
    );
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;

    const isSpread =
      columns[columnIndex].dataKey === 'spreadBid' ||
      columns[columnIndex].dataKey === 'spreadAsk';

    let displayData = null;

    if (columns[columnIndex].numeric) {
      if (isSpread) {
        displayData = Math.abs(cellData);
      } else {
        displayData = cellData;
      }
    } else if (!columns[columnIndex].numeric) {
      // Parse HTML encoded strings
      displayData = parse(cellData);
    }

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          {
            [classes.noClick]: onRowClick == null,
          },
          columnIndex != null &&
            columns[columnIndex].numeric &&
            cellData > 0 &&
            !isSpread &&
            classes.tableCellPositive,
          columnIndex != null &&
            columns[columnIndex].numeric &&
            cellData < 0 &&
            !isSpread &&
            classes.tableCellNegative
        )}
        variant="body"
        style={
          columns[columnIndex].numeric
            ? { height: rowHeight, fontWeight: 'bold' }
            : { height: rowHeight }
        }
        // style={{ height: rowHeight, fontWeight: 'bold', fontSize: '14px' }}
        // style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? 'right'
            : 'left'
        }
      >
        {displayData}

        {
          // TODO WIP
          /* <button onClick={() => lala({ cellData, columnIndex })}>></button> */
        }
      </TableCell>
    );
  };

  // When rendering your header columns,
  // Use the sort state exposed by sortState:
  headerRenderer = ({ dataKey, label, columnIndex }) => {
    const { headerHeight, columns, classes } = this.props;
    const showSortIndicator = this.sortState.sortBy.includes(dataKey);

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        <span title={label}>{label}</span>
        {showSortIndicator && (
          <SortIndicator
            sortDirection={this.sortState.sortDirection[dataKey]}
          />
        )}
      </TableCell>
    );
  };

  noRowsRenderer = () => {
    const { classes } = this.props;
    return (
      <Typography variant="subtitle1" className={classes.tableNoRows}>
        No active presets applied
      </Typography>
    );
  };

  render() {
    const {
      classes,
      columns,
      rowHeight,
      headerHeight,
      rowCount,
      rowGetter,
      rows,
      ...tableProps
    } = this.props;

    const rowGetter2 = ({ index }) => {
      const { sortBy, sortDirection } = this.state;
      const sortedRows = this.sortRows({ rows, sortBy, sortDirection });
      return sortedRows[index];
    };
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight}
            headerClassName={classes.tableHeader}
            className={classes.table}
            rowClassName={this.getRowClassName}
            sort={this.sortState.sort}
            rowGetter={rowGetter2}
            rowCount={rowCount}
            noRowsRenderer={this.noRowsRenderer}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...tableProps}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.shape({
    flexContainer: PropTypes.string,
    table: PropTypes.string,
    tableHeader: PropTypes.string,
    tableRow: PropTypes.string,
    tableRowHover: PropTypes.string,
    tableCell: PropTypes.string,
    tableCellPositive: PropTypes.string,
    tableCellNegative: PropTypes.string,
    noClick: PropTypes.string,
    tableNoRows: PropTypes.string,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  sortColumnsBy: PropTypes.arrayOf(PropTypes.string),
  sortColumnsDirection: PropTypes.shape({
    name: PropTypes.string,
    instrument: PropTypes.string,
  }),
  rowHeight: PropTypes.number,
  rowCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      instrument: PropTypes.string.isRequired,
      spreadAsk: PropTypes.number.isRequired,
      spreadBid: PropTypes.number.isRequired,
      skewAsk: PropTypes.number.isRequired,
      skewBid: PropTypes.number.isRequired,
      postAsk: PropTypes.number.isRequired,
      postBid: PropTypes.number.isRequired,
    })
  ).isRequired,
  rowGetter: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 28,
  rowHeight: 28,
  classes: null,
  sortColumnsBy: ['name', 'instrument'],
  sortColumnsDirection: {
    name: 'ASC',
    instrument: 'ASC',
  },
  onRowClick: null,
};

const VirtualizedTable = MuiVirtualizedTable;

export default VirtualizedTable;
