import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  ArrowForwardOutlined as ArrowForwardOutlinedIcon,
} from '@material-ui/icons';

import FormInput from '../../../forms/FormInput';
import FormIconButton from '../../../forms/FormIconButton';
import Loading from '../../../components/Loading';

import presenter from './presenter';

import propDefaultApiGet from '../../../prop-defaults/api-get';
import propTypeClientGroup from '../../../prop-types/client-group';
import propTypeClient from '../../../prop-types/client';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  choiceContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  ctrlContainer: {
    height: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  cardContent: {
    flex: '1 1 100%',
    paddingLeft: 0,
    padddingRight: 0,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: '500px',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    alignItems: 'baseline',
    alignContent: 'baseline',
  },
  listItem: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const ID_CTRL_CLIENT_CHOICE_FILTER_INPUT =
  'client-group-management-choice-filter-input';

function EditGroupForm({
  stateClientGroup,
  stateClient,
  retrieveClientGroupClientsAction,
  retrieveClientsAction,
  updateClientGroupAction,
}) {
  const classes = useStyles();
  const present = presenter({ ID_CTRL_CLIENT_CHOICE_FILTER_INPUT });
  const initialFormState = useRef(present.initialFormState);
  const [ctrlState, setCtrlState] = useState(initialFormState.current);
  const presentPrepSelectedOptions = useCallback(
    present.prepSelectedOptions,
    []
  );
  const presentUpdateCtrlState = useCallback(present.updateCtrlState, []);

  const [isClientGroupClientsLoaded, setIsClientGroupClientsLoaded] =
    useState(false);
  const [isClientsLoaded, setIsClientsLoaded] = useState(false);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const clientRetrieveResData =
    stateClient && stateClient.retrieveResult && stateClient.retrieveResult.data
      ? stateClient.retrieveResult.data
      : [];
  const clientGroupRetrieveResData =
    stateClientGroup &&
    stateClientGroup.retrieveResult &&
    stateClientGroup.retrieveResult.data
      ? stateClientGroup.retrieveResult.data
      : [];

  const leftChecked = present.intersection(checked, left);
  const rightChecked = present.intersection(checked, right);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) =>
    present.intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(present.not(checked, items));
    } else {
      setChecked(present.union(checked, items));
    }
  };

  const handleUpdate = (chosen) => {
    const payload = {
      clientGroupName: stateClientGroup.selected,
      clients: chosen,
    };
    updateClientGroupAction(payload);
  };

  const handleCheckedRight = () => {
    const rightItems = right.concat(leftChecked).sort();
    setRight(rightItems);
    setLeft(present.not(left, leftChecked));
    setChecked(present.not(checked, leftChecked));
    setCtrlState(initialFormState.current);
    handleUpdate(rightItems);
  };

  const handleCheckedLeft = () => {
    const rightItems = present.not(right, rightChecked).sort();
    setLeft(left.concat(rightChecked));
    setRight(rightItems);
    setChecked(present.not(checked, rightChecked));
    setCtrlState(initialFormState.current);
    handleUpdate(rightItems);
  };

  const handleFilterInputChange = (e) => {
    const { id, value } = e.target;
    setCtrlState(
      present.updateCtrlState({
        state: ctrlState,
        name: id,
        value: value.toUpperCase(),
      })
    );
    const selected = presentPrepSelectedOptions(
      clientGroupRetrieveResData,
      stateClientGroup.selected
    );
    if (value) {
      setLeft(
        clientRetrieveResData
          .map((client) => client.name)
          .filter(
            (val) =>
              !selected.includes(val) &&
              !val.toUpperCase().search(value.toUpperCase())
          )
      );
    } else {
      setLeft(
        clientRetrieveResData
          .map((client) => client.name)
          .filter((val) => !selected.includes(val))
      );
    }
  };

  const customList = (title, items = []) => {
    return (
      <Card className={classes.cardContainer}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />

        <CardContent className={classes.cardContent}>
          <List className={classes.list} dense component="div" role="list">
            {items.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem
                  className={classes.listItem}
                  key={value}
                  role="listitem"
                  dense
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </CardContent>
      </Card>
    );
  };

  useEffect(() => {
    async function retrieveFromApi() {
      await retrieveClientsAction();
      setIsClientsLoaded(true);
    }
    retrieveFromApi();
  }, [retrieveClientsAction]);

  useEffect(() => {
    async function retrieveFromApi({ clientGroup }) {
      await retrieveClientGroupClientsAction({
        clientGroup,
      });
      setIsClientGroupClientsLoaded(true);
    }
    retrieveFromApi({
      clientGroup: stateClientGroup.selected,
    });
  }, [retrieveClientGroupClientsAction, stateClientGroup.selected]);
  useEffect(() => {
    if (isClientsLoaded) {
      setLeft(clientRetrieveResData.map((client) => client.name));
    }
  }, [stateClient, isClientsLoaded, stateClientGroup.selected]);

  useEffect(() => {
    if (isClientsLoaded && isClientGroupClientsLoaded) {
      const selected = presentPrepSelectedOptions(
        clientGroupRetrieveResData,
        stateClientGroup.selected
      );
      setRight(selected);
      setLeft(
        clientRetrieveResData
          .map((client) => client.name)
          .filter((value) => !selected.includes(value))
      );
      // Enable filter input
      setCtrlState((prevState) =>
        presentUpdateCtrlState({
          state: prevState,
          name: ID_CTRL_CLIENT_CHOICE_FILTER_INPUT,
          prop: 'isDisabled',
          value: false,
        })
      );
    }
  }, [
    isClientsLoaded,
    isClientGroupClientsLoaded,
    presentPrepSelectedOptions,
    presentUpdateCtrlState,
    clientGroupRetrieveResData,
    stateClientGroup.selected,
    stateClient,
  ]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="baseline"
      className={classes.root}
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={5}>
            <FormInput
              labelText="Filter choices"
              value={ctrlState[ID_CTRL_CLIENT_CHOICE_FILTER_INPUT].value}
              disabled={
                ctrlState[ID_CTRL_CLIENT_CHOICE_FILTER_INPUT].isDisabled
              }
              changeHandler={handleFilterInputChange}
              fullWidth
              helperText="Enter leading character(s)"
              margin="dense"
              elementId={ID_CTRL_CLIENT_CHOICE_FILTER_INPUT}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.choiceContainer}>
        <Grid container alignItems="stretch">
          <Grid item xs={5}>
            {isClientsLoaded && !stateClient.isRetrieving ? (
              customList('Choices', left)
            ) : (
              <Loading />
            )}
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.ctrlContainer}
            >
              <FormIconButton
                variant="outlined"
                size="medium"
                color="primary"
                disabled={leftChecked.length === 0}
                icon={<ArrowForwardOutlinedIcon />}
                onClick={handleCheckedRight}
                aria-label="move selected right"
              />
              <FormIconButton
                variant="outlined"
                size="medium"
                color="primary"
                disabled={rightChecked.length === 0}
                icon={<ArrowBackOutlinedIcon />}
                onClick={handleCheckedLeft}
                aria-label="move selected left"
              />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {isClientGroupClientsLoaded &&
            !stateClientGroup.isRetrievingClients ? (
              customList('Chosen', right)
            ) : (
              <Loading />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

EditGroupForm.propTypes = {
  stateClientGroup: propTypeClientGroup,
  stateClient: propTypeClient,
  retrieveClientGroupClientsAction: PropTypes.func.isRequired,
  retrieveClientsAction: PropTypes.func.isRequired,
  updateClientGroupAction: PropTypes.func.isRequired,
};

EditGroupForm.defaultProps = {
  stateClientGroup: propDefaultApiGet,
  stateClient: propDefaultApiGet,
};

export default EditGroupForm;
