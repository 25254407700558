import { connect } from 'react-redux';

import CreatePresetForm from './CreatePresetForm';

import {
  create as createPriceControlPresetAction,
  cmd as cmdPriceControlPresetsAction,
} from '../../../store/modules/price-control-presets';

import { retrieve as retrieveClientGroupAction } from '../../../store/modules/client-group';
import { retrieve as retrievePriceControlPresetInstrumentAction } from '../../../store/modules/price-control-preset-instrument';

const mapStateToProps = function mapStateToProps(state) {
  const {
    clientGroup: stateClientGroup,
    priceControlPresetInstrument: statePriceControlPresetInstrument,
  } = state;
  return {
    stateClientGroup,
    statePriceControlPresetInstrument,
  };
};

export default connect(mapStateToProps, {
  createPriceControlPresetAction,
  cmdPriceControlPresetsAction,
  retrieveClientGroupAction,
  retrievePriceControlPresetInstrumentAction,
})(CreatePresetForm);
