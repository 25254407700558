import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, LicenseManager } from '@ag-grid-enterprise/all-modules';
import clsx from 'clsx';
import { useOktaAuth } from '@okta/okta-react';

import OrderHistoryRenderer from './renderers/OrderHistory';

import propTypeOrderUpdate from '../../prop-types/order-update';

import appConfig from '../../app-config';
import gridConfig from './grid-config';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../hooks/trace-update-hook';

import '../../theme/ag-grid-alpine-dark-component.scss';
import styles from './grid-component.module.css';

LicenseManager.setLicenseKey(appConfig.agGrid.licence);

function GridComponent({
  stateOrderUpdate,
  // stateOrderHistory,
  retrieveErrorAction,
  removeOrderUpdateAction,
}) {
  const config = gridConfig({
    deleteHandler: removeOrderUpdateAction,
    retrieveErrorAction,
  });

  // TODO - Use Presenter
  const { retrieveResult: rowData } = stateOrderUpdate;
  const { oktaAuth } = useOktaAuth();

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   stateOrderUpdate,
  //   stateOrderHistory,
  // });

  const detailCellRendererParams = useMemo(() => {
    return () => {
      return { oktaAuth, retrieveErrorAction };
    };
  }, []);

  return (
    <div className={clsx(styles.container, 'ag-theme-alpine-dark')}>
      <AgGridReact
        // properties
        // debug
        columnDefs={config.columnDefs}
        animateRows
        rowData={rowData}
        rowSelection="multiple"
        modules={AllModules}
        defaultColDef={config.defaultColDef}
        domLayout="normal"
        autoSizePadding={4}
        masterDetail
        immutableData
        getRowNodeId={(data) => data.internalId}
        detailCellRenderer="customDetailCellRenderer"
        frameworkComponents={{ customDetailCellRenderer: OrderHistoryRenderer }}
        detailCellRendererParams={detailCellRendererParams}
        // detailCellRendererParams={config.detailCellRendererParams}
        // events
        onFirstDataRendered={config.onFirstDataRendered}
        onGridSizeChanged={config.onGridSizeChanged}
        getRowHeight={config.getRowHeight}
        // onSelectionChanged={(event) => {
        //   setSelectedRows(event.api.getSelectedNodes().map((node) => node));
        // }}
      />
    </div>
  );
}

GridComponent.propTypes = {
  stateOrderUpdate: PropTypes.shape({
    retrieveResult: PropTypes.arrayOf(propTypeOrderUpdate),
  }),
  // stateOrderHistory: PropTypes.shape({
  //   retrieveResult: PropTypes.objectOf(PropTypes.arrayOf(propTypeOrderUpdate)),
  // }),
  removeOrderUpdateAction: PropTypes.func.isRequired,
  retrieveErrorAction: PropTypes.func.isRequired,
};

GridComponent.defaultProps = {
  stateOrderUpdate: { retrieveResult: [] },
  // stateOrderHistory: { retrieveResult: {} },
};

export default GridComponent;
