import { ArrayModel } from 'objectmodel';

import entityPresetParent from './defs/entity-preset-parent';
import PresetType from './PresetType';
import SealedModel from '../../helpers/SealedModel';

const Preset = SealedModel({
  ...entityPresetParent,
  types: new ArrayModel([PresetType]),
}).defaultTo({
  isActive: false,
});

export default Preset;
