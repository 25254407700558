import React from 'react';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const SWITCH_HEIGHT = 24;

const OnOffSwitch = withStyles((theme) => ({
  root: {
    width: 72,
    height: SWITCH_HEIGHT,
    padding: 2,
    margin: theme.spacing(0),
    alignSelf: 'center',
    overflow: 'visible',
    verticalAlign: 'initial',
  },
  switchBase: {
    padding: 5,
    '&$checked': {
      transform: 'translateX(46px)',
      '& + $track': {
        backgroundColor: 'white',
        borderColor: theme.palette.primary.dark,
        opacity: 1,
        '&::before': {
          content: '"On"',
          display: 'block',
          height: SWITCH_HEIGHT,
          marginTop: -4,
          marginLeft: 5,
        },
        '&::after': {
          content: '"Off"',
          display: 'block',
          color: 'transparent',
          height: SWITCH_HEIGHT,
          marginTop: -4,
          marginLeft: 48,
        },
      },
    },
  },
  thumb: {
    width: SWITCH_HEIGHT - 8,
    height: SWITCH_HEIGHT - 8,
    color: theme.palette.primary.dark,
  },
  track: {
    borderRadius: SWITCH_HEIGHT / 2,
    border: `1.3px solid ${'gray'}`,
    backgroundColor: 'white',
    opacity: 1,
    color: theme.palette.primary.dark,
    display: 'block',
    fontSize: 10,
    transition: theme.transitions.create(['background-color']),
    '&::after': {
      content: '"Off"',
      display: 'block',
      height: SWITCH_HEIGHT,
      marginTop: -4,
      marginLeft: 46,
    },
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
});

export default OnOffSwitch;
