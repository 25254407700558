import React, { useRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

import FormSelect from '../../FormSelect';
import FormInput from '../../FormInput';
import FormSwitch from '../../FormSwitch';
import FormNumberInput from '../../FormNumberInput';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../../hooks/trace-update-hook';

import presenter from './presenter';

import propDefaultApiGet from '../../../prop-defaults/api-get';
import propTypeProOrganisation from '../../../prop-types/shadow-pro-organisation';
import propTypeShadowStream from '../../../prop-types/shadow-stream';
import propTypeShadowGroup from '../../../prop-types/shadow-group';

import formValidation from '../../../shared/form-validation';

const ID_CTRL_GROUP_NAME = 'shadow-strategy-param-group-name-input';
const ID_CTRL_PRO_ORGANISATION = 'shadow-strategy-param-reporting-org-select';
const ID_CTRL_AUTOMATED_STREAM =
  'shadow-strategy-param-automated-stream-select';
const ID_CTRL_MANUAL_STREAM = 'shadow-strategy-param-manual-stream-select';
const ID_CTRL_EXECUTION_DELAY = 'shadow-strategy-param-execution-delay-input';
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  secondRow: {
    textAlign: 'right',
  },
}));

function GroupForm({
  retrieveProOrganisationAction,
  retrieveStreamAction,
  createShadowGroupAction,
  updateShadowGroupAction,
  cmdGroupAction,
  stateShadowGroup,
  stateShadowProOrganisation,
  stateShadowStream,
  shadowGroupSelectionChangeHandler,
  stateGroupSelected,
  defaultGroupSelection,
}) {
  const classes = useStyles();
  const present = presenter({
    ID_CTRL_GROUP_NAME,
    ID_CTRL_PRO_ORGANISATION,
    ID_CTRL_AUTOMATED_STREAM,
    ID_CTRL_MANUAL_STREAM,
    ID_CTRL_EXECUTION_DELAY,
  });

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   retrieveProOrganisationAction,
  //   retrieveStreamAction,
  //   createShadowGroupAction,
  //   updateShadowGroupAction,
  //   cmdGroupAction,
  //   stateShadowGroup,
  //   stateShadowProOrganisation,
  //   stateShadowStream,
  //   shadowGroupSelectionChangeHandler,
  //   stateGroupSelected,
  //   defaultGroupSelection,
  // });

  const initialFormState = useRef(present.initialFormState);

  const [ctrlState, setCtrlState] = useState(initialFormState.current);
  const [isChecked, setIsChecked] = useState(true);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [selectedGroupStatus, setSelectedGroupStatus] = useState(null);
  const [initialSelectedGroup, setInitialSelectedGroup] = useState(null);
  const [proOrgSelectOptions, setProOrgSelectOptions] = useState(null);

  useEffect(() => {
    if (
      stateGroupSelected === '' ||
      stateGroupSelected === defaultGroupSelection
    ) {
      setIsUpdateMode(() => false);
      // reset form and related state
      setInitialSelectedGroup(null);
      setCtrlState(initialFormState.current);
      setIsChecked(true);
    } else if (
      stateGroupSelected !== '' &&
      stateGroupSelected !== defaultGroupSelection
    ) {
      setIsUpdateMode(() => true);
    }
  }, [isUpdateMode, defaultGroupSelection, stateGroupSelected]);

  const manageCtrlState = (name, value) => {
    const def = { ...ctrlState[name] };
    const { validators } = def;
    const errors = formValidation.checkValidity(value, validators);

    setCtrlState({
      ...ctrlState,
      [name]: {
        ...def,
        isTouched: true,
        isError: !!errors.length,
        value,
      },
    });
  };

  const makeCtrlHaveIsErrorState = (name) => {
    const def = { ...ctrlState[name] };

    setCtrlState({
      ...ctrlState,
      [name]: {
        ...def,
        isError: true,
      },
    });
  };

  const validateCtrlState = (name) => {
    const def = { ...ctrlState[name] };
    const { value, validators } = def;
    const errors = formValidation.checkValidity(value, validators);

    return {
      [name]: {
        ...def,
        isError: !!errors.length,
      },
    };
  };

  const prepProOrgUnallocatedSelectOptions = useCallback(
    present.prepProOrgUnallocatedSelectOptions,
    []
  );

  const prepProOrgSelectOptions = useCallback(
    present.prepProOrgSelectOptions,
    []
  );

  const handleInputUpdate = (e) => {
    const { id, value } = e.target;
    manageCtrlState(id, value);
  };
  const handleSelectUpdate = (e) => {
    const { name, value } = e.target;
    manageCtrlState(name, value);
  };
  const handleSwitchChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = Object.keys(ctrlState).reduce((acc, cur) => {
      return [
        ...acc,
        ...formValidation.checkValidity(
          ctrlState[cur].value,
          ctrlState[cur].validators
        ),
      ];
    }, []);

    if (errors.length) {
      setCtrlState(
        Object.keys(ctrlState).reduce((acc, cur) => {
          return {
            ...acc,
            ...validateCtrlState(cur),
          };
        }, {})
      );
    } else if (!isUpdateMode) {
      // Biz rule
      let isDuplicate = false;
      if (
        stateShadowGroup &&
        stateShadowGroup.retrieveResult &&
        stateShadowGroup.retrieveResult.data &&
        stateShadowGroup.retrieveResult.data.length
      ) {
        for (
          let i = 0;
          i < stateShadowGroup.retrieveResult.data.length;
          i += 1
        ) {
          if (
            stateShadowGroup.retrieveResult.data[i].name ===
            ctrlState[ID_CTRL_GROUP_NAME].value
          ) {
            isDuplicate = true;
            makeCtrlHaveIsErrorState(ID_CTRL_GROUP_NAME);
            break;
          }
        }
      }
      if (!isDuplicate) {
        await createShadowGroupAction({
          name: ctrlState[ID_CTRL_GROUP_NAME].value,
          reportingOrg: ctrlState[ID_CTRL_PRO_ORGANISATION].value,
          automatedStreamId: ctrlState[ID_CTRL_AUTOMATED_STREAM].value,
          manualStreamId: ctrlState[ID_CTRL_MANUAL_STREAM].value,
          executionDelayMillis: ctrlState[ID_CTRL_EXECUTION_DELAY].value,
          enabled: isChecked ? 1 : 0,
          status: 'offline',
        });
        // Send 'insert' cmd for Vision to REST API
        cmdGroupAction({
          groupName: ctrlState[ID_CTRL_GROUP_NAME].value,
          action: 'insert',
        });
        shadowGroupSelectionChangeHandler(ctrlState[ID_CTRL_GROUP_NAME].value);
      }
    } else {
      await updateShadowGroupAction({
        name: ctrlState[ID_CTRL_GROUP_NAME].value,
        reportingOrg: ctrlState[ID_CTRL_PRO_ORGANISATION].value,
        automatedStreamId: ctrlState[ID_CTRL_AUTOMATED_STREAM].value,
        manualStreamId: ctrlState[ID_CTRL_MANUAL_STREAM].value,
        executionDelayMillis: ctrlState[ID_CTRL_EXECUTION_DELAY].value,
        enabled: isChecked ? 1 : 0,
        status: selectedGroupStatus,
      });
      // Send 'update' cmd for Vision to REST API
      cmdGroupAction({
        groupName: ctrlState[ID_CTRL_GROUP_NAME].value,
        action: 'update',
      });
    }
  };

  useEffect(() => {
    if (isUpdateMode) {
      setProOrgSelectOptions(
        prepProOrgSelectOptions(stateShadowProOrganisation)
      );
    } else if (!isUpdateMode) {
      setProOrgSelectOptions(
        prepProOrgUnallocatedSelectOptions(
          stateShadowProOrganisation,
          stateShadowGroup
        )
      );
    }
  }, [
    isUpdateMode,
    stateShadowGroup,
    stateShadowProOrganisation,
    prepProOrgSelectOptions,
    prepProOrgUnallocatedSelectOptions,
  ]);

  useEffect(() => {
    if (
      isUpdateMode &&
      stateGroupSelected &&
      stateShadowGroup &&
      stateShadowGroup.retrieveResult &&
      stateShadowGroup.retrieveResult.data &&
      !stateShadowGroup.isUpdating &&
      !stateShadowGroup.isCreating &&
      !stateShadowGroup.isCmdInProgress
    ) {
      setInitialSelectedGroup(
        stateShadowGroup.retrieveResult.data.reduce((acc, cur) => {
          if (cur.name === stateGroupSelected) {
            return { ...acc, ...cur };
          }
          return acc;
        }, {})
      );
    }
  }, [isUpdateMode, stateGroupSelected, stateShadowGroup]);

  useEffect(() => {
    function retrieveFromApi() {
      retrieveProOrganisationAction();
      retrieveStreamAction();
    }
    retrieveFromApi();
  }, [retrieveProOrganisationAction, retrieveStreamAction]);
  useEffect(() => {
    if (
      !isUpdateMode &&
      stateShadowGroup &&
      stateShadowGroup.retrieveResult &&
      stateShadowGroup.retrieveResult.data &&
      !stateShadowGroup.isUpdating &&
      !stateShadowGroup.isCreating &&
      !stateShadowGroup.isCmdInProgress
    ) {
      // INSERT
      // eslint-disable-next-line no-console
      // console.log('INSERT');
      setCtrlState((state) => {
        return Object.keys(state).reduce((acc, cur) => {
          const def = { ...state[cur] };
          let obj = {};
          switch (cur) {
            case ID_CTRL_PRO_ORGANISATION:
              obj = {
                options: proOrgSelectOptions,
              };

              break;
            default:
              break;
          }
          return {
            ...acc,
            [cur]: {
              ...def,
              ...obj,
            },
          };
        }, {});
      });
    } else if (
      isUpdateMode &&
      stateGroupSelected &&
      stateShadowGroup &&
      stateShadowGroup.retrieveResult &&
      stateShadowGroup.retrieveResult.data &&
      !stateShadowGroup.isUpdating &&
      !stateShadowGroup.isCreating &&
      !stateShadowGroup.isCmdInProgress &&
      stateGroupSelected !== '' &&
      stateGroupSelected !== defaultGroupSelection
    ) {
      // UPDATE
      // eslint-disable-next-line no-console
      // console.log('UPDATE');

      const currentAppState = stateShadowGroup.retrieveResult.data.reduce(
        (acc, cur) => {
          if (cur.name === stateGroupSelected) {
            return { ...acc, ...cur };
          }
          return acc;
        },
        {}
      );
      setSelectedGroupStatus(currentAppState.status);
      setIsChecked(!!currentAppState.enabled);
      setCtrlState((state) => {
        return Object.keys(state).reduce((acc, cur) => {
          const def = { ...state[cur] };
          let obj = {};
          switch (cur) {
            case ID_CTRL_GROUP_NAME:
              obj = {
                value: currentAppState.name,
                isDisabled: true,
                isError: false,
              };

              break;
            case ID_CTRL_PRO_ORGANISATION:
              obj = {
                options: proOrgSelectOptions,
                value: currentAppState.reportingOrg,
                isDisabled: true,
                isError: false,
              };

              break;
            case ID_CTRL_AUTOMATED_STREAM:
              obj = {
                value: currentAppState.automatedStreamId,
                isError: false,
              };

              break;
            case ID_CTRL_MANUAL_STREAM:
              obj = {
                value: currentAppState.manualStreamId,
                isError: false,
              };

              break;
            case ID_CTRL_EXECUTION_DELAY:
              obj = {
                value: currentAppState.executionDelayMillis,
                isError: false,
              };

              break;
            default:
              break;
          }
          return {
            ...acc,
            [cur]: {
              ...def,
              ...obj,
            },
          };
        }, {});
      });
    }
  }, [
    isUpdateMode,
    stateGroupSelected,
    stateShadowGroup,
    initialFormState,
    initialSelectedGroup,
    proOrgSelectOptions,
    defaultGroupSelection,
  ]);

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12} lg={3}>
            <FormInput
              value={ctrlState[ID_CTRL_GROUP_NAME].value}
              error={ctrlState[ID_CTRL_GROUP_NAME].isError}
              disabled={ctrlState[ID_CTRL_GROUP_NAME].isDisabled}
              required={ctrlState[ID_CTRL_GROUP_NAME].isRequired}
              changeHandler={handleInputUpdate}
              blurHandler={handleInputUpdate}
              helperText="Enter group name"
              labelText="Group Name *"
              margin="dense"
              fullWidth
              elementId={ID_CTRL_GROUP_NAME}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormSelect
              selected={
                (ctrlState[ID_CTRL_PRO_ORGANISATION].options &&
                  ctrlState[ID_CTRL_PRO_ORGANISATION].options.find(
                    (option) =>
                      option.value === ctrlState[ID_CTRL_PRO_ORGANISATION].value
                  ) &&
                  ctrlState[ID_CTRL_PRO_ORGANISATION].value) ||
                ''
              }
              options={ctrlState[ID_CTRL_PRO_ORGANISATION].options}
              error={ctrlState[ID_CTRL_PRO_ORGANISATION].isError}
              disabled={ctrlState[ID_CTRL_PRO_ORGANISATION].isDisabled}
              required={ctrlState[ID_CTRL_PRO_ORGANISATION].isRequired}
              changeHandler={handleSelectUpdate}
              blurHandler={handleSelectUpdate}
              defaultLabel=""
              defaultValue=""
              helperText="Select a reporting org"
              labelText="Reporting Orgs *"
              margin="dense"
              fullWidth
              elementId={ID_CTRL_PRO_ORGANISATION}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormSelect
              selected={
                (present
                  .prepStreamSelectOptions(stateShadowStream)
                  .find(
                    (option) =>
                      option.value === ctrlState[ID_CTRL_AUTOMATED_STREAM].value
                  ) &&
                  ctrlState[ID_CTRL_AUTOMATED_STREAM].value) ||
                ''
              }
              // selected={ctrlState[ID_CTRL_AUTOMATED_STREAM].value}
              options={present.prepStreamSelectOptions(stateShadowStream)}
              error={ctrlState[ID_CTRL_AUTOMATED_STREAM].isError}
              disabled={ctrlState[ID_CTRL_AUTOMATED_STREAM].isDisabled}
              required={ctrlState[ID_CTRL_AUTOMATED_STREAM].isRequired}
              changeHandler={handleSelectUpdate}
              blurHandler={handleSelectUpdate}
              defaultLabel=""
              defaultValue=""
              helperText="Select automated stream"
              labelText="Automated Streams *"
              margin="dense"
              fullWidth
              elementId={ID_CTRL_AUTOMATED_STREAM}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormSelect
              selected={
                (present
                  .prepStreamSelectOptions(stateShadowStream)
                  .find(
                    (option) =>
                      option.value === ctrlState[ID_CTRL_MANUAL_STREAM].value
                  ) &&
                  ctrlState[ID_CTRL_MANUAL_STREAM].value) ||
                ''
              }
              // selected={ctrlState[ID_CTRL_MANUAL_STREAM].value}
              options={present.prepStreamSelectOptions(stateShadowStream)}
              error={ctrlState[ID_CTRL_MANUAL_STREAM].isError}
              disabled={ctrlState[ID_CTRL_MANUAL_STREAM].isDisabled}
              required={ctrlState[ID_CTRL_MANUAL_STREAM].isRequired}
              changeHandler={handleSelectUpdate}
              blurHandler={handleSelectUpdate}
              defaultLabel=""
              defaultValue=""
              helperText="Select manual stream"
              labelText="Manual Streams *"
              margin="dense"
              fullWidth
              elementId={ID_CTRL_MANUAL_STREAM}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormNumberInput
              value={ctrlState[ID_CTRL_EXECUTION_DELAY].value}
              error={ctrlState[ID_CTRL_EXECUTION_DELAY].isError}
              disabled={ctrlState[ID_CTRL_EXECUTION_DELAY].isDisabled}
              required={ctrlState[ID_CTRL_EXECUTION_DELAY].isRequired}
              changeHandler={handleInputUpdate}
              blurHandler={handleInputUpdate}
              isAllowed={ctrlState[ID_CTRL_EXECUTION_DELAY].validator}
              helperText="Enter execution delay (ms)"
              labelText="Execution Delay (ms) *"
              margin="dense"
              fullWidth
              decimalScale={0}
              allowNegative={false}
              allowLeadingZeros={false}
              elementId={ID_CTRL_EXECUTION_DELAY}
            />
          </Grid>

          <Grid item xs={12} className={classes.secondRow}>
            <FormSwitch
              name="isGroupEnabled"
              isChecked={isChecked}
              changeHandler={handleSwitchChange}
              labelText="Enabled"
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              disabled={present.isSubmitDisabled(
                ctrlState,
                isUpdateMode,
                initialSelectedGroup,
                isChecked
              )}
            >
              Save Group
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

GroupForm.propTypes = {
  stateShadowGroup: propTypeShadowGroup,
  stateShadowProOrganisation: propTypeProOrganisation,
  stateShadowStream: propTypeShadowStream,
  stateGroupSelected: PropTypes.string,
  defaultGroupSelection: PropTypes.string.isRequired,
  retrieveProOrganisationAction: PropTypes.func.isRequired,
  retrieveStreamAction: PropTypes.func.isRequired,
  createShadowGroupAction: PropTypes.func.isRequired,
  updateShadowGroupAction: PropTypes.func.isRequired,
  cmdGroupAction: PropTypes.func.isRequired,
  shadowGroupSelectionChangeHandler: PropTypes.func.isRequired,
};

GroupForm.defaultProps = {
  stateShadowGroup: propDefaultApiGet,
  stateShadowProOrganisation: propDefaultApiGet,
  stateShadowStream: propDefaultApiGet,
  stateGroupSelected: '',
};

export default GroupForm;
