import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ShadowStrategyGroupStrategyParamForm from '../../forms/ShadowStrategy/GroupStrategyParamForm';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  dialogTitle: {
    '& > h2': {
      fontSize: '1.5em',
    },
  },
}));

const buttonSuccessErrorTheme = (theme) => {
  return createTheme({
    palette: {
      primary: theme.palette.success,
      secondary: theme.palette.error,
    },
  });
};

function CreateShadowGroupStrategyDialog({
  isOpen,
  onCloseHandler,
  // onConfirmHandler,
  groupSelected,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [submitFn, setSubmitFn] = useState();
  const submitForm = () => {
    if (submitFn) {
      submitFn.current();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
        New group strategy definition
      </DialogTitle>
      <DialogContent>
        <ShadowStrategyGroupStrategyParamForm
          setSubmitFn={setSubmitFn}
          groupSelected={groupSelected}
          closeHandler={onCloseHandler}
        />
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={buttonSuccessErrorTheme}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ClearIcon />}
            onClick={onCloseHandler}
          >
            Abort
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CheckIcon />}
            type="submit"
            onClick={() => {
              // onCloseHandler();
              // onConfirmHandler();
              submitForm();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
}

CreateShadowGroupStrategyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  // onConfirmHandler: PropTypes.func.isRequired,
  groupSelected: PropTypes.string.isRequired,
};

export default CreateShadowGroupStrategyDialog;
