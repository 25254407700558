import dtoClient from '../infrastructure/dto/dto-client';

function clientRepository() {
  function dtoClientDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoClient(gatewayDataObject);
    });
  }

  return Object.freeze({
    dtoClientDataSourceInput,
  });
}

export default clientRepository;
