import React from 'react';
import PropTypes from 'prop-types';

import StatusIndicator from './StatusIndicator';
import ErrorMsg from './ErrorMsg';

function RealtimeStatus({
  isConnecting,
  isConnected,
  connectError,
  isDisconnecting,
  isDisconnected,
  disconnectError,
}) {
  let status = null;
  if (isConnecting || isDisconnecting || disconnectError) status = 'orange';
  if (isConnected) status = 'green';
  if (isDisconnected || connectError) status = 'red';
  if (status == null) status = 'red';
  return (
    <>
      <ErrorMsg connectError={connectError} disconnectError={disconnectError} />
      <StatusIndicator color={status} />
    </>
  );
}

RealtimeStatus.propTypes = {
  isConnecting: PropTypes.bool,
  isConnected: PropTypes.bool,
  connectError: PropTypes.string,
  isDisconnecting: PropTypes.bool,
  isDisconnected: PropTypes.bool,
  disconnectError: PropTypes.string,
};

RealtimeStatus.defaultProps = {
  isConnecting: null,
  isConnected: null,
  connectError: null,
  isDisconnecting: null,
  isDisconnected: null,
  disconnectError: null,
};

export default RealtimeStatus;
