import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, LicenseManager } from '@ag-grid-enterprise/all-modules';
import clsx from 'clsx';

import appConfig from '../../../app-config';
import gridConfig from '../grid-config';
import ApiClient from '../../../shared/api-client';

import propTypeOrderUpdate from '../../../prop-types/order-update';

import '../../../theme/ag-grid-alpine-dark-component.scss';
import styles from './order-history.module.css';

LicenseManager.setLicenseKey(appConfig.agGrid.licence);

class RenderOrderHistory extends Component {
  constructor(props) {
    super(props);

    const { rowIndex, api, oktaAuth, retrieveErrorAction } = this.props;
    this.config = gridConfig();
    this.apiClient = new ApiClient(oktaAuth);
    this.onGridReady = this.onGridReady.bind(this);
    this.getData = this.getData.bind(this);
    this.createDetailGridId = this.createDetailGridId.bind(this);
    this.retrieveErrorAction = retrieveErrorAction;

    this.state = {
      defaultColDef: {
        ...this.config.sharedDefaultColDefs,
      },
      columnDefs: [
        {
          headerName: 'Modified Time',
          field: 'timeModified',
          minWidth: 180,
          valueFormatter: this.config.dateTimeFormatter,
        },
        ...this.config.sharedColDefs,
      ],
      rowIndex,
      masterGridApi: api,
      rowData: null,
    };
  }

  componentWillUnmount() {
    const { masterGridApi } = this.state;
    const detailGridId = this.createDetailGridId();

    // ag-Grid is automatically destroyed
    masterGridApi.removeDetailGridInfo(detailGridId);
  }

  onGridReady(params) {
    const { masterGridApi } = this.state;
    const { data: masterData } = this.props;
    const detailGridId = this.createDetailGridId();

    const gridInfo = {
      id: detailGridId,
      api: params.api,
      columnApi: params.columnApi,
    };

    masterGridApi.addDetailGridInfo(detailGridId, gridInfo);

    this.getData(masterData.internalId);
  }

  // TODO: this should be using API client middleware
  getData(identifier) {
    this.apiClient
      .get(`/orders/history/${identifier}`)
      .then((response) => {
        if (
          response &&
          response.data &&
          Array.isArray(response.data) &&
          response.data.length
        ) {
          this.setState({
            rowData: response.data,
          });
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error('detail grid: fetch order history error', e);
        this.retrieveErrorAction(e);
      });
  }

  createDetailGridId() {
    const { rowIndex } = this.state;
    return `detail_${rowIndex}`;
  }

  render() {
    const { columnDefs, defaultColDef, rowData } = this.state;
    return (
      <div className="ag-details-row">
        <div
          className={clsx(
            styles.container,
            'ag-details-grid ag-theme-alpine-dark'
          )}
        >
          <AgGridReact
            // properties
            // debug
            id="detailGrid"
            className="ag-theme-alpine-dark"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rowData}
            getRowNodeId={(data) => data.meta.id}
            modules={AllModules}
            // events
            onGridReady={this.onGridReady}
            onFirstDataRendered={this.config.onFirstDataRendered}
          />
        </div>
      </div>
    );
  }
}

RenderOrderHistory.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  api: PropTypes.shape({
    addDetailGridInfo: PropTypes.func.isRequired,
    removeDetailGridInfo: PropTypes.func.isRequired,
  }).isRequired,
  data: propTypeOrderUpdate.isRequired,
  oktaAuth: PropTypes.shape({
    getAccessToken: PropTypes.func.isRequired,
  }).isRequired,
  retrieveErrorAction: PropTypes.func.isRequired,
};

export default RenderOrderHistory;
