import { IO_ORDER_HISTORY } from './constants';

// Receive doesn't actualy receive messages.
// but prepares the socket to receive messages.
const RECEIVE = 'store/order-history/RECEIVE';
const RECEIVE_SUCCESS = 'store/order-history/RECEIVE_SUCCESS';
const RECEIVE_FAIL = 'store/order-history/RECEIVE_FAIL';

const ORDER_HISTORY = 'store/order-history/ORDER_HISTORY';

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case ORDER_HISTORY:
      return {
        ...state,
        retrieveResult: state.retrieveResult
          ? { ...state.retrieveResult, ...action.result }
          : action.result,
      };
    default:
      return state;
  }
}

export function receive() {
  return (dispatch) => {
    const newMessage = (message) => {
      return dispatch({
        type: ORDER_HISTORY,
        result: message,
      });
    };

    return dispatch({
      type: 'socket',
      types: [RECEIVE, RECEIVE_SUCCESS, RECEIVE_FAIL],
      promise: (socket) => socket.on(IO_ORDER_HISTORY, newMessage),
    });
  };
}
