import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

import ConfirmDialog from '../../ConfirmDialog';

const styles = (theme) => ({
  smallButton: {
    paddingBottom: '0.2em',
  },
  smallIcon: {
    fontSize: '1.1em',
  },
  destroy: {
    color: theme.palette.error.main,
  },
});

class DeleteRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleToggleOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  handleDeleteRecord = () => {
    const { deleteRowHandler } = this.props;

    deleteRowHandler();
  };

  render() {
    const {
      classes,
      disabled,
      confirmDialogTitle,
      confirmDialogActionLabel,
      confirmDialogEntityLabel,
      selectedRowsCount,
      selectedRowsCounter,
    } = this.props;
    const { open } = this.state;

    return (
      <>
        <IconButton
          className={classes.smallButton}
          color="secondary"
          aria-label="cancel"
          size="small"
          variant="contained"
          classes={{ colorSecondary: classes.destroy }}
          disabled={disabled}
          onClick={this.handleToggleOpen}
        >
          <CancelPresentationIcon className={classes.smallIcon} />
        </IconButton>
        <ConfirmDialog
          isOpen={open}
          title={confirmDialogTitle}
          actionLabel={confirmDialogActionLabel}
          entityLabel={confirmDialogEntityLabel}
          onCloseHandler={this.handleToggleOpen}
          onConfirmHandler={() => this.handleDeleteRecord()}
          selectedRowsCount={
            selectedRowsCounter ? selectedRowsCounter() : selectedRowsCount
          }
        />
      </>
    );
  }
}

DeleteRow.propTypes = {
  confirmDialogTitle: PropTypes.string,
  confirmDialogActionLabel: PropTypes.string,
  confirmDialogEntityLabel: PropTypes.string,
  selectedRowsCount: PropTypes.number,
  classes: PropTypes.shape({
    smallButton: PropTypes.string.isRequired,
    smallIcon: PropTypes.string.isRequired,
    destroy: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  deleteRowHandler: PropTypes.func.isRequired,
  selectedRowsCounter: PropTypes.func,
};

DeleteRow.defaultProps = {
  confirmDialogTitle: '',
  confirmDialogActionLabel: '',
  confirmDialogEntityLabel: '',
  selectedRowsCount: 1,
  selectedRowsCounter: null,
  disabled: false,
};

export default withStyles(styles)(DeleteRow);
