import dtoSystemAlertMsg from '../infrastructure/dto/dto-system-alert-msg';

function systemAlertRepository() {
  function dtoSystemAlertMsgDataSourceInput(gatewayDataObject) {
    if (typeof gatewayDataObject !== 'object')
      throw new TypeError('expects an object');
    return dtoSystemAlertMsg(gatewayDataObject);
  }

  return Object.freeze({
    dtoSystemAlertMsgDataSourceInput,
  });
}

export default systemAlertRepository;
