import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(1),
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  inputLabel: {
    padding: theme.spacing(1) - 2,
  },
  multipleSelect: {
    '& select[multiple]': {
      maxHeight: '500px',
      backgroundColor: theme.palette.background.default,
    },
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function FormSelectMuliple({
  options,
  onChangeHandler,
  onKeyDownHandler,
  onKeyUpHandler,
  onClickCaptureHandler,
  onFocusHandler,
  onBlurHandler,
  value,
  elementId,
  labelText,
  selectStyle,
}) {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink htmlFor={elementId} className={classes.inputLabel}>
        {labelText}
      </InputLabel>
      <Select
        multiple
        native
        value={value}
        label={labelText}
        className={classes.multipleSelect}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        onKeyUp={onKeyUpHandler}
        onClickCapture={onClickCaptureHandler}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        id={elementId}
        inputProps={{
          style: selectStyle,
        }}
      >
        {options.map((item) => (
          <option key={item.id} label={item.name} value={item.id}>
            {item.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

FormSelectMuliple.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  labelText: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  selectStyle: PropTypes.shape({ height: PropTypes.string }),
  onChangeHandler: PropTypes.func.isRequired,
  onClickCaptureHandler: PropTypes.func,
  onKeyDownHandler: PropTypes.func,
  onKeyUpHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
};

FormSelectMuliple.defaultProps = {
  options: [],
  selectStyle: { height: 'auto' },
  onClickCaptureHandler: null,
  onKeyDownHandler: null,
  onKeyUpHandler: null,
  onFocusHandler: null,
  onBlurHandler: null,
};

export default FormSelectMuliple;
