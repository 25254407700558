import ValTypeAlphaNumDash from '../../../ValTypeAlphaNumDash';

const entityPresetParent = {
  id: [Number],
  name: ValTypeAlphaNumDash,
  group: [ValTypeAlphaNumDash],
  instrument: [String],
  isActive: [Boolean],
};

export default entityPresetParent;
