import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Drawer,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { Create } from '@material-ui/icons';
import { ErrorBoundary } from '@sentry/react';

import presenter from './presenter';

import propTypePriceControlPreset from '../../prop-types/price-control-preset';

import Loading from '../../components/Loading';
import VirtualizedTable from './VirtualizedTable';
import CreatePresetForm from '../../forms/PriceControls/CreatePresetForm';
import FormIconButton from '../../forms/FormIconButton';
import Controls from './Controls';
import Fallback from '../../components/Fallback';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: '100%',
  },
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    margin: 0,
    padding: theme.spacing(1),
  },
  gridMain: {
    flex: '1 1 100%',
  },
  cardHeader: {
    padding: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  cardHeaderAction: {
    marginTop: '0px',
  },
  cardContent: {
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  cardTableContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '4px',
    },
  },
  cardTable: {
    // height: '100%',
    flex: '1 1 100%',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
    },
  },
  tableHeader: {
    // backgroundColor: theme.palette.background.brandSecondary,
    backgroundColor: '#f6f7fa',
    borderBottome: 'solid 1px rgb(24, 29, 31)',
  },
  tableRow: {
    // backgroundColor: theme.palette.background.brandPrimary,
    backgroundColor: '#fcfcfc',
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
    fontSize: '0.75rem',
    textAlign: 'center',
    lineHeight: '0.9rem',
  },
  tableCellPositive: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  tableCellNegative: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  noClick: {
    cursor: 'initial',
  },
  tableNoRows: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.hint,
  },
}));

function PriceControls({
  retrievePriceControlPresetsAction,
  statePriceControlPresets,
}) {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const present = presenter();

  const retrieveResultData =
    statePriceControlPresets &&
    statePriceControlPresets.retrieveResult &&
    statePriceControlPresets.retrieveResult.data
      ? statePriceControlPresets.retrieveResult.data
      : [];

  const tableRows = present.presetsWithTypesPivot({
    data: retrieveResultData,
  });

  const toggleDrawerHandler = (isOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpen(isOpen);
  };

  useEffect(() => {
    function retrieveFromApi() {
      retrievePriceControlPresetsAction().then(() => setIsLoaded(true));
    }
    retrieveFromApi();
  }, [retrievePriceControlPresetsAction]);

  return isLoaded ? (
    <ErrorBoundary fallback={Fallback}>
      <div className={classes.root}>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item container spacing={1} className={classes.gridMain}>
            <Grid item xs={12} md={9} lg={6}>
              <Card className={classes.cardTableContainer}>
                <CardHeader
                  title="Applied Presets"
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  className={classes.cardHeader}
                  classes={{ action: classes.cardHeaderAction }}
                  action={
                    <FormIconButton
                      id="toggle-context-drawer-button"
                      variant="outlined"
                      color="primary"
                      size="small"
                      icon={<Create />}
                      onClick={toggleDrawerHandler(true)}
                    >
                      Create
                    </FormIconButton>
                  }
                />

                <Divider />
                <CardContent
                  className={clsx(classes.cardContent, classes.cardTable)}
                >
                  <VirtualizedTable
                    rowCount={tableRows.length}
                    rowGetter={({ index }) => tableRows[index]}
                    rows={tableRows}
                    classes={{
                      flexContainer: classes.flexContainer,
                      table: classes.table,
                      tableHeader: classes.tableHeader,
                      tableRow: classes.tableRow,
                      tableRowHover: classes.tableRowHover,
                      tableCell: classes.tableCell,
                      tableCellPositive: classes.tableCellPositive,
                      tableCellNegative: classes.tableCellNegative,
                      tableNoRows: classes.tableNoRows,
                      noClick: classes.noClick,
                    }}
                    sortColumnsBy={['name', 'instrument']}
                    sortColumnsDirection={{
                      name: 'ASC',
                      instrument: 'ASC',
                    }}
                    headerHeight={29}
                    rowHeight={29}
                    columns={[
                      {
                        width: 120,
                        label: 'Name',
                        dataKey: 'name',
                      },
                      {
                        width: 100,
                        label: 'Instrument',
                        dataKey: 'instrument',
                      },
                      {
                        width: 105,
                        label: 'Spread Bid',
                        dataKey: 'spreadBid',
                        numeric: true,
                      },
                      {
                        width: 105,
                        label: 'Spread Ask',
                        dataKey: 'spreadAsk',
                        numeric: true,
                      },
                      {
                        width: 105,
                        label: 'Skew Bid',
                        dataKey: 'skewBid',
                        numeric: true,
                      },
                      {
                        width: 105,
                        label: 'Skew Ask',
                        dataKey: 'skewAsk',
                        numeric: true,
                      },
                      {
                        width: 105,
                        label: 'Post Bid',
                        dataKey: 'postBid',
                        numeric: true,
                      },
                      {
                        width: 105,
                        label: 'Post Ask',
                        dataKey: 'postAsk',
                        numeric: true,
                      },
                    ]}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={3} lg={6}>
              <Controls statePriceControlPresets={statePriceControlPresets} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawerHandler(false)}
      >
        <CreatePresetForm
          formHeading="Quick Preset"
          postSubmitCleanupHandler={({ isShowForm = false } = {}) =>
            setIsDrawerOpen(isShowForm)
          }
          statePresetNames={retrieveResultData.map((preset) => preset.name)}
        />
      </Drawer>
    </ErrorBoundary>
  ) : (
    <Loading />
  );
}

PriceControls.propTypes = {
  statePriceControlPresets: propTypePriceControlPreset,
  retrievePriceControlPresetsAction: PropTypes.func.isRequired,
};

PriceControls.defaultProps = {
  statePriceControlPresets: null,
};
export default PriceControls;
