import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from '@material-ui/core';

import ClientGroup from './ClientGroup';
import CreateGroupForm from './CreateGroupForm';
import EditGroupForm from './EditGroupForm';

import presenter from './presenter';

import propDefaultApiGet from '../../prop-defaults/api-get';
import propTypeClientGroup from '../../prop-types/client-group';

const CREATE_NEW_GROUP_OPTION = 'Create New Group';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: '100%',
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    margin: 0,
    padding: theme.spacing(1),
  },
  gridMain: {
    width: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flex: '1 1 100%',
  },
  container: {},
  buttonsContainer: {
    textAlign: 'center',
    marginBottom: '1em',
  },
}));

const ID_CTRL_CLIENT_GROUP_SELECT =
  'client-group-management-form-client-group-select';
const handleGroupSelectChange = (fn) => (e) => {
  fn(e.target.value);
};

function ClientGroupManagement({
  clientGroupSelectionChangeAction,
  retrieveClientGroupAction,
  stateClientGroup,
}) {
  const classes = useStyles();
  const present = presenter({
    ID_CTRL_CLIENT_GROUP_SELECT,
  });

  useEffect(() => {
    function retrieveFromApi() {
      retrieveClientGroupAction();
    }
    retrieveFromApi();
  }, [retrieveClientGroupAction]);

  return (
    <div className={classes.root}>
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={12} lg={3} className={classes.gridMain}>
          <Card className={classes.cardContainer}>
            <CardHeader
              title="Selected Group"
              subheader="new or existing"
              titleTypographyProps={{ variant: 'subtitle1' }}
            />
            <Divider />
            <CardContent className={classes.content}>
              <ClientGroup
                groupSelectOptions={present.prepGroupSelectOptions(
                  stateClientGroup
                )}
                selectionChangeHandler={handleGroupSelectChange(
                  clientGroupSelectionChangeAction
                )}
                defaultGroupSelection={CREATE_NEW_GROUP_OPTION}
              />
              <Divider />
              <CreateGroupForm
                stateClientGroup={stateClientGroup}
                clientGroupSelectionChangeHandler={
                  clientGroupSelectionChangeAction
                }
                defaultGroupSelection={CREATE_NEW_GROUP_OPTION}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={9} className={classes.gridMain}>
          {present.isShowEditComp(
            CREATE_NEW_GROUP_OPTION,
            stateClientGroup.selected
          ) ? (
            <Card className={classes.card}>
              <CardHeader
                title="Manage"
                titleTypographyProps={{ variant: 'subtitle1' }}
                subheaderTypographyProps={{ variant: 'subtitle2' }}
              />
              <Divider />
              <CardContent className={classes.content}>
                <EditGroupForm />
              </CardContent>
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

ClientGroupManagement.propTypes = {
  stateClientGroup: propTypeClientGroup,
  clientGroupSelectionChangeAction: PropTypes.func.isRequired,
  retrieveClientGroupAction: PropTypes.func.isRequired,
};

ClientGroupManagement.defaultProps = {
  stateClientGroup: propDefaultApiGet,
};

export default ClientGroupManagement;
