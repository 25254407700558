import dtoShadowGroup from '../infrastructure/dto/dto-shadow-group';
import dtoShadowProOrganisation from '../infrastructure/dto-pro-organization';
import dtoShadowStream from '../infrastructure/dto-stream';
import dtoShadowParameter from '../infrastructure/dto-shadow-group-strategy';
import dtoShadowParameterUpdate from '../infrastructure/dto-shadow-group-strategy-update';
import dtoInstrument from '../infrastructure/dto-instrument';

function shadowStratParamRepository() {
  function dtoShadowGroupDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoShadowGroup(gatewayDataObject);
    });
  }

  function dtoShadowProOrganisationDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoShadowProOrganisation(gatewayDataObject);
    });
  }

  function dtoShadowStreamDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoShadowStream(gatewayDataObject);
    });
  }

  function dtoShadowParameterDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoShadowParameter(gatewayDataObject);
    });
  }

  function dtoShadowParameterUpdateDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoShadowParameterUpdate(gatewayDataObject);
    });
  }

  function dtoInstrumentDataSourceInput(gatewayDataCollection) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoInstrument(gatewayDataObject);
    });
  }

  return Object.freeze({
    dtoShadowGroupDataSourceInput,
    dtoShadowProOrganisationDataSourceInput,
    dtoShadowStreamDataSourceInput,
    dtoShadowParameterDataSourceInput,
    dtoShadowParameterUpdateDataSourceInput,
    dtoInstrumentDataSourceInput,
  });
}

export default shadowStratParamRepository;
