import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  iconOnly: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

function IconLabelButtons({
  id,
  icon,
  variant,
  color,
  size,
  classes,
  children,
  ariaLabel,
  disabled,
  onClick,
}) {
  const styles = useStyles();

  return (
    <div>
      <Button
        variant={variant}
        color={color}
        size={size}
        classes={
          children ? classes : { startIcon: styles.iconOnly, ...classes }
        }
        className={styles.button}
        startIcon={icon}
        disabled={disabled}
        aria-label={ariaLabel}
        onClick={onClick}
        id={id}
      >
        {children}
      </Button>
    </div>
  );
}

IconLabelButtons.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium']),
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  icon: PropTypes.element.isRequired,
  children: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

IconLabelButtons.defaultProps = {
  id: null,
  ariaLabel: '',
  children: null,
  variant: 'contained',
  color: 'default',
  size: 'medium',
  classes: null,
  disabled: false,
  onClick: null,
};

export default IconLabelButtons;
