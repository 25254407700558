import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import clsx from 'clsx';

import FormInput from '../../FormInput';
import FormNumberInput from '../../FormNumberInput';
import FormAutocomplete from '../../FormAutocomplete';
import FormSwitch from '../../FormSwitch';
import FormIconButton from '../../FormIconButton';

import propTypePriceControlPresetInstrument from '../../../prop-types/price-control-preset-instrument';
import propTypeClientGroup from '../../../prop-types/client-group';

import presenter from './presenter';

/*
Note issue #7 workaround: CSS injection order

Use 'classes' prop on Switch to apply required 'switchBase' & 'switchBaseRoot'
CSS otherwise missing/ignored due to CSS specificity downgrade, 
resulting from the use of 'injectFirst' in parent component tree
*/

const useStyles = makeStyles((theme) => ({
  root: {
    width: '300px',
    padding: '18px',
    overflowX: 'hidden',
  },
  switchBase: {
    top: 0,
    left: 0,
    color: '#fafafa',
    zIndex: 1,
    position: 'absolute',
    transition:
      'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  switchBaseRoot: {
    padding: '9px',
  },
  radioFormCtrlFieldset: {
    paddingLeft: '14px',
    paddingRight: '14px',
  },
  radioFormCtrlLegend: {
    marginBottom: '14px',
  },
  radioGroup: {
    justifyContent: 'center',
  },
  formSwitch: {
    textAlign: 'right',
  },
  formLoadingCtrlMsg: {
    textAlign: 'center',
    color: theme.palette.text.hint,
  },
  submitBtnContainer: {
    textAlign: 'center',
  },
  formControlLabel: {
    whiteSpace: 'nowrap',
  },
}));

const ID_CTRL_PRESET_NAME =
  'price-controls-quick-create-form-preset-name-input';
const ID_CTRL_PRESET_TYPE =
  'price-controls-quick-create-form-preset-type-radio';
const ID_CTRL_GROUP_NAME = 'price-controls-quick-create-form-group-name-select';
const ID_CTRL_INSTRUMENT = 'price-controls-quick-create-form-instrument-select';
const ID_CTRL_LEFT_SIDE = 'price-controls-quick-create-form-left-side-input';
const ID_CTRL_RIGHT_SIDE = 'price-controls-quick-create-form-right-side-input';

function CreatePresetForm({
  stateClientGroup,
  statePriceControlPresetInstrument,
  statePresetNames,
  formHeading,
  createPriceControlPresetAction,
  retrievePriceControlPresetInstrumentAction,
  retrieveClientGroupAction,
  postSubmitCleanupHandler,
}) {
  const classes = useStyles();
  const present = presenter({
    ID_CTRL_PRESET_NAME,
    ID_CTRL_PRESET_TYPE,
    ID_CTRL_GROUP_NAME,
    ID_CTRL_INSTRUMENT,
    ID_CTRL_LEFT_SIDE,
    ID_CTRL_RIGHT_SIDE,
  });
  const initialFormState = useRef(present.initialFormState);
  const [ctrlState, setCtrlState] = useState(initialFormState.current);
  const [isChecked, setIsChecked] = useState(false);
  const [isAddAnotherChecked, setIsAddAnotherChecked] = useState(false);

  const handleRadioGroupChange = (e) => {
    const { value } = e.target;
    setCtrlState(
      present.manageCtrlState({
        state: ctrlState,
        name: ID_CTRL_PRESET_TYPE,
        value,
      })
    );
  };

  const handleInputUpdate = (e) => {
    const { id, value } = e.target;
    setCtrlState(
      present.manageCtrlState({ state: ctrlState, name: id, value })
    );
  };

  const handleAutocompleteChange = (identifier, propName) => (e, selected) => {
    setCtrlState(
      present.replaceValueCtrlStateSliceProp({
        state: ctrlState,
        identifier,
        propName,
        value: selected,
      })
    );
  };

  const handleSwitchChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleCheckboxChange = (e) => {
    setIsAddAnotherChecked(e.target.checked);
  };

  const handleSavePreset = async () => {
    const validatedState = present.validateCtrls(ctrlState);

    const bizRuleErrorCtrlIds = present.validateBizRules({
      formState: ctrlState,
      presetNames: statePresetNames,
    });

    // save errors to state:
    setCtrlState({
      ...ctrlState,
      ...validatedState,
      ...bizRuleErrorCtrlIds.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: {
            ...ctrlState[cur],
            isError: true,
          },
        };
      }, {}),
    });

    // TODO: unit test & refactor to unify form input & biz rule validation
    if (present.hasErrors(ctrlState) || !!bizRuleErrorCtrlIds.length) return;

    const payload = present.prepPayload({
      name: ctrlState[ID_CTRL_PRESET_NAME],
      group: ctrlState[ID_CTRL_GROUP_NAME],
      instrument: ctrlState[ID_CTRL_INSTRUMENT],
      isActive: isChecked,
      presetType: ctrlState[ID_CTRL_PRESET_TYPE],
      leftSide: ctrlState[ID_CTRL_LEFT_SIDE],
      rightSide: ctrlState[ID_CTRL_RIGHT_SIDE],
    });

    await createPriceControlPresetAction(payload);

    if (isAddAnotherChecked) {
      setCtrlState(initialFormState.current);
      setIsChecked(false);
    } else {
      postSubmitCleanupHandler({ isShowForm: false });
    }
  };

  useEffect(() => {
    function retrieveFromApi() {
      retrieveClientGroupAction();
      retrievePriceControlPresetInstrumentAction();
    }
    retrieveFromApi();
  }, [retrieveClientGroupAction, retrievePriceControlPresetInstrumentAction]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="column" alignContent="center">
        <Grid item xs={12}>
          <Typography variant="h5">{formHeading}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            labelText="Preset Name"
            value={ctrlState[ID_CTRL_PRESET_NAME].value}
            error={ctrlState[ID_CTRL_PRESET_NAME].isError}
            disabled={ctrlState[ID_CTRL_PRESET_NAME].isDisabled}
            required={ctrlState[ID_CTRL_PRESET_NAME].isRequired}
            changeHandler={handleInputUpdate}
            blurHandler={handleInputUpdate}
            fullWidth
            helperText="Enter name for preset"
            margin="dense"
            elementId={ID_CTRL_PRESET_NAME}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            component="fieldset"
            fullWidth
            required
            error={ctrlState[ID_CTRL_PRESET_TYPE].isError}
            className={classes.radioFormCtrlFieldset}
          >
            <FormLabel
              component="legend"
              className={classes.radioFormCtrlLegend}
            >
              Preset Type
            </FormLabel>
            <RadioGroup
              aria-label="Preset Type"
              name="preset type"
              value={ctrlState[ID_CTRL_PRESET_TYPE].value}
              onChange={handleRadioGroupChange}
              row
              className={classes.radioGroup}
              id={ID_CTRL_PRESET_TYPE}
            >
              <FormControlLabel
                value="spread"
                control={
                  <Radio
                    size="small"
                    classes={{
                      root: classes.switchBaseRoot,
                    }}
                  />
                }
                label="Spread"
                labelPlacement="top"
              />
              <FormControlLabel
                value="skew"
                control={
                  <Radio
                    size="small"
                    classes={{
                      root: classes.switchBaseRoot,
                    }}
                  />
                }
                label="Skew"
                labelPlacement="top"
              />
              <FormControlLabel
                value="post"
                control={
                  <Radio
                    size="small"
                    classes={{
                      root: classes.switchBaseRoot,
                    }}
                  />
                }
                label="Post"
                labelPlacement="top"
              />
            </RadioGroup>
            <FormHelperText>Select a preset type</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {stateClientGroup &&
          stateClientGroup.retrieveResult &&
          stateClientGroup.retrieveResult.data ? (
            <FormAutocomplete
              options={stateClientGroup.retrieveResult.data.map((item) => {
                return { id: item.name, label: item.name };
              })}
              value={ctrlState[ID_CTRL_GROUP_NAME].value}
              inputValue={ctrlState[ID_CTRL_GROUP_NAME].inputValue}
              error={ctrlState[ID_CTRL_GROUP_NAME].isError}
              disabled={ctrlState[ID_CTRL_GROUP_NAME].isDisabled}
              required={ctrlState[ID_CTRL_GROUP_NAME].isRequired}
              changeHandler={handleAutocompleteChange(
                ID_CTRL_GROUP_NAME,
                'value'
              )}
              inputChangeHandler={handleAutocompleteChange(
                ID_CTRL_GROUP_NAME,
                'inputValue'
              )}
              margin="dense"
              helperText="Select a Group"
              labelText="Group"
              fullWidth
              elementId={ID_CTRL_GROUP_NAME}
            />
          ) : (
            <Typography variant="body1" className={classes.formLoadingCtrlMsg}>
              please wait, loading groups...
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {statePriceControlPresetInstrument &&
          statePriceControlPresetInstrument.retrieveResult &&
          statePriceControlPresetInstrument.retrieveResult.data ? (
            <FormAutocomplete
              options={statePriceControlPresetInstrument.retrieveResult.data.map(
                (item) => {
                  return { id: item.item, label: item.item };
                }
              )}
              value={ctrlState[ID_CTRL_INSTRUMENT].value}
              inputValue={ctrlState[ID_CTRL_INSTRUMENT].inputValue}
              error={ctrlState[ID_CTRL_INSTRUMENT].isError}
              disabled={ctrlState[ID_CTRL_INSTRUMENT].isDisabled}
              required={ctrlState[ID_CTRL_INSTRUMENT].isRequired}
              changeHandler={handleAutocompleteChange(
                ID_CTRL_INSTRUMENT,
                'value'
              )}
              inputChangeHandler={handleAutocompleteChange(
                ID_CTRL_INSTRUMENT,
                'inputValue'
              )}
              margin="dense"
              helperText="Select an instrument"
              labelText="Instruments"
              fullWidth
              elementId={ID_CTRL_INSTRUMENT}
            />
          ) : (
            <Typography variant="body1" className={classes.formLoadingCtrlMsg}>
              please wait, loading instruments...
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormNumberInput
                value={ctrlState[ID_CTRL_LEFT_SIDE].value}
                error={ctrlState[ID_CTRL_LEFT_SIDE].isError}
                disabled={ctrlState[ID_CTRL_LEFT_SIDE].isDisabled}
                required={ctrlState[ID_CTRL_LEFT_SIDE].isRequired}
                changeHandler={handleInputUpdate}
                blurHandler={handleInputUpdate}
                labelText="Left Side"
                helperText="Enter value"
                margin="dense"
                fullWidth={false}
                decimalScale={5}
                elementId={ID_CTRL_LEFT_SIDE}
              />
            </Grid>
            <Grid item xs={6}>
              <FormNumberInput
                value={ctrlState[ID_CTRL_RIGHT_SIDE].value}
                error={ctrlState[ID_CTRL_RIGHT_SIDE].isError}
                disabled={ctrlState[ID_CTRL_RIGHT_SIDE].isDisabled}
                required={ctrlState[ID_CTRL_RIGHT_SIDE].isRequired}
                changeHandler={handleInputUpdate}
                blurHandler={handleInputUpdate}
                labelText="Right Side"
                helperText="Enter value"
                margin="dense"
                fullWidth={false}
                decimalScale={5}
                elementId={ID_CTRL_RIGHT_SIDE}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={6} className={classes.formSwitch}>
              <FormSwitch
                name="isPresetEnabled"
                isChecked={isChecked}
                changeHandler={handleSwitchChange}
                labelText="Active"
                classes={{
                  switchBase: clsx([
                    classes.switchBase,
                    classes.switchBaseRoot,
                  ]),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                classes={{ label: classes.formControlLabel }}
                control={
                  <Checkbox
                    checked={isAddAnotherChecked}
                    onChange={handleCheckboxChange}
                    size="small"
                    name="price-controls-quick-create-form-add-another-checkbox"
                  />
                }
                label="Add another?"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.submitBtnContainer}>
              <FormIconButton
                variant="outlined"
                color="primary"
                size="medium"
                icon={<Save />}
                onClick={handleSavePreset}
                id="quick-create-save-button"
              >
                Create
              </FormIconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

CreatePresetForm.propTypes = {
  stateClientGroup: propTypeClientGroup,
  statePriceControlPresetInstrument: propTypePriceControlPresetInstrument,
  statePresetNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  formHeading: PropTypes.string.isRequired,
  createPriceControlPresetAction: PropTypes.func.isRequired,
  postSubmitCleanupHandler: PropTypes.func.isRequired,
  retrieveClientGroupAction: PropTypes.func.isRequired,
  retrievePriceControlPresetInstrumentAction: PropTypes.func.isRequired,
};

CreatePresetForm.defaultProps = {
  stateClientGroup: null,
  statePriceControlPresetInstrument: null,
};

export default CreatePresetForm;
