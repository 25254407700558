import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormSelect from '../../../forms/FormSelect';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../../hooks/trace-update-hook';

const useStyles = makeStyles(() => ({}));

function ParamGroup({
  groupSelectOptions,
  defaultGroupSelection,
  stateSelected,
  selectionChangeHandler,
}) {
  const classes = useStyles();

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   groupSelectOptions,
  //   defaultGroupSelection,
  //   stateSelected,
  //   selectionChangeHandler,
  // });

  return (
    <FormSelect
      className={classes.select}
      selected={(groupSelectOptions && stateSelected) || defaultGroupSelection}
      options={groupSelectOptions}
      changeHandler={selectionChangeHandler}
      defaultLabel={defaultGroupSelection}
      defaultValue={defaultGroupSelection}
      helperText="Select existing or create new group"
      labelText="Groups"
      elementId="shadow-strategy-param-group-select"
      margin="dense"
      fullWidth
    />
  );
}

ParamGroup.propTypes = {
  groupSelectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  defaultGroupSelection: PropTypes.string.isRequired,
  stateSelected: PropTypes.string,
  selectionChangeHandler: PropTypes.func.isRequired,
};

ParamGroup.defaultProps = {
  groupSelectOptions: null,
  stateSelected: '',
};

export default ParamGroup;
