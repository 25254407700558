import { connect } from 'react-redux';

import Topbar from './Topbar';

const mapStateToProps = function mapStateToProps(state) {
  const { user: stateUser } = state;
  return {
    stateUser,
  };
};

export default connect(mapStateToProps)(Topbar);
