import { zonedTimeToUtc } from 'date-fns-tz';

import SystemAlertMessage from '../../domain/ctx/system/SystemAlertMessage';

function dtoSystemAlertMessage(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');

  const systemAlertMessage = SystemAlertMessage({
    level: gatewayDataObject.level.trim().toUpperCase(),
    datacenter: gatewayDataObject.datacenter.trim().toUpperCase(),
    timestamp: zonedTimeToUtc(gatewayDataObject.timestamp, 'Etc/UTC'),
    summary: gatewayDataObject.summary.toUpperCase(),
    htmlDetails: gatewayDataObject.htmlDetails.trim().toUpperCase(),
    isSendEmail: gatewayDataObject.isSendEmail,
  });

  return systemAlertMessage;
}

export default dtoSystemAlertMessage;
