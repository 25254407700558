import entityShadowParameter from '../defs/ctx-shadow-strat-param/entity-shadow-parameter';
import SealedModel from '../helpers/SealedModel';

const ShadowParameterUpdate = SealedModel({
  ...entityShadowParameter,
  pkCounterparty: String,
  pkInstrumentId: Number,
});

export default ShadowParameterUpdate;
