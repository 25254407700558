import React from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from '@material-ui/core';

function FormSelect({
  selected,
  options,
  changeHandler,
  blurHandler,
  defaultLabel,
  defaultValue,
  helperText,
  labelText,
  elementId,
  margin,
  fullWidth,
  className,
  required,
  error,
  disabled,
}) {
  const inputLabelId = `${elementId}-label`;

  return (
    <FormControl
      className={className}
      variant="outlined"
      margin={margin}
      fullWidth={fullWidth}
      required={required}
      error={error}
      disabled={disabled}
    >
      <InputLabel id={inputLabelId}>{labelText}</InputLabel>
      <Select
        labelId={inputLabelId}
        label={labelText}
        id={elementId}
        name={elementId}
        value={selected}
        onChange={changeHandler}
        onBlur={blurHandler}
      >
        {defaultLabel !== '' ? (
          <MenuItem value={defaultValue}>
            <em>{defaultLabel}</em>
          </MenuItem>
        ) : null}
        {Array.isArray(options) &&
          options.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

FormSelect.propTypes = {
  defaultLabel: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  helperText: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ),
  changeHandler: PropTypes.func.isRequired,
  blurHandler: PropTypes.func,
  elementId: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormSelect.defaultProps = {
  margin: 'normal',
  fullWidth: false,
  className: '',
  required: false,
  error: false,
  blurHandler: null,
  disabled: false,
  options: null,
};

export default FormSelect;
