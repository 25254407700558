import React from 'react';
import PropTypes from 'prop-types';
import OnOffSwitch from '../../../OnOffSwitch';

function Status(props) {
  const { setValue, value } = props;

  return (
    <OnOffSwitch
      checked={value === 1}
      size="medium"
      onClick={() => setValue(value === 1 ? 0 : 1)}
    />
  );
}

Status.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOf([0, 1]).isRequired,
};

export default Status;
