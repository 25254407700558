import { connect } from 'react-redux';

import SelectInstrument from './SelectInstrument';

const mapStateToProps = (state) => {
  const { shadowInstrument: stateShadowInstruments } = state;
  return {
    stateShadowInstruments,
  };
};

export default connect(mapStateToProps)(SelectInstrument);
