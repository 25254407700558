import ValTypeBoolNum from '../../ValTypeBoolNum';
import ValTypeAlphaNumDash from '../../ValTypeAlphaNumDash';

const entityShadowGroup = {
  name: ValTypeAlphaNumDash,
  reportingOrg: String,
  automatedStreamId: Number,
  manualStreamId: Number,
  executionDelayMillis: Number,
  enabled: ValTypeBoolNum,
};

export default entityShadowGroup;
