import entityShadowGroup from '../defs/ctx-shadow-strat-param/entity-shadow-group';
import ValTypeStatus from './ValTypeStatus';
import SealedModel from '../helpers/SealedModel';

const ShadowGroup = SealedModel({
  ...entityShadowGroup,
  status: [ValTypeStatus], // TODO: Fix API to include status in return array objects
}).defaultTo({
  status: 'offline',
});

export default ShadowGroup;
