import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ToastAlert from '../ToastAlert';

import presenter from './presenter';

const useStyles = makeStyles(() => ({
  alertAction: {
    alignItems: 'start',
  },
}));

function ApiClientErrors({
  stateSystemAlert,
  retrieveResetError,
  cmdResetError,
}) {
  const classes = useStyles();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const present = presenter();
  const presentVm = useCallback(present.vm, []);
  const [vm, setVm] = useState(presentVm(null));

  const handleCloseAlert = () => {
    if (
      stateSystemAlert.errors != null &&
      Array.isArray(stateSystemAlert.errors) &&
      stateSystemAlert.errors.length > 0
    ) {
      stateSystemAlert.errors.forEach((error) => {
        if (error.type === 'retrieveError') retrieveResetError();
        if (error.type === 'cmdError') cmdResetError();
      });
    }
    setIsAlertOpen(false);
  };

  useEffect(() => {
    if (
      stateSystemAlert &&
      stateSystemAlert.errors != null &&
      Array.isArray(stateSystemAlert.errors) &&
      stateSystemAlert.errors.length > 0
    ) {
      setIsAlertOpen(true);
      const displayContent = stateSystemAlert.errors;

      setVm(presentVm(displayContent));
    }
  }, [stateSystemAlert, presentVm]);

  return (
    vm != null && (
      <ToastAlert
        severity={vm.severity}
        isAlertOpen={isAlertOpen}
        closeAlertHandler={handleCloseAlert}
        alertTitleCopy={vm.alertTitleCopy}
        alertBodyCopy={vm.alertBodyCopy}
        alertClasses={{ action: classes.alertAction }}
      />
    )
  );
}

ApiClientErrors.propTypes = {
  stateSystemAlert: PropTypes.shape({
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        state: PropTypes.instanceOf(Error),
        type: PropTypes.string,
      })
    ),
  }),
  retrieveResetError: PropTypes.func,
  cmdResetError: PropTypes.func,
};

ApiClientErrors.defaultProps = {
  stateSystemAlert: null,
  retrieveResetError: () => {},
  cmdResetError: () => {},
};

export default ApiClientErrors;
