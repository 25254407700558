import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

function FormInput({
  elementId,
  labelText,
  value,
  defaultValue,
  helperText,
  required,
  disabled,
  error,
  margin,
  type,
  fullWidth,
  inputProps,
  style,
  className,
  autoComplete,
  changeHandler,
  blurHandler,
}) {
  return (
    <TextField
      id={elementId}
      label={labelText}
      value={value}
      defaultValue={defaultValue}
      helperText={helperText}
      variant="outlined"
      required={required}
      disabled={disabled}
      error={error}
      margin={margin}
      type={type}
      fullWidth={fullWidth}
      inputProps={inputProps}
      style={style}
      className={className}
      autoComplete={autoComplete}
      onChange={changeHandler}
      onBlur={blurHandler}
    />
  );
}

FormInput.propTypes = {
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  helperText: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'email',
    'search',
    'tel',
    'url',
  ]),
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
  }),
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  autoComplete: PropTypes.string,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
};

FormInput.defaultProps = {
  defaultValue: undefined,
  required: false,
  disabled: false,
  error: false,
  margin: 'normal',
  type: 'text',
  fullWidth: false,
  inputProps: { min: null, max: null, step: null },
  className: null,
  style: null,
  autoComplete: null,
  changeHandler: null,
  blurHandler: null,
};

export default FormInput;
