import { connect } from 'react-redux';

import SystemAlertMessages from './SystemAlertMessages';

const mapStateToProps = function mapStateToProps(state) {
  const { systemAlert: stateSystemAlert } = state;
  return {
    stateSystemAlert,
  };
};

export default connect(mapStateToProps)(SystemAlertMessages);
