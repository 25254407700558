const swapSessionForTokens = async (oktaAuth, options) => {
  const scopes = ['openid', 'profile', 'email'];

  return oktaAuth.token.getWithoutPrompt({
    scopes,
    ...options,
  });
};

const storeTokenOrTokens = (oktaAuth, tokenOrTokens) => {
  oktaAuth.tokenManager.setTokens(tokenOrTokens.tokens);
};

const getAccessToken = (oktaAuth) => {
  let oktaAccessToken = null;
  if (oktaAuth != null && typeof oktaAuth.getAccessToken === 'function') {
    oktaAccessToken = oktaAuth.getAccessToken();
  }
  if (
    oktaAccessToken != null &&
    typeof oktaAccessToken === 'string' &&
    oktaAccessToken.length > 0
  )
    return oktaAccessToken;
  // eslint-disable-next-line no-console
  console.warn('no access token found!');
  return null;
};

const prepPathname = (url) => {
  if (url) {
    try {
      const parsedUrl = new URL(url, window.location.origin);
      return parsedUrl.pathname;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('parse url error', e);
    }
  }
  return '/';
};

const getStoredState = (storageLocation, key = 'referrerPath') => {
  const getByStateLocation = {
    localStorage: localStorage.getItem(key),
    sessionStorage: sessionStorage.getItem(key),
    cookie: () => {
      // eslint-disable-next-line no-console
      console.warn('not implemented');
      return '';
    },
    memory: () => {
      // eslint-disable-next-line no-console
      console.warn('not implemented');
      return '';
    },
  };
  return getByStateLocation[storageLocation];
};

export default {
  swapSessionForTokens,
  storeTokenOrTokens,
  getAccessToken,
  prepPathname,
  getStoredState,
};
