function groupStategiesFormEditPresenter() {
  function prepSubmitPayload({ groupName, counterparty, instrumentId }) {
    return [{ pk: { counterparty, groupName, instrumentId } }];
  }

  function prepCmdPayload({ groupName, counterparty, instrumentId, action }) {
    return {
      groupName,
      counterparty,
      instrumentId,
      action,
    };
  }

  return Object.freeze({
    prepSubmitPayload,
    prepCmdPayload,
  });
}

export default groupStategiesFormEditPresenter;
