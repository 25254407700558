import presentersCommon from '../../../presenters/common';

function presenter({ ID_CTRL_CLIENT_GROUP_NAME_INPUT }) {
  const initialFormState = {
    [ID_CTRL_CLIENT_GROUP_NAME_INPUT]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: true,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Client group name: required',
        },
        {
          rule: 'isAlphaNumDash',
          feedback:
            'Client group name: alphanumeric characters with dashes only',
        },
      ],
    },
  };

  return Object.freeze({
    initialFormState,
    manageCtrlState: presentersCommon.manageCtrlState,
    hasErrors: presentersCommon.hasErrors,
    validateCtrls: presentersCommon.validateCtrls,
  });
}

export default presenter;
