import { ArrayModel } from 'objectmodel';

import entityClient from '../client/defs/entity-client';
import entityClientGroup from './defs/entity-client-group';
import SealedModel from '../../helpers/SealedModel';

const ClientGroup = SealedModel({
  ...entityClientGroup,
  clients: [ArrayModel(entityClient)],
});

export default ClientGroup;
