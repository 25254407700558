import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/styles';
import { blue, green, orange, red, yellow } from '@material-ui/core/colors';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    height: '20px',
    marginBottom: '0.2em',
  },
  sizeSmall: {
    fontSize: theme.typography.body2.fontSize,
  },
  blue: {
    color: blue.A100,
    borderColor: blue.A100,
  },
  green: {
    color: green.A200,
    borderColor: green.A200,
  },
  orange: {
    color: orange.A200,
    borderColor: orange.A200,
  },
  red: {
    color: red.A200,
    borderColor: red.A200,
  },
  yellow: {
    color: yellow.A200,
    borderColor: yellow.A200,
  },
});

const colourByStatus = (status) => {
  const colours = {
    InsertNew: 'blue',
    PendingNew: 'blue',
    New: 'blue',
    InsertCancel: 'blue',
    PartiallyFilled: 'green',
    Filled: 'green',
    Canceled: 'orange',
    Expired: 'orange',
    Rejected: 'red',
    default: 'yellow',
  };
  return colours[status] || colours.default;
};

// eslint-disable-next-line react/prefer-stateless-function
class RenderStatus extends React.Component {
  render() {
    const { classes, getValue } = this.props;
    const value = getValue();
    const colour = colourByStatus(value);
    return (
      <Chip
        size="small"
        variant="outlined"
        classes={{ sizeSmall: classes.sizeSmall }}
        className={clsx(classes.root, classes[colour])}
        label={value}
      />
    );
  }
}

RenderStatus.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    sizeSmall: PropTypes.string.isRequired,
    blue: PropTypes.string.isRequired,
    green: PropTypes.string.isRequired,
    orange: PropTypes.string.isRequired,
    red: PropTypes.string.isRequired,
    yellow: PropTypes.string.isRequired,
  }).isRequired,
  getValue: PropTypes.func.isRequired,
};

export default withStyles(styles)(RenderStatus);
