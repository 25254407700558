const appNodeEnv = process.env.NODE_ENV;
const appEnv = process.env.REACT_APP_SENTRY_APP_ENV;
const appVer = process.env.REACT_APP_SENTRY_APP_RELEASE;

// eslint-disable-next-line import/prefer-default-export
export const appCfg = {
  appNodeEnv: appNodeEnv == null ? '' : appNodeEnv.trim(),
  appEnv: appEnv == null ? '' : appEnv.trim(),
  appVersion: appVer == null ? '' : appVer.trim(),
};
