import ValTypeBoolNum from '../../ValTypeBoolNum';
import ValTypeAlphaNumDash from '../../ValTypeAlphaNumDash';

const entityProOrganization = {
  organization: ValTypeAlphaNumDash,
  logMarketData: ValTypeBoolNum,
  enabled: ValTypeBoolNum,
};

export default entityProOrganization;
