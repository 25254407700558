import PriceControlPreset from '../domain/ctx/price-controls/Preset';

function dtoPriceControlPreset(gatewayDataObject) {
  if (typeof gatewayDataObject !== 'object')
    throw new TypeError('dto expects a JSON object');
  const priceControlPreset = PriceControlPreset({
    id: gatewayDataObject.id,
    name: gatewayDataObject.name,
    group: gatewayDataObject.group,
    instrument: gatewayDataObject.instrument,
    isActive: gatewayDataObject.isActive ? gatewayDataObject.isActive : false,
    types: gatewayDataObject.types.map((type) => {
      return {
        id: type.id,
        primary: type.primary,
        secondary: type.secondary,
        value: type.value,
        priceControlPresetId: type.priceControlPresetId,
      };
    }),
  });

  return priceControlPreset;
}

export default dtoPriceControlPreset;
