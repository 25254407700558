import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';

import FormSelect from '../../FormSelect';
import FormInput from '../../FormInput';
import FormNumberInput from '../../FormNumberInput';
import FormSwitch from '../../FormSwitch';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../../hooks/trace-update-hook';

import shadowStrategyGroupFormPresenter from '../../../presenters/shadow-strategy-group-param-form';

import propDefaultApiGet from '../../../prop-defaults/api-get';
import propTypeInstrument from '../../../prop-types/shadow-instrument';
import propTypeShadowGroupStrategyParam from '../../../prop-types/shadow-group-strategy-param';

import formValidation from '../../../shared/form-validation';

import staticDataOrderTypes from '../data/static-data-order-types';
import staticDataTimeInForceOpts from '../data/static-data-time-in-force-opts';
import staticDataStrategies from '../data/static-data-strategies';

import SelectInstrument from '../../SelectInstrument/SelectInstrument';

const ID_CTRL_INSTRUMENTS =
  'shadow-strategy-param-group-strat-instrument-select';
const ID_CTRL_COUNTERPARTY =
  'shadow-strategy-param-group-strat-counterparty-input';
const ID_CTRL_STRATEGY = 'shadow-strategy-param-group-strat-strategy-select';
const ID_CTRL_ORDER_TYPE =
  'shadow-strategy-param-group-strat-order-type-select';
const ID_CTRL_TIF = 'shadow-strategy-param-group-strat-tif-select';
const ID_CTRL_HEDGE_RATIO =
  'shadow-strategy-param-group-strat-hedge-ratio-input';
const ID_CTRL_COPY_MIN = 'shadow-strategy-param-group-strat-copy-min-input';
const ID_CTRL_COPY_MAX = 'shadow-strategy-param-group-strat-copy-max-input';
const ID_CTRL_RETRY_INTERVAL =
  'shadow-strategy-param-group-strat-retry-interval-input';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  secondRow: {
    textAlign: 'center',
  },
}));

function GroupStrategyParamForm({
  stateShadowInstrument,
  // retrieveInstrumentAction,
  setSubmitFn,
  groupSelected,
  createShadowGroupStrategyParamAction,
  cmdStrategyAction,
  closeHandler,
  stateShadowGroupStrategyParam,
}) {
  const classes = useStyles();
  const presenter = shadowStrategyGroupFormPresenter({
    ID_CTRL_INSTRUMENTS,
    ID_CTRL_COUNTERPARTY,
    ID_CTRL_STRATEGY,
    ID_CTRL_ORDER_TYPE,
    ID_CTRL_TIF,
    ID_CTRL_HEDGE_RATIO,
    ID_CTRL_COPY_MIN,
    ID_CTRL_COPY_MAX,
    ID_CTRL_RETRY_INTERVAL,
  });
  const submitRef = useRef(null);
  const initialFormState = useRef(presenter.initialFormState);
  const [ctrlState, setCtrlState] = useState(initialFormState.current);
  const [isChecked, setIsChecked] = useState(true);
  const [accessToken, setAccessToken] = useState(null);

  const { authState } = useOktaAuth();

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   stateShadowInstrument,
  //   retrieveInstrumentAction,
  // });

  const handleInputUpdate = (e) => {
    const { id, value } = e.target;
    manageCtrlState(id, value);
  };
  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleSelectUpdate = (e) => {
    const { name, value } = e.target;
    manageCtrlState(name, value);
  };
  const handleAutocomplete = (value) => {
    manageCtrlState(ID_CTRL_INSTRUMENTS, value);
  };

  const manageCtrlState = (name, value) => {
    const def = { ...ctrlState[name] };
    const { validators } = def;
    const errors = formValidation.checkValidity(value, validators);

    setCtrlState({
      ...ctrlState,
      [name]: {
        ...def,
        isTouched: true,
        isError: !!errors.length,
        value,
      },
    });
  };

  const ctrlStateHasErrors = () => {
    const errors = Object.keys(ctrlState).reduce((acc, cur) => {
      return [
        ...acc,
        ...formValidation.checkValidity(
          ctrlState[cur].value,
          ctrlState[cur].validators
        ),
      ];
    }, []);
    if (errors.length) {
      setCtrlState(
        Object.keys(ctrlState).reduce((acc, cur) => {
          return {
            ...acc,
            ...presenter.validateFormState(ctrlState, cur),
          };
        }, {})
      );
    } else {
      return false;
    }
    return true;
  };

  const bizRulesHasErrors = (shadowGroupStrategyParam) => {
    const errors = presenter.validateBizRules(
      ctrlState,
      shadowGroupStrategyParam
    );
    if (errors.length) {
      setCtrlState({
        ...ctrlState,
        ...errors.reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: {
              ...ctrlState[cur],
              isError: true,
            },
          };
        }, {}),
      });
      return true;
    }
    return false;
  };

  const handleSubmit = async (shadowGroupStrategyParam) => {
    if (!ctrlStateHasErrors() && !bizRulesHasErrors(shadowGroupStrategyParam)) {
      await createShadowGroupStrategyParamAction(
        presenter.prepSubmitPayload({
          groupName: groupSelected,
          formState: ctrlState,
          isEnabled: isChecked,
        }),
        accessToken
      );
      cmdStrategyAction(
        presenter.prepCmdPayload({
          groupName: groupSelected,
          formState: ctrlState,
          action: 'insert',
        }),
        accessToken
      );
      closeHandler();
    }
  };

  submitRef.current = () => handleSubmit(stateShadowGroupStrategyParam);
  useEffect(() => {
    if (setSubmitFn) {
      setSubmitFn(submitRef);
    }
  });

  useEffect(() => {
    if (authState.isAuthenticated) {
      setAccessToken(() => authState.accessToken);
    } else {
      setAccessToken(() => null);
    }
  }, [authState]);

  // WARN: Moved to GridComponent
  // useEffect(() => {
  //   function localConnect() {
  //     retrieveInstrumentAction();
  //   }
  //   localConnect();
  // }, [retrieveInstrumentAction]);

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
        >
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12} md={6}>
                <FormInput
                  value={ctrlState[ID_CTRL_COUNTERPARTY].value}
                  error={ctrlState[ID_CTRL_COUNTERPARTY].isError}
                  disabled={ctrlState[ID_CTRL_COUNTERPARTY].isDisabled}
                  required={ctrlState[ID_CTRL_COUNTERPARTY].isRequired}
                  changeHandler={handleInputUpdate}
                  blurHandler={handleInputUpdate}
                  helperText="Enter user/counterparty"
                  labelText="User/Cpty *"
                  margin="dense"
                  fullWidth
                  elementId={ID_CTRL_COUNTERPARTY}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <FormSelect
                  selected={ctrlState[ID_CTRL_INSTRUMENTS].value}
                  options={presenter.prepInstrumentSelectOptions(
                    stateShadowInstrument
                  )}
                  error={ctrlState[ID_CTRL_INSTRUMENTS].isError}
                  disabled={ctrlState[ID_CTRL_INSTRUMENTS].isDisabled}
                  required={ctrlState[ID_CTRL_INSTRUMENTS].isRequired}
                  changeHandler={handleSelectUpdate}
                  blurHandler={handleSelectUpdate}
                  defaultLabel=""
                  defaultValue=""
                  helperText="Select an instrument"
                  labelText="Instruments *"
                  margin="dense"
                  fullWidth
                  elementId={ID_CTRL_INSTRUMENTS}
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <SelectInstrument
                  stateShadowInstruments={stateShadowInstrument}
                  value={Number(ctrlState[ID_CTRL_INSTRUMENTS].value)}
                  error={ctrlState[ID_CTRL_INSTRUMENTS].isError}
                  disabled={ctrlState[ID_CTRL_INSTRUMENTS].isDisabled}
                  required={ctrlState[ID_CTRL_INSTRUMENTS].isRequired}
                  embeded={false}
                  setValue={handleAutocomplete}
                  defaultLabel=""
                  defaultValue=""
                  helperText="Select an instrument"
                  labelText="Instruments *"
                  fullWidth
                  elementId={ID_CTRL_INSTRUMENTS}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelect
                  selected={ctrlState[ID_CTRL_STRATEGY].value}
                  options={staticDataStrategies}
                  error={ctrlState[ID_CTRL_STRATEGY].isError}
                  disabled={ctrlState[ID_CTRL_STRATEGY].isDisabled}
                  required={ctrlState[ID_CTRL_STRATEGY].isRequired}
                  changeHandler={handleSelectUpdate}
                  blurHandler={handleSelectUpdate}
                  defaultLabel=""
                  defaultValue=""
                  helperText="Select strategy"
                  labelText="Strategy"
                  margin="dense"
                  fullWidth
                  elementId={ID_CTRL_STRATEGY}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelect
                  selected={ctrlState[ID_CTRL_ORDER_TYPE].value}
                  options={staticDataOrderTypes}
                  error={ctrlState[ID_CTRL_ORDER_TYPE].isError}
                  disabled={ctrlState[ID_CTRL_ORDER_TYPE].isDisabled}
                  required={ctrlState[ID_CTRL_ORDER_TYPE].isRequired}
                  changeHandler={handleSelectUpdate}
                  blurHandler={handleSelectUpdate}
                  defaultLabel=""
                  defaultValue=""
                  helperText="Select an order type"
                  labelText="Order Type *"
                  margin="dense"
                  fullWidth
                  elementId={ID_CTRL_ORDER_TYPE}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelect
                  selected={ctrlState[ID_CTRL_TIF].value}
                  options={staticDataTimeInForceOpts}
                  error={ctrlState[ID_CTRL_TIF].isError}
                  disabled={ctrlState[ID_CTRL_TIF].isDisabled}
                  required={ctrlState[ID_CTRL_TIF].isRequired}
                  changeHandler={handleSelectUpdate}
                  blurHandler={handleSelectUpdate}
                  defaultLabel=""
                  defaultValue=""
                  helperText="Select TIF"
                  labelText="Time in force *"
                  margin="dense"
                  fullWidth
                  elementId={ID_CTRL_TIF}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormNumberInput
                  value={ctrlState[ID_CTRL_HEDGE_RATIO].value}
                  error={ctrlState[ID_CTRL_HEDGE_RATIO].isError}
                  disabled={ctrlState[ID_CTRL_HEDGE_RATIO].isDisabled}
                  required={ctrlState[ID_CTRL_HEDGE_RATIO].isRequired}
                  changeHandler={handleInputUpdate}
                  blurHandler={handleInputUpdate}
                  helperText="Enter hedge ratio, maximum 10.00"
                  labelText="Hedge ratio *"
                  margin="dense"
                  fullWidth
                  allowNegative={false}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === '' || floatValue <= 10;
                  }}
                  decimalScale={2}
                  fixedDecimalScale
                  elementId={ID_CTRL_HEDGE_RATIO}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12} md={6}>
                <FormNumberInput
                  value={ctrlState[ID_CTRL_COPY_MIN].value}
                  error={ctrlState[ID_CTRL_COPY_MIN].isError}
                  disabled={ctrlState[ID_CTRL_COPY_MIN].isDisabled}
                  required={ctrlState[ID_CTRL_COPY_MIN].isRequired}
                  changeHandler={handleInputUpdate}
                  blurHandler={handleInputUpdate}
                  helperText="Enter copy min"
                  labelText="Copy Min *"
                  margin="dense"
                  fullWidth
                  thousandSeparator
                  allowNegative={false}
                  decimalScale={0}
                  elementId={ID_CTRL_COPY_MIN}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormNumberInput
                  value={ctrlState[ID_CTRL_COPY_MAX].value}
                  error={ctrlState[ID_CTRL_COPY_MAX].isError}
                  disabled={ctrlState[ID_CTRL_COPY_MAX].isDisabled}
                  required={ctrlState[ID_CTRL_COPY_MAX].isRequired}
                  changeHandler={handleInputUpdate}
                  blurHandler={handleInputUpdate}
                  helperText="Enter copy max, greater than copy min"
                  labelText="Copy Max *"
                  margin="dense"
                  fullWidth
                  thousandSeparator
                  allowNegative={false}
                  decimalScale={0}
                  elementId={ID_CTRL_COPY_MAX}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormNumberInput
                  value={ctrlState[ID_CTRL_RETRY_INTERVAL].value}
                  error={ctrlState[ID_CTRL_RETRY_INTERVAL].isError}
                  disabled={ctrlState[ID_CTRL_RETRY_INTERVAL].isDisabled}
                  required={ctrlState[ID_CTRL_RETRY_INTERVAL].isRequired}
                  changeHandler={handleInputUpdate}
                  blurHandler={handleInputUpdate}
                  helperText="Enter retry intervals (ms)"
                  labelText="Retry Intervals (ms) *"
                  margin="dense"
                  fullWidth
                  allowNegative={false}
                  decimalScale={0}
                  elementId={ID_CTRL_RETRY_INTERVAL}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.secondRow}>
                <FormSwitch
                  name="isGroupStrategyEnabled"
                  isChecked={isChecked}
                  changeHandler={handleSwitchChange}
                  labelText="Enabled"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

GroupStrategyParamForm.propTypes = {
  stateShadowGroupStrategyParam: propTypeShadowGroupStrategyParam.isRequired,
  stateShadowInstrument: propTypeInstrument,
  // retrieveInstrumentAction: PropTypes.func.isRequired,
  createShadowGroupStrategyParamAction: PropTypes.func.isRequired,
  cmdStrategyAction: PropTypes.func.isRequired,
  setSubmitFn: PropTypes.func.isRequired,
  groupSelected: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

GroupStrategyParamForm.defaultProps = {
  stateShadowInstrument: propDefaultApiGet,
};

export default GroupStrategyParamForm;
