import React from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';

import { CssBaseline } from '@material-ui/core';

import theme from './theme';
import Routes from './Routes';

import SocketClient from './shared/socket-client';
import ApiClient from './shared/api-client';
import configureStore from './store/create';
import configAuth from './config/auth';
import logUtil from './shared/logger';
import {
  RETRIEVE as USER_RETRIEVE,
  RETRIEVE_SUCCESS as USER_RETRIEVE_SUCCESS,
  RETRIEVE_FAIL as USER_RETRIEVE_FAIL,
} from './store/modules/user';

const { issuer, clientId, redirectUri, scopes, pkce, disableHttpsCheck } =
  configAuth.oidc;

const oktaAuth = new OktaAuth({
  issuer,
  clientId,
  redirectUri,
  scopes,
  pkce,
  disableHttpsCheck,
  tokenManager: {
    autoRenew: true,
    secure: true,
    storage: 'sessionStorage', // localStorage | sessionStorage | cookie | memory
    expireEarlySeconds: 1800, // Okta token lifetime should be set for 1 hour = 3600 seconds
  },
});
const socketClient = new SocketClient(oktaAuth);
const apiClient = new ApiClient(oktaAuth);

const initialState = {};
const store = configureStore(initialState, socketClient, apiClient);

oktaAuth.authStateManager.subscribe(async (authStateSub) => {
  if (authStateSub.isAuthenticated) {
    store.dispatch({
      type: USER_RETRIEVE,
    });
    oktaAuth
      .getUser()
      .then((info) => {
        store.dispatch({
          type: USER_RETRIEVE_SUCCESS,
          result: info,
        });
      })
      .catch((e) => {
        store.dispatch({
          type: USER_RETRIEVE_FAIL,
          result: e,
        });
        logger.logError(e);
      });
  }
});

const logger = logUtil();
logger.setStore(store);
oktaAuth.tokenManager.on(
  'expired',
  (
    key
    // expiredToken
  ) => {
    logger.logInfo(`Token expired: ${key}`, 'log');
  }
);
oktaAuth.tokenManager.on(
  'removed',
  (
    key
    // expiredToken
  ) => {
    logger.logInfo(`Token removed: ${key}`, 'log');
  }
);
oktaAuth.tokenManager.on(
  'renewed',
  (
    key
    // newToken,
    // oldToken
  ) => {
    logger.logInfo(`Token renewed: ${key}`, 'log');
  }
);
// Triggered when an OAuthError is returned via the API (typically during token renew)
oktaAuth.tokenManager.on('error', (err) => {
  // eslint-disable-next-line no-console
  console.warn('Token renewel error');
  // eslint-disable-next-line no-console
  console.error(err);
  logger.logError(err);
  // err.name
  // err.message
  // err.errorCode
  // err.errorSummary
  // err.tokenKey
  // err.accessToken
});

function HasAccessToRouter() {
  const history = useHistory();

  const customAuthHandler = () => {
    history.replace('/sign-in');
  };
  // no-op
  const restoreOriginalUri = async () => window.location.origin;

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </Security>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <HasAccessToRouter />
      </Router>
    </Provider>
  );
}

export default App;
