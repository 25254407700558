import presentersCommon from '../../presenters/common';

function presenter({ ID_CTRL_EMAIL, ID_CTRL_PASSWORD }) {
  const initialFormState = {
    [ID_CTRL_EMAIL]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Email: required',
        },
        {
          rule: 'isEmail',
          feedback: 'Email: is not valid',
        },
      ],
    },
    [ID_CTRL_PASSWORD]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Password: required',
        },
        {
          rule: 'isValidPassword',
          feedback: 'Password: is not valid',
        },
      ],
    },
  };

  return Object.freeze({
    initialFormState,
    manageCtrlState: presentersCommon.manageCtrlState,
    hasErrors: presentersCommon.hasErrors,
    validateCtrls: presentersCommon.validateCtrls,
  });
}

export default presenter;
