import ValTypeBoolNum from '../../ValTypeBoolNum';
// Note: instrument prop is added by API
const entityInstrument = {
  id: Number,
  instrument: String,
  symbol: String,
  tenor: String,
  productType: String,
  dps: Number,
  enabled: ValTypeBoolNum,
};

export default entityInstrument;
