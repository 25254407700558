import React from 'react';

import { format } from 'date-fns-tz';
import { parseISO } from 'date-fns';

import StatusRenderer from './renderers/Status';
import DeleteRowRenderer from '../ag-grid/renderers/DeleteRow';

const getSelectedRowData = (nodes) => {
  return Object.values(nodes).map((node) => node.data);
};

const dateTimeFormatter = (params) => {
  if (params.value == null) return '';
  const parsedDate = parseISO(params.value);
  return `${format(parsedDate, 'yyyy-MM-dd HH:mm:ss:SSS')}`;
};

const sizeColumnsToFit = (params) => {
  params.api.sizeColumnsToFit();
};

const onFirstDataRendered = (params) => {
  sizeColumnsToFit(params);
};

const onGridSizeChanged = (params) => {
  sizeColumnsToFit(params);
};

function generateConfig({ deleteHandler } = {}) {
  const sharedColDefs = [
    {
      headerName: 'Symbol',
      field: 'instrument',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      headerName: 'Side',
      field: 'side',
      minWidth: 50,
      maxWidth: 50,
    },
    {
      headerName: 'Initial Qty',
      field: 'initialQty',
      minWidth: 100,
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      minWidth: 100,
    },
    {
      headerName: 'Filled Qty',
      field: 'filledQty',
      minWidth: 80,
      maxWidth: 80,
    },
    {
      headerName: 'Price',
      field: 'price',
      minWidth: 80,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 100,
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: 'Source',
      field: 'source',
      minWidth: 160,
    },
    {
      headerName: 'Counterparty',
      field: 'counterparty',
      minWidth: 100,
    },
    {
      headerName: 'TIF',
      field: 'tif',
      minWidth: 50,
    },
    {
      headerName: 'Order Type',
      field: 'orderType',
      minWidth: 90,
    },
    {
      headerName: 'Stop Price',
      field: 'stopPrice',
      minWidth: 90,
      maxWidth: 90,
    },
    {
      headerName: 'Internal ID',
      field: 'internalId',
      minWidth: 240,
      maxWidth: 240,
    },
    {
      headerName: 'Expiration Time',
      field: 'expirationTime',
      minWidth: 160,
      maxWidth: 160,
      valueFormatter: dateTimeFormatter,
    },
  ];

  const sharedDefaultColDefs = {
    flex: 1,
    sortable: false,
    filter: true,
  };

  return {
    columnDefs: [
      {
        colId: 'internalId',
        minWidth: 50,
        maxWidth: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: 'left',
        suppressColumnsToolPanel: true,
      },
      {
        minWidth: 35,
        maxWidth: 35,
        // cellRendererFramework: CancelOrdersRowRenderer,
        cellRendererFramework: (props) => {
          return (
            <DeleteRowRenderer
              confirmDialogTitle="Cancel selected order?"
              confirmDialogActionLabel="cancel"
              confirmDialogEntityLabel="order"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              // deleteRowHandler={() => deleteHandler({ id })}
              deleteRowHandler={() => {
                // eslint-disable-next-line react/prop-types
                const { api } = props;
                const selectedRowData = getSelectedRowData(
                  // eslint-disable-next-line react/prop-types
                  api.getSelectedNodes()
                );
                const deleteOrderIds = selectedRowData.map(
                  (selectedOrder) => selectedOrder.internalId
                );
                deleteHandler({ ids: deleteOrderIds });
              }}
            />
          );
        },
        // headerComponentFramework: CancelOrdersHeaderRenderer,
        headerComponentFramework: (props) => {
          return (
            <DeleteRowRenderer
              confirmDialogTitle="Cancel selected order(s)?"
              confirmDialogActionLabel="cancel"
              confirmDialogEntityLabel="orders(s)"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              // deleteRowHandler={() => deleteHandler({ id })}
              deleteRowHandler={() => {
                // eslint-disable-next-line react/prop-types
                const { api } = props;
                const selectedRowData = getSelectedRowData(
                  // eslint-disable-next-line react/prop-types
                  api.getSelectedNodes()
                );
                const deleteOrderIds = selectedRowData.map(
                  (selectedOrder) => selectedOrder.internalId
                );
                deleteHandler({ ids: deleteOrderIds });
              }}
              selectedRowsCounter={() => {
                // eslint-disable-next-line react/prop-types
                const { api } = props;
                // eslint-disable-next-line react/prop-types
                return api.getSelectedNodes().length;
              }}
            />
          );
        },
        suppressColumnsToolPanel: true,
        pinned: 'right',
      },
      {
        headerName: 'Modified Time',
        field: 'timeModified',
        minWidth: 180,
        valueFormatter: dateTimeFormatter,
        cellRenderer: 'agGroupCellRenderer',
        sort: 'desc',
      },
      ...sharedColDefs,
    ],
    defaultColDef: {
      ...sharedDefaultColDefs,
    },
    sharedColDefs,
    sharedDefaultColDefs,
    onFirstDataRendered,
    onGridSizeChanged,
    getRowHeight(params) {
      const sizes = params.api.getSizesForCurrentTheme();
      if (params.node && params.node.detail) {
        const offset = 65;

        const allDetailRowHeight =
          params.data.meta.historyCounter * sizes.rowHeight;
        return allDetailRowHeight + sizes.headerHeight + offset;
      }
      return sizes.rowHeight;
    },
    dateTimeFormatter,
  };
}

export default generateConfig;
