import seedrandom from 'seedrandom';
import { hslToRgb } from '@material-ui/core/styles';

export const mergeArrObjsWithMatchingProp = (prop) => (arr1, arr2) =>
  Array.isArray(arr1) && arr1.length > 0
    ? arr1.map(
        (obj) =>
          (arr2 &&
            toArrayIfNotArray(arr2).find((p) => {
              return p[prop] === obj[prop];
            })) ||
          obj
      )
    : arr2;

// TODO: DRY - share across projects
export const mergeArrObjsWithProp = (prop) => (arr1, arr2) => {
  const array1 = toArrayIfNotArray(arr1);
  const array2 = toArrayIfNotArray(arr2);
  return [
    ...array1.filter((arr1Element) => {
      return !array2.find((arr2Element) => {
        return arr2Element[prop] === arr1Element[prop];
      });
    }),
    ...array2,
  ];
};

export const deepMergeArrObjsWithIdentifier = (prop) => (arr1, arr2) => {
  return Object.values(
    [...arr1, ...arr2].reduce((acc, { [prop]: id, ...rest }) => {
      acc[id] = {
        ...(acc[id] || {}),
        [prop]: id,
        ...rest,
      };
      return acc;
    }, {})
  );
};

export function toArrayIfNotArray(thing) {
  return Array.isArray(thing) ? thing : [thing];
}

export const generateColorFromString = (seed) => {
  const PHI = 1.618033988749895;
  return hslToRgb(
    `hsl(${Math.floor(((seedrandom(seed)() + 1 / PHI) % 1) * 360)}, 50%, 50%)`
  );
};
