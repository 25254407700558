import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import propTypeInstrument from '../../prop-types/shadow-instrument';

// TODO: refactor/replace with generic/reusable autocomplete component
function SelectInstrument({
  stateShadowInstruments,
  setValue,
  value,
  embeded = true,
  error,
  disabled,
  required,
  labelText,
  helperText,
  fullWidth,
}) {
  const [openEdit, setOpenEdit] = useState(false);
  const options =
    (stateShadowInstruments &&
      stateShadowInstruments.retrieveResult &&
      stateShadowInstruments.retrieveResult.data) ||
    [];

  const currentValue = options.find((x) => x.id === value);

  // eslint-disable-next-line no-nested-ternary
  return embeded ? (
    openEdit ? (
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.instrument}
        style={{ width: '100%' }}
        onChange={(e, newValue) => {
          if (newValue) {
            setValue(newValue.id);
          }
          setOpenEdit(false);
        }}
        onBlur={() => setOpenEdit(false)}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant={embeded ? 'standard' : 'outlined'}
            margin={embeded ? 'none' : 'normal'}
            size={embeded ? 'small' : 'medium'}
          />
        )}
      />
    ) : (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <span onClick={() => setOpenEdit(true)}>{currentValue.instrument}</span>
    )
  ) : (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.instrument}
      style={{ width: '100%' }}
      onChange={(e, newValue) => {
        if (newValue) {
          setValue(newValue.id);
        }
        setOpenEdit(false);
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant={embeded ? 'standard' : 'outlined'}
          margin={embeded ? 'none' : 'dense'}
          size={embeded ? 'small' : 'medium'}
          error={error}
          disabled={disabled}
          required={required}
          helperText={helperText}
          label={labelText}
          fullWidth={fullWidth}
        />
      )}
    />
  );
}

SelectInstrument.propTypes = {
  stateShadowInstruments: propTypeInstrument.isRequired,
  setValue: PropTypes.func,
  value: PropTypes.number,
  embeded: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelText: PropTypes.string,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
};

SelectInstrument.defaultProps = {
  embeded: true,
  error: false,
  disabled: false,
  required: false,
  fullWidth: true,
  labelText: '',
  helperText: '',
  value: 0,
  setValue: () => {
    // eslint-disable-next-line no-console
    console.log('Not implemented');
  },
};

export default SelectInstrument;
