import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Popover,
  Collapse,
} from '@material-ui/core';
import {
  ExpandLess as IconExpandLess,
  ExpandMore as IconExpandMore,
  FiberManualRecord as IconSpacer,
} from '@material-ui/icons';

import SidebarNavItemComponent from './SidebarNavItemComponent';

import propTypeNavItem from '../../../../prop-types/nav-item';

function NavItemCollapsed(props) {
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    IconClassName = '',
    isCollapsed,
    className,
    items = [],
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const hasChildren = items && items.length > 0;
  const allNavItems = getAllNavItems(items);
  const hasChildrenAndIsActive = checkHasChildrenAndIsActive(
    hasChildren,
    allNavItems
  );

  const open = Boolean(anchorEl);
  const id = open ? 'sidebar-nav-item-popper' : undefined;

  const handlePopoverOpen = (event) => {
    if (!hasChildren) {
      return false;
    }
    setAnchorEl(event.currentTarget);
    return null;
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const ListItemIconInner = prepListItemIconInner(Icon, isCollapsed, classes);

  const ListItemRoot = (
    <Tooltip
      disableFocusListener
      disableHoverListener={false}
      disableTouchListener
      title={name}
      placement="right"
    >
      <SidebarNavItemComponent
        link={link}
        className={clsx(
          classes.navItem,
          classes.navItemCollapsed,
          hasChildrenAndIsActive && 'active',
          open && 'open',
          className
        )}
        isCollapsed
        aria-describedby={id}
        onClick={handlePopoverOpen}
      >
        {!!ListItemIconInner && (
          <ListItemIcon
            style={IconStyles}
            className={clsx(classes.navItemIcon, IconClassName)}
          >
            {ListItemIconInner}
          </ListItemIcon>
        )}
        <ListItemText
          primary={name}
          disableTypography
          style={{ visibility: 'hidden' }}
        />
        {hasChildren && (
          <IconExpandLess
            className={clsx(
              classes.iconToggle,
              !open && classes.iconToggleInactive
            )}
          />
        )}
      </SidebarNavItemComponent>
    </Tooltip>
  );

  const ListItemChildren = hasChildren ? (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      classes={{
        paper: classes.navItemPoper,
      }}
    >
      <div className={clsx(classes.navItemChildren)}>
        <List component="div" disablePadding>
          {items.map((item) => (
            <SidebarNavItem
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...item}
              isNested
              nestingLevel={0}
              isCollapsed={false}
              key={item.name || item.link}
              isOpen={open}
              onClick={
                !item.items || !item.items.length
                  ? handlePopoverClose
                  : undefined
              }
            />
          ))}
        </List>
      </div>
    </Popover>
  ) : null;

  return (
    <div
      className={clsx(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive &&
          isCollapsed &&
          classes.navItemWrapperActiveCollapsed
      )}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  );
}

function NavItemDefault(props) {
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    IconClassName = '',
    isCollapsed,
    nestingLevel = 0,
    nestingOffset = 16,
    className,
    items = [],
    onClick = () => {},
  } = props;
  const classes = useStyles();
  const hasChildren = items && items.length > 0;
  const allNavItems = getAllNavItems(items);
  const hasChildrenAndIsActive = checkHasChildrenAndIsActive(
    hasChildren,
    allNavItems
  );
  const isOpen = hasChildrenAndIsActive || false;
  const [open, setOpen] = useState(isOpen);

  // This is a work around for fixing the collapsed item popover overflow bug
  useLayoutEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  });

  const handleClick = () => {
    setOpen(!open);
  };

  const ListItemIconInner = prepListItemIconInner(Icon, isCollapsed, classes);

  const nestingOffsetChildren = !isCollapsed ? nestingOffset + 16 : 16;

  const ListItemRoot = (
    <SidebarNavItemComponent
      link={link}
      className={clsx(
        classes.navItem,
        isCollapsed && classes.navItemCollapsed,
        hasChildrenAndIsActive && 'active',
        className
      )}
      style={{
        fontSize: `${1 - 0.07 * nestingLevel}em`,
        paddingLeft: `${
          !ListItemIconInner ? nestingOffset + 40 : nestingOffset
        }px`,
        paddingTop: nestingLevel ? '4px' : '8px',
        paddingBottom: nestingLevel ? '4px' : '8px',
      }}
      isCollapsed={isCollapsed}
      onClick={handleClick}
    >
      {!!ListItemIconInner && (
        <ListItemIcon
          style={IconStyles}
          className={clsx(classes.navItemIcon, IconClassName)}
        >
          {ListItemIconInner}
        </ListItemIcon>
      )}
      <ListItemText primary={name} disableTypography />
      {hasChildren && !open && (
        <IconExpandMore className={classes.iconToggle} />
      )}
      {hasChildren && open && <IconExpandLess className={classes.iconToggle} />}
    </SidebarNavItemComponent>
  );

  const ListItemChildren = hasChildren ? (
    <div className={clsx(classes.navItemChildren)}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* <Divider /> */}
        <List component="div" disablePadding>
          {items.map((item) => (
            <SidebarNavItem
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...item}
              isNested
              nestingLevel={nestingLevel + 1}
              isCollapsed={isCollapsed}
              key={item.name || item.link}
              isOpen={open}
              nestingOffset={nestingOffsetChildren}
            />
          ))}
        </List>
      </Collapse>
    </div>
  ) : null;

  return (
    <div
      className={clsx(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive &&
          isCollapsed &&
          classes.navItemWrapperActiveCollapsed
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="menuitem"
      tabIndex="0"
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  );
}

function SidebarNavItem(props) {
  const { isCollapsed } = props;
  if (isCollapsed) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <NavItemCollapsed {...props} />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NavItemDefault {...props} />;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    // nested: {
    //   paddingLeft: theme.spacing(10),
    // },
    navItemWrapper: {
      position: 'relative',
    },
    navItemWrapperActive: {
      // background: 'rgba(0, 0, 0, 0.08)',
    },
    navItemWrapperActiveCollapsed: {
      background: 'rgba(0, 0, 0, 0.08)',
    },
    navItem: {
      position: 'relative',
      transition: 'background .23s ease',
      '&.active:not(.open)': {
        color: theme.palette.secondary.main,
        '& .MuiListItemIcon-root': {
          color: theme.palette.secondary.main,
        },
      },
      '&.open': {
        color: theme.palette.white,
        '& .MuiListItemIcon-root': {
          color: theme.palette.white,
        },
      },
    },
    navItemPoper: {
      width: theme.props.layout.menu.width,
      color: theme.palette.text.primary,
      background: theme.palette.background.brandPrimary,
    },
    navItemChildren: {
      transition: 'background .23s ease',
      // position: 'absolute',
    },
    navItemChildrenActive: {
      // background: 'rgba(0, 0, 0, 0.1)',
    },
    navItemCollapsed: {
      whiteSpace: 'nowrap',
      flexWrap: 'nowrap',
      width: theme.props.layout.menu.collapsedWidth,
      '& $iconToggle': {
        position: 'absolute',
        // bottom: -1,
        fontSize: 14,
        top: '50%',
        marginTop: '-0.5em',
        transform: 'rotate(90deg)',
        right: '-6px',
      },
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },

    navItemIcon: {
      minWidth: 40,
    },
    iconToggle: {},
    iconToggleInactive: {
      opacity: 0.35,
    },
    iconSpacer: {
      fontSize: 13,
      marginLeft: 6,
    },
  })
);

function prepListItemIconInner(Icon, isCollapsed, classes) {
  return (
    (!!Icon && <Icon />) ||
    (isCollapsed && <IconSpacer className={classes.iconSpacer} />) ||
    ''
  );
}

// Flattened array of all children
function getAllNavItems(navItems) {
  return navItems.reduce((acc, cur) => {
    if (cur.items && cur.items.length) {
      return acc.concat([cur], getAllNavItems(cur.items));
    }
    return acc.concat([cur]);
  }, []);
}

function checkHasChildrenAndIsActive(hasChildren, allNavItems) {
  return (
    hasChildren &&
    allNavItems.filter((item) => {
      return item.link === window.location.pathname;
    }).length > 0
  );
}

NavItemCollapsed.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // eslint-disable-next-line react/forbid-prop-types
  IconStyles: PropTypes.object,
  IconClassName: PropTypes.string,
  isCollapsed: PropTypes.bool.isRequired,
  className: PropTypes.string,
  items: PropTypes.arrayOf(propTypeNavItem),
};

NavItemCollapsed.defaultProps = {
  link: null,
  Icon: null,
  IconStyles: {},
  IconClassName: '',
  className: null,
  items: [],
};

NavItemDefault.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // eslint-disable-next-line react/forbid-prop-types
  IconStyles: PropTypes.object,
  IconClassName: PropTypes.string,
  isCollapsed: PropTypes.bool.isRequired,
  nestingLevel: PropTypes.number,
  nestingOffset: PropTypes.number,
  className: PropTypes.string,
  items: PropTypes.arrayOf(propTypeNavItem),
  onClick: PropTypes.func,
};

NavItemDefault.defaultProps = {
  link: null,
  Icon: null,
  IconStyles: {},
  IconClassName: '',
  nestingLevel: 0,
  nestingOffset: 16,
  className: null,
  items: [],
  onClick: () => {},
};

SidebarNavItem.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
};

export default SidebarNavItem;
