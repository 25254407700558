export default {
  agGrid: {
    licence:
      'CompanyName=Edgewater Markets LCC,LicensedApplication=Edgewater,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-020515,ExpiryDate=9_November_2022_[v2]_MTY2Nzk1MjAwMDAwMA==3ec2ca2bcf4794d9df256f6e11d0b875',
  },
  api: {
    baseUrl: {
      production: '/api',
      staging: '',
      development: '/api',
    },
  },
  socketIO: {
    baseUrl: {
      production: '',
      staging: '',
      development: '/',
    },
    path: {
      production: '',
      staging: '',
      development: '',
    },
  },
};
