function presenter() {
  function isShowGroupSelect() {
    return true;
  }
  function hasRecords(state) {
    if (state && state.retrieveResult && state.retrieveResult.hasRecords) {
      return true;
    }
    return false;
  }

  function isDataFetching(state) {
    if (
      state.isRetrieving ||
      state.isUpdating ||
      state.isCreating ||
      state.isCmdInProgress
    ) {
      return true;
    }
    return false;
  }

  function isShowEditComp(defaultGroupOption, groupSelected) {
    if (
      groupSelected != null &&
      groupSelected.length > 0 &&
      defaultGroupOption !== groupSelected
    ) {
      return true;
    }
    return false;
  }

  function prepGroupSelectOptions(clientGroups) {
    if (hasRecords(clientGroups)) {
      return clientGroups.retrieveResult.data.map((clientGroup) => {
        return { label: clientGroup.name, value: clientGroup.name };
      });
    }
    return null;
  }

  return Object.freeze({
    isDataFetching,
    isShowGroupSelect,
    isShowEditComp,
    prepGroupSelectOptions,
  });
}

export default presenter;
