import formValidation from '../shared/form-validation';

function shadowStrategyGroupFormPresenter({
  ID_CTRL_COUNTERPARTY,
  ID_CTRL_INSTRUMENTS,
  ID_CTRL_STRATEGY,
  ID_CTRL_ORDER_TYPE,
  ID_CTRL_TIF,
  ID_CTRL_HEDGE_RATIO,
  ID_CTRL_COPY_MIN,
  ID_CTRL_COPY_MAX,
  ID_CTRL_RETRY_INTERVAL,
}) {
  const initialFormState = {
    [ID_CTRL_COUNTERPARTY]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'User/Cpty: required',
        },
        {
          rule: 'minStringLength:1',
          feedback: 'User/Cpty: minimum length 1 character',
        },
      ],
    },
    [ID_CTRL_INSTRUMENTS]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Instrument: required',
        },
      ],
    },
    [ID_CTRL_STRATEGY]: {
      isTouched: true,
      isError: false,
      isDisabled: true,
      value: 'ONE_FOR_ONE',
      validators: [
        {
          rule: 'required',
          feedback: 'Strategy: required',
        },
      ],
    },
    [ID_CTRL_ORDER_TYPE]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Order type: required',
        },
      ],
    },
    [ID_CTRL_TIF]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'TIF: required',
        },
      ],
    },
    [ID_CTRL_HEDGE_RATIO]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Hedge ratio: required',
        },
      ],
    },
    [ID_CTRL_COPY_MIN]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Copy min: required',
        },
      ],
    },
    [ID_CTRL_COPY_MAX]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Copy max: required',
        },
      ],
    },
    [ID_CTRL_RETRY_INTERVAL]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      value: '',
      validators: [
        {
          rule: 'required',
          feedback: 'Retry interval: required',
        },
      ],
    },
  };

  function validateBizRules(formState, shadowGroupStrategyParam) {
    const errors = [];

    const copyMin = parseFloat(
      formState[ID_CTRL_COPY_MIN].value.replace(/,/g, '')
    );
    const copyMax = parseFloat(
      formState[ID_CTRL_COPY_MAX].value.replace(/,/g, '')
    );

    if (copyMax < copyMin) {
      errors.push(ID_CTRL_COPY_MAX);
    }

    const hashStrategyParamPk = {};
    if (
      shadowGroupStrategyParam &&
      shadowGroupStrategyParam.retrieveResult &&
      shadowGroupStrategyParam.retrieveResult.data &&
      shadowGroupStrategyParam.retrieveResult.data.length
    ) {
      const newKey = `${formState[ID_CTRL_COUNTERPARTY].value}${formState[ID_CTRL_INSTRUMENTS].value}`;
      hashStrategyParamPk[newKey] = newKey;
      for (
        let i = 0;
        i < shadowGroupStrategyParam.retrieveResult.data.length;
        i += 1
      ) {
        const key = `${shadowGroupStrategyParam.retrieveResult.data[i].counterparty}${shadowGroupStrategyParam.retrieveResult.data[i].instrumentId}`;
        if (hashStrategyParamPk[key]) {
          errors.push(ID_CTRL_COUNTERPARTY);
          errors.push(ID_CTRL_INSTRUMENTS);
          break;
        } else {
          hashStrategyParamPk[key] = key;
        }
      }
    }
    return errors;
  }

  const validateFormState = (formState, name) => {
    const def = { ...formState[name] };
    const { value, validators } = def;
    const errors = formValidation.checkValidity(value, validators);

    return {
      [name]: {
        ...def,
        isError: !!errors.length,
      },
    };
  };

  function hasShadowInstrumentRecords(shadowInstrument) {
    if (
      !shadowInstrument.isRetrieving &&
      shadowInstrument.retrieveResult &&
      shadowInstrument.retrieveResult.hasRecords
    ) {
      return true;
    }
    return false;
  }

  function prepInstrumentSelectOptions(shadowInstrument) {
    if (hasShadowInstrumentRecords(shadowInstrument)) {
      return shadowInstrument.retrieveResult.data.map((instrument) => {
        return {
          label: instrument.instrument,
          value: instrument.id,
        };
      });
    }
    return [
      {
        label: '',
        value: '',
      },
    ];
  }

  function prepSubmitPayload({ groupName, formState, isEnabled }) {
    return {
      groupName,
      counterparty: formState[ID_CTRL_COUNTERPARTY].value,
      instrumentId: formState[ID_CTRL_INSTRUMENTS].value,
      strategy: formState[ID_CTRL_STRATEGY].value,
      enabled: isEnabled ? 1 : 0,
      orderType: formState[ID_CTRL_ORDER_TYPE].value,
      timeInForce: formState[ID_CTRL_TIF].value,
      hedgeRatio: parseFloat(formState[ID_CTRL_HEDGE_RATIO].value),
      copyMinQty: parseFloat(
        formState[ID_CTRL_COPY_MIN].value.replace(/,/g, '')
      ),
      copyMaxQty: parseFloat(
        formState[ID_CTRL_COPY_MAX].value.replace(/,/g, '')
      ),
      retryIntervalMillis: parseInt(
        formState[ID_CTRL_RETRY_INTERVAL].value,
        10
      ),
    };
  }

  function prepCmdPayload({ groupName, formState, action }) {
    return {
      groupName,
      counterparty: formState[ID_CTRL_COUNTERPARTY].value,
      instrumentId: formState[ID_CTRL_INSTRUMENTS].value,
      action,
    };
  }

  return Object.freeze({
    prepInstrumentSelectOptions,
    initialFormState,
    prepSubmitPayload,
    prepCmdPayload,
    validateBizRules,
    validateFormState,
  });
}

export default shadowStrategyGroupFormPresenter;
