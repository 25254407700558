import { connect } from 'react-redux';
import ApiClientErrors from './ApiClientErrors';

import {
  cmdResetError,
  retrieveResetError,
} from '../../store/modules/api-client-failure';

const mapStateToProps = function mapStateToProps(state) {
  const { apiClientFailure: stateApiClientFailure } = state;
  const isRetrieveError =
    stateApiClientFailure.retrieveError != null &&
    stateApiClientFailure.retrieveError instanceof Error;
  const isCmdError =
    stateApiClientFailure.cmdError != null &&
    stateApiClientFailure.cmdError instanceof Error;

  const errors = [];

  if (isRetrieveError)
    errors.push({
      state: stateApiClientFailure.retrieveError,
      type: 'retrieveError',
    });
  if (isCmdError)
    errors.push({
      state: stateApiClientFailure.cmdError,
      type: 'cmdError',
    });

  return {
    stateSystemAlert: {
      errors,
    },
  };
};

export default connect(mapStateToProps, {
  retrieveResetError,
  cmdResetError,
})(ApiClientErrors);
