import { connect } from 'react-redux';

import {
  retrieveClients as retrieveClientGroupClientsAction,
  update as updateClientGroupAction,
} from '../../../store/modules/client-group';

import { retrieve as retrieveClientsAction } from '../../../store/modules/client';

import EditGroupForm from './EditGroupForm';

const mapStateToProps = function mapStateToProps(state) {
  const { clientGroup: stateClientGroup, client: stateClient } = state;
  return {
    stateClientGroup,
    stateClient,
  };
};
export default connect(mapStateToProps, {
  retrieveClientGroupClientsAction,
  retrieveClientsAction,
  updateClientGroupAction,
})(EditGroupForm);
