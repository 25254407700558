import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  Save as SaveIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons';

import FormInput from '../../../forms/FormInput';
import FormIconButton from '../../../forms/FormIconButton';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../../hooks/trace-update-hook';

import presenter from './presenter';

// import propDefaultApiGet from '../../../prop-defaults/api-get';
// import propTypeClientGroup from '../../../prop-types/client-group';

import ConfirmDialog from '../../../components/ConfirmDialog';

const ID_CTRL_CLIENT_GROUP_NAME_INPUT =
  'client-group-management-group-name-input';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  destroy: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
}));

function CreateGroupForm({
  // stateClientGroup,
  // clientGroupSelectionChangeHandler,
  stateSelected,
  defaultGroupSelection,
  createClientGroupAction,
  removeClientGroupAction,
  clientGroupSelectionChangeAction,
}) {
  const classes = useStyles();
  const present = presenter({
    ID_CTRL_CLIENT_GROUP_NAME_INPUT,
  });

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   createClientGroupAction,
  //   stateClientGroup,
  //   clientGroupSelectionChangeHandler,
  //   stateSelected,
  //   defaultGroupSelection,
  //   removeClientGroupAction,
  //   clientGroupSelectionChangeAction,
  // });

  const initialFormState = useRef(present.initialFormState);
  const [ctrlState, setCtrlState] = useState(initialFormState.current);
  const [isOpen, setIsOpen] = useState(false);

  const handleInputUpdate = (e) => {
    const { id, value } = e.target;
    setCtrlState(
      present.manageCtrlState({
        state: ctrlState,
        name: id,
        value: value.toUpperCase(),
      })
    );
  };

  const handleDestroy = () => {
    removeClientGroupAction({ clientGroupName: stateSelected });
    clientGroupSelectionChangeAction(defaultGroupSelection);
    setCtrlState(initialFormState.current);
  };

  const handleToggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleCreate = () => {
    const validatedState = present.validateCtrls(ctrlState);

    // save errors to state:
    setCtrlState({
      ...ctrlState,
      ...validatedState,
    });

    if (present.hasErrors(ctrlState)) return;

    const { value } = ctrlState[ID_CTRL_CLIENT_GROUP_NAME_INPUT];

    createClientGroupAction({ clientGroupName: value });
    clientGroupSelectionChangeAction(value);
    setCtrlState(initialFormState.current);
  };

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justifyContent="center"
      spacing={3}
    >
      {(stateSelected == null ||
        stateSelected === '' ||
        stateSelected === defaultGroupSelection) && (
        <Grid item xs={12}>
          <FormInput
            value={ctrlState[ID_CTRL_CLIENT_GROUP_NAME_INPUT].value}
            error={ctrlState[ID_CTRL_CLIENT_GROUP_NAME_INPUT].isError}
            disabled={ctrlState[ID_CTRL_CLIENT_GROUP_NAME_INPUT].isDisabled}
            required={ctrlState[ID_CTRL_CLIENT_GROUP_NAME_INPUT].isRequired}
            changeHandler={handleInputUpdate}
            blurHandler={handleInputUpdate}
            helperText="Enter client group name"
            labelText="Client Group Name"
            margin="dense"
            fullWidth
            elementId={ID_CTRL_CLIENT_GROUP_NAME_INPUT}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {stateSelected == null ||
        stateSelected === '' ||
        stateSelected === defaultGroupSelection ? (
          <FormIconButton
            variant="outlined"
            color="primary"
            size="medium"
            icon={<SaveIcon />}
            disabled={ctrlState[ID_CTRL_CLIENT_GROUP_NAME_INPUT].isError}
            onClick={handleCreate}
          >
            Create
          </FormIconButton>
        ) : (
          <>
            <FormIconButton
              variant="outlined"
              color="primary"
              classes={{
                outlinedPrimary: classes.destroy,
              }}
              size="medium"
              icon={<DeleteForeverIcon />}
              onClick={handleToggleIsOpen}
            >
              Delete
            </FormIconButton>
            <ConfirmDialog
              isOpen={isOpen}
              title="Delete selected client group?"
              actionLabel="delete"
              entityLabel="client group"
              onCloseHandler={handleToggleIsOpen}
              onConfirmHandler={handleDestroy}
              selectedRowsCount={1}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}

CreateGroupForm.propTypes = {
  // stateClientGroup: propTypeClientGroup,
  stateSelected: PropTypes.string,
  defaultGroupSelection: PropTypes.string.isRequired,
  // clientGroupSelectionChangeHandler: PropTypes.func.isRequired,
  createClientGroupAction: PropTypes.func.isRequired,
  removeClientGroupAction: PropTypes.func.isRequired,
  clientGroupSelectionChangeAction: PropTypes.func.isRequired,
};

CreateGroupForm.defaultProps = {
  // stateClientGroup: propDefaultApiGet,
  stateSelected: '',
};

export default CreateGroupForm;
