import { connect } from 'react-redux';

import Container from './Container';

const mapStateToProps = function mapStateToProps(state) {
  const { shadowStrategyParamConfig } = state;
  const { groupSelected } = shadowStrategyParamConfig || {};
  return {
    groupSelected,
  };
};

export default connect(mapStateToProps)(Container);
