import React from 'react';
import PropTypes from 'prop-types';

import OnOffSwitch from '../../../../forms/OnOffSwitch';

// eslint-disable-next-line react/prefer-stateless-function
class RenderStatus extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  getSelectedRowData = (nodes) => {
    return Object.values(nodes).map((node) => node.data);
  };

  handleClick = () => {
    const { api, cmdGroupAction } = this.props;
    const selectedRowData = this.getSelectedRowData(api.getSelectedNodes());

    const userUpdate = {
      groupName: selectedRowData[0].name,
    };
    if (selectedRowData[0].status === 'active') {
      userUpdate.action = 'stop';
    } else {
      userUpdate.action = 'start';
    }

    cmdGroupAction(userUpdate);
  };

  render() {
    // const { isChecked } = this.state;
    const { getValue } = this.props;
    let isChecked = false;

    if (getValue() === 'active') {
      isChecked = true;
    }

    return (
      <OnOffSwitch
        checked={isChecked}
        size="medium"
        onClick={this.handleClick}
      />
    );
  }
}

RenderStatus.propTypes = {
  getValue: PropTypes.func.isRequired,
  api: PropTypes.shape({
    getSelectedNodes: PropTypes.func.isRequired,
  }).isRequired,
  cmdGroupAction: PropTypes.func.isRequired,
};

export default RenderStatus;
