import shadowStratParamRepository from '../../repostitories/shadow-strat-param';

import {
  API_FAIL as API_CLIENT_FAIL,
  CMD_FAIL as API_CLIENT_CMD_FAIL,
} from './api-client-failure';

const RETRIEVE = 'store/shadow-group-strategy-param/RETRIEVE';
const RETRIEVE_SUCCESS = 'store/shadow-group-strategy-param/RETRIEVE_SUCCESS';

const CREATE = 'store/shadow-group-strategy-param/CREATE';
const CREATE_SUCCESS = 'store/shadow-group-strategy-param/CREATE_SUCCESS';

const UPDATE = 'store/shadow-group-strategy-param/UPDATE';
const UPDATE_SUCCESS = 'store/shadow-group-strategy-param/UPDATE_SUCCESS';

const REMOVE = 'store/shadow-group-strategy-param/REMOVE';
const REMOVE_SUCCESS = 'store/shadow-group-strategy-param/REMOVE_SUCCESS';

const STRATEGY_CMD = 'store/shadow-group-strategy-param/STRATEGY_CMD';
const STRATEGY_CMD_SUCCESS =
  'store/shadow-group-strategy-param/STRATEGY_CMD_SUCCESS';

const repo = shadowStratParamRepository();

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        isRetrieving: true,
      };
    case RETRIEVE_SUCCESS:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: null,
        retrieveResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoShadowParameterDataSourceInput(action.result.data),
        },
      };
    case CREATE:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createError: null,
        retrieveResult: {
          hasRecords:
            state.retrieveResult && state.retrieveResult.hasRecords
              ? state.retrieveResult.hasRecords
              : action.result.hasRecords,
          count:
            state.retrieveResult && state.retrieveResult.count
              ? state.retrieveResult.count + action.result.data.length
              : action.result.data.length,
          data: repo.dtoShadowParameterDataSourceInput([
            ...(state.retrieveResult && state.retrieveResult.data
              ? state.retrieveResult.data
              : []),
            ...action.result.data,
          ]),
        },
      };
    case UPDATE:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateError: null,
        updateResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoShadowParameterUpdateDataSourceInput(
            action.result.data
          ),
        },
        retrieveResult: {
          ...state.retrieveResult,
          data: repo.dtoShadowParameterDataSourceInput(
            state.retrieveResult.data.reduce((stateAcc, stateCur) => {
              const updated = action.result.data.reduce((acc, cur) => {
                if (
                  stateCur.counterparty != null &&
                  stateCur.counterparty === cur.pkCounterparty &&
                  stateCur.instrumentId != null &&
                  stateCur.instrumentId === cur.pkInstrumentId
                ) {
                  return { ...acc, ...cur };
                }
                return acc;
              }, {});

              return [...stateAcc, { ...stateCur, ...updated }];
            }, [])
          ),
        },
      };
    case REMOVE:
      return {
        ...state,
        isRemoving: true,
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        isRemoving: false,
        removeError: null,
        removeResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: action.result.data,
        },
        retrieveResult: {
          hasRecords: !!(
            state.retrieveResult.count - action.result.data.length
          ),
          count: state.retrieveResult.count - action.result.data.length,
          data: repo.dtoShadowParameterDataSourceInput(
            state.retrieveResult.data.filter((stateCur) => {
              return action.result.data.reduce((acc, cur) => {
                if (
                  stateCur.counterparty != null &&
                  stateCur.counterparty === cur.pkCounterparty &&
                  stateCur.instrumentId != null &&
                  stateCur.instrumentId === cur.pkInstrumentId
                ) {
                  return false;
                }
                return acc;
              }, true);
            })
          ),
        },
      };

    default:
      return state;
  }
}

export function retrieve({ groupName }, authToken) {
  return {
    types: [RETRIEVE, RETRIEVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.get(
        `/shadow-params/shadow-parameters/${groupName}`,
        null,
        authToken
      ),
  };
}

export function create(
  {
    groupName,
    counterparty,
    instrumentId,
    strategy,
    enabled,
    orderType,
    timeInForce,
    hedgeRatio,
    copyMinQty,
    copyMaxQty,
    retryIntervalMillis,
  },
  authToken
) {
  return {
    types: [CREATE, CREATE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.post(
        '/shadow-params/shadow-parameters',
        {
          data: {
            groupName,
            counterparty,
            instrumentId,
            strategy,
            enabled,
            orderType,
            timeInForce,
            hedgeRatio,
            copyMinQty,
            copyMaxQty,
            retryIntervalMillis,
          },
        },
        authToken
      ),
  };
}

export function update({ shadowGroupParamUpdates }, authToken) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.put(
        '/shadow-params/shadow-parameters',
        {
          data: shadowGroupParamUpdates.map((shadowGroupParamUpdate) => {
            return {
              pk: {
                groupName: shadowGroupParamUpdate.pk.groupName,
                counterparty: shadowGroupParamUpdate.pk.counterparty,
                instrumentId: shadowGroupParamUpdate.pk.instrumentId,
              },
              values: {
                counterparty: shadowGroupParamUpdate.values.counterparty,
                instrumentId: shadowGroupParamUpdate.values.instrumentId,
                strategy: shadowGroupParamUpdate.values.strategy,
                enabled: shadowGroupParamUpdate.values.enabled,
                orderType: shadowGroupParamUpdate.values.orderType,
                timeInForce: shadowGroupParamUpdate.values.timeInForce,
                hedgeRatio: shadowGroupParamUpdate.values.hedgeRatio,
                copyMinQty: shadowGroupParamUpdate.values.copyMinQty,
                copyMaxQty: shadowGroupParamUpdate.values.copyMaxQty,
                retryIntervalMillis:
                  shadowGroupParamUpdate.values.retryIntervalMillis,
              },
            };
          }),
        },
        authToken
      ),
  };
}

export function remove({ shadowGroupParamsToRemove }, authToken) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.delete(
        '/shadow-params/shadow-parameters',
        {
          data: shadowGroupParamsToRemove.map((shadowGroupParamRemove) => {
            return {
              pk: {
                groupName: shadowGroupParamRemove.pk.groupName,
                counterparty: shadowGroupParamRemove.pk.counterparty,
                instrumentId: shadowGroupParamRemove.pk.instrumentId,
              },
            };
          }),
        },
        authToken
      ),
  };
}

export function cmdStrategy(
  { groupName, counterparty, instrumentId, action },
  authToken
) {
  let url = '/shadow-params/shadow-parameters/vision/command';
  if (groupName === '*') {
    url = `/shadow-params/shadow-parameters/${groupName}/vision/command`;
  }
  return {
    types: [STRATEGY_CMD, STRATEGY_CMD_SUCCESS, API_CLIENT_CMD_FAIL],
    promise: (client) =>
      client.put(
        url,
        {
          data: {
            groupName,
            counterparty,
            instrumentId,
            action,
          },
        },
        authToken
      ),
  };
}
