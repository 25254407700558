import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import MinimalLayout from './layouts/Minimal';
import MainLayout from './layouts';

import {
  Dashboard as DashboardView,
  SignIn as SignInView,
  ForgottenPassword as ForgottenPasswordView,
  NotFound as NotFoundView,
  ShadowStrategyGroups as ShadowStrategyGroupsView,
  ShadowStrategyParamConfig as ShadowStrategyParamConfigView,
  PriceControlsEditor as PriceControlsEditorView,
  PriceControls as PriceControlsView,
  ClientGroupManagement as ClientGroupManagementView,
} from './views';

function Routes() {
  return (
    <Switch>
      <SecureRoute exact path="/">
        <MainLayout>
          <DashboardView />
        </MainLayout>
      </SecureRoute>
      <SecureRoute path="/parameters/strategy-management">
        <MainLayout>
          <ShadowStrategyGroupsView />
        </MainLayout>
      </SecureRoute>
      <SecureRoute path="/parameters/strategy-params">
        <MainLayout>
          <ShadowStrategyParamConfigView />
        </MainLayout>
      </SecureRoute>
      <SecureRoute path="/price-controls-editor">
        <MainLayout>
          <PriceControlsEditorView />
        </MainLayout>
      </SecureRoute>
      <SecureRoute path="/price-controls">
        <MainLayout>
          <PriceControlsView />
        </MainLayout>
      </SecureRoute>
      <SecureRoute path="/client-group-management">
        <MainLayout>
          <ClientGroupManagementView />
        </MainLayout>
      </SecureRoute>
      <Route exact path="/sign-in">
        <MinimalLayout>
          <SignInView />
        </MinimalLayout>
      </Route>
      <Route exact path="/forgotten-password">
        <MinimalLayout>
          <ForgottenPasswordView />
        </MinimalLayout>
      </Route>
      <Route path="/not-found">
        <MinimalLayout>
          <NotFoundView />
        </MinimalLayout>
      </Route>
      <Route path="/implicit/callback" component={LoginCallback} />
      <Redirect to="/not-found" />
    </Switch>
  );
}

export default Routes;
