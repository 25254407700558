import dtoPriceControlPresetInstrument from '../infrastructure/dto-price-control-preset-instrument';

function priceControlPresetInstrumentRepository() {
  function dtoPriceControlPresetInstrumentDataSourceInput(
    gatewayDataCollection
  ) {
    if (!Array.isArray(gatewayDataCollection))
      throw new TypeError('expects an array');
    return gatewayDataCollection.map((gatewayDataObject) => {
      return dtoPriceControlPresetInstrument(gatewayDataObject);
    });
  }

  return Object.freeze({
    dtoPriceControlPresetInstrumentDataSourceInput,
  });
}

export default priceControlPresetInstrumentRepository;
