import { connect } from 'react-redux';
import SocketClientErrors from './SocketClientErrors';

import {
  cmdResetError,
  retrieveResetError,
} from '../../store/modules/api-client-failure';

const mapStateToProps = function mapStateToProps(state) {
  const { socket: stateSocket } = state;
  const isConnected = !!stateSocket.isConnected;
  const isConnecting = !!stateSocket.isConnecting;
  const isDisconnecting = !!stateSocket.isDisconnecting;
  const isDisconnected = !!stateSocket.isDisconnected;
  const isConnectError = stateSocket.connectError != null;
  const isDisconnectError = stateSocket.disconnectError != null;

  const errors = [];
  let severity = 'URGENT';

  if (isDisconnectError) {
    errors.push({
      state: isDisconnectError.disconnectError,
      type: 'disconnectError',
    });
    severity = 'WARNING';
  }

  if ((isDisconnected && !isConnecting) || isConnectError) {
    errors.push({
      state: stateSocket.connectError,
      type: 'connectError',
    });
    severity = 'URGENT';
  }

  return {
    stateSystemAlert: {
      errors,
      severity,
      status: {
        isConnected,
        isConnecting,
        isDisconnecting,
        isDisconnected,
      },
    },
  };
};

export default connect(mapStateToProps, {
  retrieveResetError,
  cmdResetError,
})(SocketClientErrors);
