import { connect } from 'react-redux';

import GridComponent from './GridComponent';

import { remove as removeOrderUpdateAction } from '../../store/modules/order-update';
import { retrieveError as retrieveErrorAction } from '../../store/modules/api-client-failure';

const mapStateToProps = function mapStateToProps(state) {
  const { orderUpdate: stateOrderUpdate, orderHistory: stateOrderHistory } =
    state;
  return {
    stateOrderUpdate,
    stateOrderHistory,
  };
};

export default connect(mapStateToProps, {
  removeOrderUpdateAction,
  retrieveErrorAction,
})(GridComponent);
