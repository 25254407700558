import { connect } from 'react-redux';

import ShadowStrategyParamConfig from './ShadowStrategyParamConfig';

import { selectionChange as shadowGroupSelectionChangeAction } from '../../store/modules/shadow-strategy-param-config';
import { retrieve as retrieveShadowGroupsAction } from '../../store/modules/shadow-group';

const mapStateToProps = function mapStateToProps(state) {
  const { shadowGroup: stateShadowGroup } = state;
  const { shadowStrategyParamConfig: stateShadowStrategyParamConfig } = state;
  const { groupSelected } = stateShadowStrategyParamConfig || {};
  return {
    stateShadowGroup,
    groupSelected,
  };
};

export default connect(mapStateToProps, {
  shadowGroupSelectionChangeAction,
  retrieveShadowGroupsAction,
})(ShadowStrategyParamConfig);
