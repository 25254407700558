import { arrayOf, bool, element, number, shape, string } from 'prop-types';

const propTypeInstrument = shape({
  isRetrieving: bool,
  retrieveError: element,
  retrieveResult: shape({
    hasRecords: bool.isRequired,
    count: number.isRequired,
    data: arrayOf(
      shape({
        id: number.isRequired,
        instrument: string.isRequired,
        symbol: string,
        tenor: string.isRequired,
        productType: string.isRequired,
        dps: number.isRequired,
        enabled: number.isRequired,
      }).isRequired
    ).isRequired,
  }),
});

export default propTypeInstrument;
