import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from '@material-ui/core';
import { ErrorBoundary } from '@sentry/react';

import ShadowStrategyParamGroup from '../../forms/ShadowStrategy/ParamGroup';
import ShadowStrategyGroupForm from '../../forms/ShadowStrategy/GroupForm';
import ShadowStrategyGroupParams from '../../forms/ShadowStrategy/GroupParams';
import CreateShadowGroupStrategyDialog from './CreateShadowGroupStrategyDialog';
import Fallback from '../../components/Fallback';

import shadowStrategyParamConfigPresenter from '../../presenters/shadow-strategy-param-config';

import propDefaultApiGet from '../../prop-defaults/api-get';
import propTypeShadowGroup from '../../prop-types/shadow-group';

const CREATE_NEW_GROUP_OPTION = 'Create New Group';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', margin: 0, padding: theme.spacing(1) },
  cardContainer: {
    flexGrow: 1,
    height: '100%',
  },
  buttonsContainer: {
    textAlign: 'center',
    marginBottom: '1em',
  },
}));

const handleGroupSelectChange = (fn) => (e) => {
  fn(e.target.value);
};

function ShadowStrategyParamConfig({
  shadowGroupSelectionChangeAction,
  retrieveShadowGroupsAction,
  stateShadowGroup,
  groupSelected,
}) {
  const classes = useStyles();

  const presenter = shadowStrategyParamConfigPresenter();

  const [createGroupStratDialogOpen, setCreateGroupStratDialogOpen] =
    useState(false);

  const handleCreateStratDialogOpen = () => {
    setCreateGroupStratDialogOpen(true);
  };
  const handleCreateStratDialogClose = () => {
    setCreateGroupStratDialogOpen(false);
  };
  useEffect(() => {
    function retrieveFromApi() {
      retrieveShadowGroupsAction();
    }
    retrieveFromApi();
  }, [retrieveShadowGroupsAction]);

  return (
    <ErrorBoundary fallback={Fallback}>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} lg={3}>
          <Card className={classes.cardContainer}>
            <CardHeader
              title="Selected Group"
              subheader="new or existing"
              titleTypographyProps={{ variant: 'subtitle1' }}
            />
            <Divider />
            <CardContent className={classes.content}>
              <ShadowStrategyParamGroup
                isShowGroupSelect={presenter.isShowGroupSelect(
                  stateShadowGroup
                )}
                groupSelectOptions={presenter.prepGroupSelectOptions(
                  stateShadowGroup
                )}
                selectionChangeHandler={handleGroupSelectChange(
                  shadowGroupSelectionChangeAction
                )}
                defaultGroupSelection={CREATE_NEW_GROUP_OPTION}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Card className={classes.cardContainer}>
            <CardHeader
              title="Manage"
              subheader=""
              titleTypographyProps={{ variant: 'subtitle1' }}
              subheaderTypographyProps={{ variant: 'subtitle2' }}
            />
            <Divider />
            <CardContent className={classes.content}>
              <ShadowStrategyGroupForm
                stateShadowGroup={stateShadowGroup}
                shadowGroupSelectionChangeHandler={
                  shadowGroupSelectionChangeAction
                }
                defaultGroupSelection={CREATE_NEW_GROUP_OPTION}
              />
            </CardContent>
          </Card>
        </Grid>
        {presenter.isShowStratComp(CREATE_NEW_GROUP_OPTION, groupSelected) ? (
          <Grid item xs={12}>
            <ShadowStrategyGroupParams
              handleCreateStratDialogOpen={handleCreateStratDialogOpen}
            />
            <CreateShadowGroupStrategyDialog
              isOpen={createGroupStratDialogOpen}
              onCloseHandler={handleCreateStratDialogClose}
              onConfirmHandler={handleCreateStratDialogClose}
              groupSelected={groupSelected}
            />
          </Grid>
        ) : null}
      </Grid>
    </ErrorBoundary>
  );
}

ShadowStrategyParamConfig.propTypes = {
  stateShadowGroup: propTypeShadowGroup,
  groupSelected: PropTypes.string,
  shadowGroupSelectionChangeAction: PropTypes.func.isRequired,
  retrieveShadowGroupsAction: PropTypes.func.isRequired,
};

ShadowStrategyParamConfig.defaultProps = {
  stateShadowGroup: propDefaultApiGet,
  groupSelected: '',
};

export default ShadowStrategyParamConfig;
