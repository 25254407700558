import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FormSelect from '../../FormSelect';

// // TODO: DEV ONLY - REMOVE
// import useTraceUpdate from '../../../hooks/trace-update-hook';

const useStyles = makeStyles(() => ({
  formSelect: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

function ParamGroup({
  isShowGroupSelect,
  groupSelectOptions,
  defaultGroupSelection,
  groupSelected,
  selectionChangeHandler,
}) {
  const classes = useStyles();

  // // TODO: DEV ONLY - REMOVE
  // useTraceUpdate({
  //   isShowGroupSelect,
  //   groupSelectOptions,
  //   defaultGroupSelection,
  //   groupSelected,
  //   selectionChangeHandler,
  // });

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <Grid container>
          {isShowGroupSelect ? (
            <FormSelect
              className={classes.formSelect}
              selected={
                (groupSelectOptions && groupSelected) || defaultGroupSelection
              }
              options={groupSelectOptions}
              changeHandler={selectionChangeHandler}
              defaultLabel={defaultGroupSelection}
              defaultValue={defaultGroupSelection}
              helperText="Select existing or create new group"
              labelText="Groups"
              elementId="shadow-strategy-param-group-select"
              margin="dense"
              fullWidth={false}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

ParamGroup.propTypes = {
  isShowGroupSelect: PropTypes.bool.isRequired,
  groupSelectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  defaultGroupSelection: PropTypes.string.isRequired,
  groupSelected: PropTypes.string,
  selectionChangeHandler: PropTypes.func.isRequired,
};

ParamGroup.defaultProps = {
  groupSelectOptions: null,
  groupSelected: '',
};

export default ParamGroup;
