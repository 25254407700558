import { ArrayModel } from 'objectmodel';

import entityClient from './defs/entity-client';
import entityClientGroup from '../client-group/defs/entity-client-group';
import SealedModel from '../../helpers/SealedModel';

const Client = SealedModel({
  ...entityClient,
  groups: [ArrayModel(entityClientGroup)],
});

export default Client;
