import priceControlsRepository from '../../repostitories/price-controls-repository';
import {
  IO_PRICE_CONTROL_PRESET_CREATE_OR_UPDATE,
  IO_PRICE_CONTROL_PRESET_DESTROY,
} from './constants';
import { deepMergeArrObjsWithIdentifier } from '../../shared/utils';

import {
  API_FAIL as API_CLIENT_FAIL,
  CMD_FAIL as API_CLIENT_CMD_FAIL,
} from './api-client-failure';

const RETRIEVE = 'store/price-control-presets/RETRIEVE';
const RETRIEVE_SUCCESS = 'store/price-control-presets/RETRIEVE_SUCCESS';

const CREATE = 'store/price-control-presets/CREATE';
const CREATE_SUCCESS = 'store/price-control-presets/CREATE_SUCCESS';

const UPDATE = 'store/price-control-presets/UPDATE';
const UPDATE_SUCCESS = 'store/price-control-presets/UPDATE_SUCCESS';

const REMOVE = 'store/price-control-presets/REMOVE';
const REMOVE_SUCCESS = 'store/price-control-presets/REMOVE_SUCCESS';

const RECEIVE_CREATE_OR_UPDATE =
  'store/price-control-presets/RECEIVE_CREATE_OR_UPDATE';
const RECEIVE_CREATE_OR_UPDATE_SUCCESS =
  'store/price-control-presets/RECEIVE_CREATE_OR_UPDATE_SUCCESS';
const RECEIVE_CREATE_OR_UPDATE_FAIL =
  'store/price-control-presets/RECEIVE_CREATE_OR_UPDATE_FAIL';

const RECEIVED_CREATE_OR_UPDATE =
  'store/price-control-presets/RECEIVED_CREATE_OR_UPDATE';

const RECEIVE_DESTROY = 'store/price-control-presets/RECEIVE_DESTROY';
const RECEIVE_DESTROY_SUCCESS =
  'store/price-control-presets/RECEIVE_DESTROY_SUCCESS';
const RECEIVE_DESTROY_FAIL = 'store/price-control-presets/RECEIVE_DESTROY_FAIL';

const RECEIVED_DESTROY = 'store/price-control-presets/RECEIVED_DESTROY';

const CMD = 'store/price-control-presets/CMD';
const CMD_SUCCESS = 'store/price-control-presets/CMD_SUCCESS';

const repo = priceControlsRepository();

const filterOutIds = (stateSlice, filterVals) => {
  return stateSlice.filter(
    (item) => !filterVals.map((filterVal) => filterVal.id).includes(item.id)
  );
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        isRetrieving: true,
      };
    case RETRIEVE_SUCCESS:
      return {
        ...state,
        isRetrieving: false,
        retrieveError: null,
        retrieveResult: {
          hasRecords: action.result.hasRecords,
          count: action.result.count,
          data: repo.dtoPriceControlPresetDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('id')(
                  state.retrieveResult.data,
                  Object.values(action.result.data)
                )
              : Object.values(action.result.data)
          ),
        },
      };
    case CREATE:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createError: null,
        createResult: {
          hasRecords: !!state.retrieveResult.count + action.result.count,
          count: state.retrieveResult.count + action.result.count,
          data: repo.dtoPriceControlPresetDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('id')(
                  state.retrieveResult.data,
                  Object.values(action.result.data)
                )
              : Object.values(action.result.data)
          ),
        },
      };
    case UPDATE:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateError: null,
        updateResult: {
          hasRecords: !!state.retrieveResult.count + action.result.count,
          count: state.retrieveResult.count + action.result.count,
          data: repo.dtoPriceControlPresetDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('id')(
                  state.retrieveResult.data,
                  Object.values(action.result.data)
                )
              : Object.values(action.result.data)
          ),
        },
      };
    case REMOVE:
      return {
        ...state,
        isRemoving: true,
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        isRemoving: false,
        removeError: null,
        removeResult: {
          hasRecords:
            state.retrieveResult && state.retrieveResult.hasRecords
              ? !!filterOutIds(state.retrieveResult.data, action.result.data)
                  .length
              : false,
          count:
            state.retrieveResult && state.retrieveResult.count
              ? filterOutIds(state.retrieveResult.data, action.result.data)
                  .length
              : 0,
          data:
            state.retrieveResult && state.retrieveResult.data
              ? filterOutIds(state.retrieveResult.data, action.result.data)
              : [],
        },
      };
    case RECEIVED_CREATE_OR_UPDATE:
      return {
        ...state,
        retrieveResult: {
          hasRecords:
            state.retrieveResult && state.retrieveResult.hasRecords
              ? state.retrieveResult.hasRecords
              : !!Object.keys(action.result).length,
          count:
            state.retrieveResult && state.retrieveResult.count
              ? state.retrieveResult.count
              : Object.keys(action.result).length,
          data: repo.dtoPriceControlPresetDataSourceInput(
            state.retrieveResult && state.retrieveResult.data
              ? deepMergeArrObjsWithIdentifier('id')(
                  state.retrieveResult.data,
                  Object.values(action.result)
                )
              : Object.values(action.result)
          ),
        },
      };
    case RECEIVED_DESTROY:
      return {
        ...state,
        retrieveResult: {
          hasRecords:
            state.retrieveResult && state.retrieveResult.hasRecords
              ? !!(state.retrieveResult.count - 1)
              : false,
          count:
            state.retrieveResult && state.retrieveResult.count
              ? state.retrieveResult.count - 1
              : 0,
          data:
            state.retrieveResult && state.retrieveResult.data
              ? state.retrieveResult.data.filter(
                  (item) => item.id !== action.result
                )
              : [],
        },
      };
    case CMD:
      return {
        ...state,
        isCmdInProgress: true,
      };
    case CMD_SUCCESS:
      return {
        ...state,
        isCmdInProgress: false,
        cmdError: null,
        cmdResult: action.result,
      };

    default:
      return state;
  }
}

export function retrieve(authToken) {
  return {
    types: [RETRIEVE, RETRIEVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) => client.get(`/price-control-presets`, null, authToken),
  };
}

export function create(
  { name, group, instrument, isActive, types },
  authToken
) {
  return {
    types: [CREATE, CREATE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.post(
        '/price-control-presets',
        {
          data: {
            name,
            group,
            instrument,
            isActive,
            types: types.map((type) => {
              return {
                primary: type.primary,
                secondary: type.secondary,
                value: type.value,
              };
            }),
          },
        },
        authToken
      ),
  };
}

export function update({ id, name, group, instrument, types }, authToken) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.put(
        `/price-control-presets/${id}`,
        {
          data: {
            id,
            name,
            group,
            instrument,
            types: types.map((type) => {
              return {
                id: type.id,
                primary: type.primary,
                secondary: type.secondary,
                value: type.value,
                priceControlPresetId: type.priceControlPresetId,
              };
            }),
          },
        },
        authToken
      ),
  };
}

export function remove({ id }, authToken) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, API_CLIENT_FAIL],
    promise: (client) =>
      client.delete(`/price-control-presets/${id}`, null, authToken),
  };
}

export function receiveCreateOrUpdate() {
  return (dispatch) => {
    const newMessage = (message) => {
      return dispatch({
        type: RECEIVED_CREATE_OR_UPDATE,
        result: message,
      });
    };

    return dispatch({
      type: 'socket',
      types: [
        RECEIVE_CREATE_OR_UPDATE,
        RECEIVE_CREATE_OR_UPDATE_SUCCESS,
        RECEIVE_CREATE_OR_UPDATE_FAIL,
      ],
      promise: (socket) =>
        socket.on(IO_PRICE_CONTROL_PRESET_CREATE_OR_UPDATE, newMessage),
    });
  };
}

export function receiveDestroy() {
  return (dispatch) => {
    const newMessage = (message) => {
      return dispatch({
        type: RECEIVED_DESTROY,
        result: message,
      });
    };

    return dispatch({
      type: 'socket',
      types: [RECEIVE_DESTROY, RECEIVE_DESTROY_SUCCESS, RECEIVE_DESTROY_FAIL],
      promise: (socket) =>
        socket.on(IO_PRICE_CONTROL_PRESET_DESTROY, newMessage),
    });
  };
}

export function cmd(
  { id, name, group, instrument, isActive, types },
  authToken
) {
  const url = '/price-control-presets/vision/command';
  return {
    types: [CMD, CMD_SUCCESS, API_CLIENT_CMD_FAIL],
    promise: (client) =>
      client.put(
        url,
        {
          data: {
            id,
            name,
            group,
            instrument,
            isActive,
            types: types.map((type) => {
              return {
                id: type.id,
                primary: type.primary,
                secondary: type.secondary,
                value: type.value,
                priceControlPresetId: type.priceControlPresetId,
              };
            }),
          },
        },
        authToken
      ),
  };
}
