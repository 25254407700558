import React from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { ChangeDetectionService } from 'ag-grid-react/lib/changeDetectionService';
import { AllModules, LicenseManager } from '@ag-grid-enterprise/all-modules';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { AutoSizer } from 'react-virtualized';

import '../../../theme/ag-grid-alpine-component.scss';

import appConfig from '../../../app-config';
import gridConfig from './grid-config';

LicenseManager.setLicenseKey(appConfig.agGrid.licence);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    '& .is-active-flash.ag-cell.ag-cell-data-changed': {
      backgroundColor: `${theme.palette.error.light} !important`,
    },
    '& .not-active-flash.ag-cell.ag-cell-data-changed': {
      backgroundColor: `${theme.palette.success.light} !important`,
    },
  },
}));

function GridComponent({
  rowData,
  stateInstrumentData,
  stateGroupData,
  deleteHandler,
  cellValueChangedHandler,
  cellEditStartedHandler,
}) {
  const classes = useStyles();
  const config = gridConfig({
    stateInstrumentData,
    stateGroupData,
    deleteHandler,
  });

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <div
            style={{ height, width }}
            className={clsx('ag-theme-alpine', classes.root)}
          >
            <AgGridReact
              // properties
              // debug
              height={height}
              width={width}
              columnDefs={config.columnDefs}
              animateRows
              singleClickEdit
              enableCellChangeFlash
              // stopEditingWhenGridLosesFocus
              rowData={rowData}
              modules={AllModules}
              defaultColDef={config.defaultColDef}
              domLayout="normal"
              autoSizePadding={4}
              immutableData
              getRowNodeId={(data) => data.id}
              rowHeight={30}
              pagination
              paginationAutoPageSize
              // paginationPageSize={25}
              frameworkComponents={config.frameworkComponents}
              // Explictly 'rowDataChangeDetectionStrategy' prop
              // Shouldbe be set implicitly with 'immutableData' prop
              // Workaround intermittent grid update error;
              // changeDetectionService.ts:114 Uncaught TypeError:
              // Cannot add property areEquivPropertyTracking, object is not extensible
              rowDataChangeDetectionStrategy={
                ChangeDetectionService.IdentityCheck
              }
              // columnDefsChangeDetectionStrategy={ChangeDetectionService.NoCheck}
              // events
              onFirstDataRendered={config.onFirstDataRendered}
              onGridSizeChanged={config.onGridSizeChanged}
              onCellValueChanged={(params) => cellValueChangedHandler(params)}
              onCellEditingStarted={cellEditStartedHandler}
            />
          </div>
        );
      }}
    </AutoSizer>
  );
}

GridComponent.propTypes = {
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      group: PropTypes.string,
      instrument: PropTypes.string,
      isActive: PropTypes.bool,
      leftSide: PropTypes.number,
      rightSide: PropTypes.number,
      type: PropTypes.oneOf(['spread', 'skew', 'post']),
    })
  ),
  stateGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  stateInstrumentData: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.string.isRequired,
    })
  ).isRequired,
  deleteHandler: PropTypes.func.isRequired,
  cellValueChangedHandler: PropTypes.func.isRequired,
  cellEditStartedHandler: PropTypes.func.isRequired,
};

GridComponent.defaultProps = {
  rowData: null,
};

export default GridComponent;
