import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

/**
 * Note: known issue: occurs on first load initial value or reset to 'empty'
 *
 * Material-UI: the value provided to Autocomplete is invalid.
 * None of the options match with `{"id":0,"label":""}`.
 * You can use the `getOptionSelected` prop to customize the equality test.
 *
 * Attempted workaround including adding an 'empty' option
 * More info: https://github.com/mui-org/material-ui/issues/18514
 */

function FormAutocomplete({
  elementId,
  name,
  ref,
  options,
  value,
  inputValue,
  error,
  disabled,
  required,
  labelText,
  helperText,
  fullWidth,
  style,
  className,
  margin,
  variant,
  size,
  changeHandler,
  inputChangeHandler,
  blurHandler,
  getOptionLabel,
  getOptionSelected,
}) {
  return (
    <Autocomplete
      id={elementId}
      name={name}
      ref={ref}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      style={style}
      className={className}
      onChange={changeHandler}
      onInputChange={inputChangeHandler}
      onBlur={blurHandler}
      value={value}
      inputValue={inputValue.label}
      renderInput={(params) => {
        return (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant={variant}
            margin={margin}
            size={size}
            error={error}
            disabled={disabled}
            required={required}
            helperText={helperText}
            label={labelText}
            fullWidth={fullWidth}
          />
        );
      }}
    />
  );
}

const propTypeOption = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
});

FormAutocomplete.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  ref: PropTypes.object,
  options: PropTypes.arrayOf(propTypeOption).isRequired,
  value: propTypeOption,
  inputValue: PropTypes.string,
  elementId: PropTypes.string.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  variant: PropTypes.oneOf(['standard', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium']),
  changeHandler: PropTypes.func.isRequired,
  inputChangeHandler: PropTypes.func.isRequired,
  blurHandler: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
};

FormAutocomplete.defaultProps = {
  name: null,
  ref: null,
  margin: 'normal',
  variant: 'outlined',
  size: 'medium',
  error: false,
  disabled: false,
  required: false,
  fullWidth: true,
  className: null,
  value: { id: 0, label: '' },
  inputValue: '',
  style: { width: '100%' },
  blurHandler: null,
  getOptionLabel: (option) => option.label,
  getOptionSelected: (option, selected) => option.label === selected.label,
};

export default FormAutocomplete;
