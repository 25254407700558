import StatusRenderer from './renderers/Status';

const onFirstDataRendered = (params) => {
  params.api.sizeColumnsToFit();
};

const getRowStyle = (params) => {
  const { data, node } = params;
  if (data.status === 'active') return { background: '#66FF99' };
  if (node.rowIndex % 2 === 0) {
    return { background: 'var(--ag-background-color, #fff)' };
  }
  return { background: 'var(--ag-odd-row-background-color, #fcfcfc)' };
};

const generateConfig = () => {
  const sharedColDefs = [
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 170,
      maxWidth: 170,
      cellRenderer: 'statusRenderer',
    },
    { headerName: 'Name', field: 'name', minWidth: 100 },
    {
      headerName: 'Reporting Organization',
      field: 'reportingOrg',
      minWidth: 100,
    },
  ];

  const sharedDefaultColDefs = {
    fiex: 1,
    sortable: false,
    filter: true,
  };

  return {
    columnDefs: [...sharedColDefs],
    frameworkComponents: {
      statusRenderer: StatusRenderer,
    },
    defaultColDef: {
      ...sharedDefaultColDefs,
    },
    sharedColDefs,
    sharedDefaultColDefs,
    onFirstDataRendered,
    getRowStyle,
  };
};

export default generateConfig;
