import { connect } from 'react-redux';

import ShadowStrategyGroups from './ShadowStrategyGroups';

import { retrieve as retrieveShadowGroupsAction } from '../../store/modules/shadow-group';

const mapStateToProps = function mapStateToProps(state) {
  const { shadowGroup: stateShadowGroup } = state;
  return {
    stateShadowGroup,
  };
};

export default connect(mapStateToProps, {
  retrieveShadowGroupsAction,
})(ShadowStrategyGroups);
