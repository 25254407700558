import { format } from 'date-fns-tz';

function presenter() {
  const vm = (state) => {
    const severityLevels = {
      URGENT: 'error',
      WARNING: 'warning',
      LOW: 'info',
    };

    return {
      alertTitleCopy: state
        ? `${state.level}: ${state.datacenter} - ${state.summary}`
        : '',
      alertBodyCopy: state
        ? `<p><strong>Timestamp</strong>: ${format(
            state.timestamp,
            'yyyy-MM-dd HH:mm:ss:SSS zzzz',
            { timeZone: 'Etc/UTC' }
          )}<br /><strong>Email notification sent</strong>: ${
            state.isSendEmail ? 'yes' : 'no'
          }</p>${state.htmlDetails}`
        : '',
      severity: state ? severityLevels[state.level] : 'info',
      hasAlertMessages: !!state,
    };
  };

  return Object.freeze({
    vm,
  });
}

export default presenter;
