import { ObjectModel } from 'objectmodel';

const SealedModel = (def) => {
  const model = ObjectModel(def);
  model.sealed = true;
  model.extend = () => {
    throw new Error(`Sealed models cannot be extended`);
  };

  const checkUndeclaredProps = (obj, modelDef, undeclaredProps, path) => {
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      const subpath = path ? `${path}.${key}` : key;
      if (!Object.prototype.hasOwnProperty.call(modelDef, key)) {
        undeclaredProps.push(subpath);
      } else if (
        val &&
        typeof val === 'object' &&
        Object.getPrototypeOf(val) === Object.prototype
      ) {
        checkUndeclaredProps(val, modelDef[key], undeclaredProps, subpath);
      }
    });
  };

  return model.assert(
    function hasNoUndeclaredProps(obj) {
      if (!model.sealed) return true;
      const undeclaredProps = [];
      checkUndeclaredProps(obj, this.definition, undeclaredProps);
      return undeclaredProps.length === 0 ? true : undeclaredProps;
    },
    (undeclaredProps) =>
      `Undeclared properties in the sealed model definition: ${undeclaredProps}`
  );
};

export default SealedModel;
